import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export const DocumentInfoFragmentDoc = gql`
    fragment documentInfo on t_doc {
  amount
  description
  num_order
  id: doc_id
  doc_id
  ins_date
  ins_user
  ddmmyyyy
  can_edit_doc1
  posted
  payment_partner_name
  partner_1c
  order_date
  verified
  t_order {
    order_sum
  }
  ftrans: t_ftrans {
    amount
    description
    order: t_order {
      ins_date: format_ins_date
      order_id
      customer {
        name
      }
    }
  }
  manager {
    email
    name
    lastname
    full_name
    id: subject_id
    subject_id
    surname
  }
  agent: partner {
    email
    full_name
    lastname
    name
    phone
    id: subject_id
    subject_id
    surname
  }
  partner {
    email
    full_name
    lastname
    name
    phone
    id: subject_id
    subject_id
    surname
  }
  partner_guid
  t_doc_type {
    brief
    description
    id: doc_type_id
    doc_type_id
  }
  date: oper_date
  oper_date
  order_id
  number
  expenses
  exp {
    doc_rest_expenses
  }
}
    `;
export const CurrentEmployeeInfoFragmentDoc = gql`
    fragment currentEmployeeInfo on t_subject {
  id: subject_id
  is_active
  subject_id
  name
  lastname
  address
  email
  phone
  avatar
  branch: object_sales_area_link {
    id: sales_area_id
    sales_area {
      name
    }
  }
}
    `;
export const OrderTaskInfoFragmentDoc = gql`
    fragment orderTaskInfo on t_order {
  customer_id
  customer {
    lastname
    email
    name
    phone
    subject_id
    id: subject_id
    surname
    dolznost: props(where: {prop: {brief: {_eq: "DOLZHNOST"}}}) {
      text_val
      prop_id
      prop {
        name
        brief
      }
      prop_value {
        brief
        value
        prop_value_id
      }
    }
  }
  firm {
    email
    lastname
    name
    subject_id
    phone
  }
  status {
    name
    order_status_id
  }
  type {
    brief
    name
  }
  format_ins_date
  firm_id
  manager_id
  manager {
    email
    lastname
    name
    phone
    subject_id
    surname
  }
}
    `;
export const DefaultOrderInfoFragmentDoc = gql`
    fragment defaultOrderInfo on t_order {
  id: order_id
  order_id
  comment
  description
  deleted
  manager_id
  order_sum
  manager {
    name
    lastname
    surname
  }
  ins_date
  status_id
  number
  cart_id
  cart {
    cart_id
    user_id
    checkouted
  }
  format_ins_date
  orderline_aggregate {
    aggregate {
      sum {
        price
      }
    }
  }
  orderline {
    price
    order_id
    comment
    article
    quantity
    product_id
    deleted
    id: orderline_id
    orderline_id
  }
  status {
    name
    id: order_status_id
    order_status_id
  }
  customer {
    id: subject_id
    subject_id
    lastname
    name
  }
  marzh_order_fakt
  order_expenses_fakt
}
    `;
export const DefaultCurrentOrderInfoFragmentDoc = gql`
    fragment defaultCurrentOrderInfo on t_order {
  id: order_id
  archived
  deal_owner {
    name
    lastname
  }
  deal_owner_id
  invoice_date
  invoice_number
  bill_number
  bill_date
  marzh_order_fakt
  order_expenses_fakt
  marzh_order_fakt_perc
  order_id
  number
  manager_id
  comment
  description
  format_ins_date
  order_sum
  purchase_amount
  cost_amount_order
  marzh
  marzh_percent
  status_id
  status {
    name
    id: order_status_id
    order_status_id
  }
  order_type {
    brief
    order_type_id
    id: order_type_id
    name
  }
  order_type_id
  order_quarter
  manager {
    name
    lastname
    surname
  }
  customer_id
  customer {
    id: subject_id
    subject_id
    lastname
    name
    phone
    email
    manager: subject_links(
      where: {t_link_type: {brief: {_eq: "PARTNER_MANAGER"}}, t_object_type: {brief: {_eq: "SUBJECT"}}, subject_object: {deleted: {_eq: false}}}
      limit: 1
    ) {
      id: subject_link_id
      subject: subject_object {
        id: subject_id
        subject_id
        lastname
        name
        avatar
      }
    }
  }
  firm_id
  firm {
    id: subject_id
    subject_id
    lastname
    name
  }
  orderline {
    amount
  }
  orderline_aggregate {
    aggregate {
      sum {
        purchase_price
        fixed_cost
      }
    }
  }
  contact_list: object_subject_links(
    where: {t_link_type: {brief: {_eq: "CONTACT_PERSON"}}, t_object_type: {brief: {_eq: "ORDER"}}}
    order_by: {subject_id: asc}
  ) {
    id: subject_link_id
    subject_link_id
    subject_id
    subject: t_subject {
      id: subject_id
      name
      lastname
      post: doc(path: "post")
      phone
      email
    }
  }
}
    `;
export const ProductInfoFragmentDoc = gql`
    fragment productInfo on t_item {
  id: item_id
  name
  description
  article
  ins_date
  cat_id
}
    `;
export const CurrentSalesAreaInfoFragmentDoc = gql`
    fragment currentSalesAreaInfo on t_sales_area {
  id: sales_area_id
  sales_area_id
  name
  brief
  leader: sales_area_links(
    where: {link_type: {brief: {_eq: "BRANCH_LEADER"}}, object_type: {brief: {_eq: "SUBJECT"}}, subject: {deleted: {_eq: false}}}
  ) {
    id: sales_area_link_id
    sales_area_link_id
    object_id
    subject {
      id: subject_id
      name
      lastname
    }
  }
}
    `;
export const SalesAreaForPartnerPageInfoFragmentDoc = gql`
    fragment salesAreaForPartnerPageInfo on t_sales_area {
  id: sales_area_id
  name
}
    `;
export const SubjectInfoFragmentDoc = gql`
    fragment subjectInfo on t_subject {
  id: subject_id
  address
  birthdate
  contacts
  organization
  deleted
  email
  surname
  subject_id
  name
  lastname
  phone
  inn
  kpp
  info
  full_name
  subject_type {
    name
    subject_type_id
  }
  type_id
  ins_date
  ins_date_format
  upd_date_format
  props {
    prop {
      brief
      name
    }
    prop_value {
      value
      brief
    }
    prop_id
    prop_value_id
    subject_prop_id
  }
}
    `;
export const CompanyDefaultInfoFragmentDoc = gql`
    fragment companyDefaultInfo on t_subject {
  id: subject_id
  address
  birthdate
  contacts
  deleted
  email
  surname
  subject_id
  manager
  name
  lastname
  phone
  inn
  kpp
  info
  subject_type {
    name
    subject_type_id
  }
  type_id
  ins_date
  ins_date_format
  upd_date_format
  props {
    prop {
      brief
      name
    }
    prop_value {
      value
      brief
    }
    prop_id
    prop_value_id
    subject_prop_id
  }
  type_organization: props(where: {prop: {brief: {_eq: "TIP_ORGANIZACII"}}}) {
    prop_value {
      brief
      value
      id: prop_value_id
    }
    prop {
      brief
      name
      id: prop_id
    }
    prop_value_id
    prop_id
    id: subject_prop_id
    subject_prop_id
  }
  partner: object_subject_links(
    where: {t_link_type: {brief: {_eq: "PARTNER_ORGANIZATION"}}, t_object_type: {brief: {_eq: "SUBJECT"}}}
    limit: 1
  ) {
    id: subject_link_id
    subject: t_subject {
      id: subject_id
      subject_id
      lastname
      name
    }
  }
}
    `;
export const PartnerInfoFragmentDoc = gql`
    fragment partnerInfo on t_subject {
  id: subject_id
      last_doc_date
      last_doc_num
      last_contact_date
      last_contact_text
      prev_year_amount
  deleted
  subject_id
  name
  full_name
  guid
  email
  email2
  email3
  phone
  phone2
  phone3
  address
  kpp
  type_id
  status_id
  status {
    name
  }
  ins_date
  ins_date_format
  upd_date_format
  doc
  info
  subject_type {
    name
    subject_type_id
  }
  manager: subject_links(
    where: {t_link_type: {brief: {_eq: "PARTNER_MANAGER"}}, t_object_type: {brief: {_eq: "SUBJECT"}}, subject_object: {deleted: {_eq: false}}}
    limit: 1
  ) {
    id: subject_link_id
    subject_id: object_id
    subject: subject_object {
      id: subject_id
      subject_id
      lastname
      name
      branch: object_sales_area_link {
        id: sales_area_id
        sales_area {
          name
        }
      }
    }
  }
  website: doc(path: "website")
  type_organization: props(where: {prop: {brief: {_eq: "TIP_ORGANIZACII"}}}) {
    prop_value {
      brief
      value
      id: prop_value_id
    }
    prop {
      brief
      name
      id: prop_id
    }
    prop_value_id
    prop_id
    id: subject_prop_id
    subject_prop_id
  }
  SFERA_DEYATELNOSTI: props(where: {prop: {brief: {_eq: "SFERA_DEYATELNOSTI"}}}) {
    prop_value {
      brief
      value
      id: prop_value_id
    }
    prop {
      brief
      name
      id: prop_id
    }
    prop_value_id
    prop_id
    id: subject_prop_id
    subject_prop_id
  }
  city: props(where: {prop: {brief: {_eq: "CITY"}}}) {
    prop_value {
      brief
      value
      id: prop_value_id
    }
    prop {
      brief
      name
      id: prop_id
    }
    prop_value_id
    prop_id
    id: subject_prop_id
    subject_prop_id
  }
  etap_raboti: props(where: {prop: {brief: {_eq: "ETAPI_RABOTI"}}}) {
    prop_value {
      brief
      value
      id: prop_value_id
    }
    prop {
      brief
      name
      id: prop_id
    }
    prop_value_id
    prop_id
    id: subject_prop_id
    subject_prop_id
  }
  partner_docs(where: {deleted: {_eq: false}}) {
    doc_id
    amount
    number
    ddmmyyyy
    expenses
    ftrans: t_ftrans {
      amount
      description
      order: t_order {
        ins_date: format_ins_date
        order_id
        invoice_date
        invoice_number
        bill_date
        bill_number
        customer {
          name
        }
      }
    }
  }
  orders(where: {deleted: {_neq: true}}) {
    invoice_date
    invoice_number
    bill_date
    bill_number
    order_id
    ins_date: format_ins_date
    order_sum
    deal_owner {
      subject_id
      name
      lastname
    }
    customer {
      name
      lastname
      subject_id
    }
    marzh_order_fakt
    marzh_order_fakt_perc
    order_expenses_fakt
    archived
    status_id
    status {
      name
    }
  }
  created_by_subject {
    name
    lastname
  }
  owner: subject_links(
    where: {t_link_type: {brief: {_eq: "OWNER"}}, t_object_type: {brief: {_eq: "SUBJECT"}}, subject_object: {deleted: {_eq: false}}}
    limit: 1
  ) {
    id: subject_link_id
    subject_id: object_id
  }
  cooper_status: props(where: {prop: {brief: {_eq: "COOPER_STATUS"}}}) {
    prop_value {
      brief
      value
      id: prop_value_id
    }
    prop {
      brief
      name
      id: prop_id
    }
    prop_value_id
    prop_id
    id: subject_prop_id
    subject_prop_id
  }
  tag_list: props(where: {prop: {brief: {_eq: "TAGS"}}}) {
    prop_value {
      brief
      value
      id: prop_value_id
    }
    prop {
      brief
      name
      id: prop_id
    }
    prop_value_id
    prop_id
    id: subject_prop_id
    subject_prop_id
  }
}
    `;
export const ContactInfoFragmentDoc = gql`
    fragment contactInfo on t_subject {
  id: subject_id
  address
  birthdate
  contacts
  deleted
  email
  surname
  subject_id
  name
  lastname
  phone
  inn
  kpp
  info
  full_name
  ins_date
  ins_date_format
  upd_date_format
  is_active
  post: doc(path: "post")
  organization: subject_links(
    where: {t_link_type: {brief: {_eq: "CONTACT_PERSON"}}, t_object_type: {brief: {_eq: "SUBJECT"}}, subject_object: {subject_type: {brief: {_eq: "ORGANIZATION"}}}}
  ) {
    subject: subject_object {
      name
      id: subject_id
    }
  }
  partner: subject_links(
    where: {t_link_type: {brief: {_eq: "CONTACT_PERSON"}}, t_object_type: {brief: {_eq: "SUBJECT"}}, subject_object: {subject_type: {brief: {_eq: "PARTNER"}}}}
  ) {
    subject: subject_object {
      name
      id: subject_id
    }
  }
  post: doc(path: "post")
}
    `;
export const PartnerListInfoForPartnersPageFragmentDoc = gql`
    fragment partnerListInfoForPartnersPage on v_subject_search {
  id: subject_id
      last_doc_date
      last_doc_num
      last_contact_date
      last_contact_text
      prev_year_amount
  guid
  deleted
  subject_id
  name
  subject_type {
    name
    subject_type_id
  }
  SFERA_DEYATELNOSTI: props(where: {prop: {brief: {_eq: "SFERA_DEYATELNOSTI"}}}) {
    prop_value {
      brief
      value
      id: prop_value_id
    }
    prop {
      brief
      name
      id: prop_id
    }
    prop_value_id
    prop_id
    id: subject_prop_id
    subject_prop_id
  }
  email
  phone
  address
  type_id
  status_id
  status {
    name
  }
  ins_date
  manager: subject_links(
    where: {t_link_type: {brief: {_eq: "PARTNER_MANAGER"}}, t_object_type: {brief: {_eq: "SUBJECT"}}, subject_object: {deleted: {_eq: false}}}
    limit: 1
  ) {
    id: subject_link_id
    subject: subject_object {
      id: subject_id
      subject_id
      lastname
      name
      avatar
    }
  }
  partner_manager_name
  type_organization: props(where: {prop: {brief: {_eq: "TIP_ORGANIZACII"}}}) {
    prop_value {
      brief
      value
      id: prop_value_id
    }
    prop {
      brief
      name
      id: prop_id
    }
    prop_value_id
    prop_id
    id: subject_prop_id
    subject_prop_id
  }
  city: props(where: {prop: {brief: {_eq: "CITY"}}}) {
    prop_value {
      brief
      value
      id: prop_value_id
    }
    prop {
      brief
      name
      id: prop_id
    }
    prop_value_id
    prop_id
    id: subject_prop_id
    subject_prop_id
  }
  etap_raboti: props(where: {prop: {brief: {_eq: "ETAPI_RABOTI"}}}) {
    prop_value {
      brief
      value
      id: prop_value_id
    }
    prop {
      brief
      name
      id: prop_id
    }
    prop_value_id
    prop_id
    id: subject_prop_id
    subject_prop_id
  }
  cooper_status: props(where: {prop: {brief: {_eq: "COOPER_STATUS"}}}) {
    prop_value {
      brief
      value
      id: prop_value_id
    }
    prop {
      brief
      name
      id: prop_id
    }
    prop_value_id
    prop_id
    id: subject_prop_id
    subject_prop_id
  }
  tag_list: props(where: {prop: {brief: {_eq: "TAGS"}}}) {
    prop_value {
      brief
      value
      id: prop_value_id
    }
    prop {
      brief
      name
      id: prop_id
    }
    prop_value_id
    prop_id
    id: subject_prop_id
    subject_prop_id
  }
}
    `;
export const TaskInfoDefaultFragmentDoc = gql`
    fragment taskInfoDefault on t_task {
  id: task_id
  task_id
  ins_date
  deleted
  deadline: deadline_string
  manager_id
  name
  description
  status_id
  type_id
  date_start_string
  ins_date_string
  ins_time_string
  task_type {
    id: task_type_id
    task_type_id
    name
    brief
  }
  task_priority {
    name
  }
  task_props {
    id: task_prop_id
    task_prop_id
    prop_id
    prop_value_id
    text_val
    bigint_val
    datetime_val
    int_val
    prop {
      brief
      name
    }
    prop_value {
      value
      brief
    }
  }
  tip_zadachi: task_props(where: {prop: {brief: {_eq: "TIP_ZADACHI"}}}) {
    id: task_prop_id
    prop_value_id
    prop_value {
      id: prop_value_id
      value
      brief
    }
  }
  status {
    name
    task_status_id
    id: task_status_id
  }
  subject_id
  subject {
    id: subject_id
    subject_id
    full_name
    name
    surname
  }
  subject_owner {
    id: subject_id
    subject_id
    full_name
    name
    surname
    lastname
  }
  subject_manager {
    lastname
    name
    subject_id
    id: subject_id
  }
  contact_person: task_links(
    where: {object_type: {brief: {_eq: "SUBJECT"}}, link_type: {brief: {_eq: "CONTACT_PERSON"}}}
  ) {
    id: task_link_id
    task_link_id
    object_id
    subject: object_subject {
      id: subject_id
      subject_id
      name
      lastname
    }
  }
}
    `;
export const ShipmentRequestInfoFragmentDoc = gql`
    fragment shipmentRequestInfo on t_task {
  name
  task_id
  id: task_id
  date_start_string
  selected_shipment_task {
    task_id
    status {
      name
    }
  }
  document: doc(path: "document")
  task_props {
    bigint_val
    datetime_val
    decimal_val
    int_val
    object_id
    prop_id
    prop_value_id
    text_val
    prop {
      brief
      name
    }
    prop_value {
      value
      brief
    }
  }
  ins_date_string
  status_id
  status {
    name
  }
  subject_id
  subject {
    lastname
    surname
    phone
    full_name
    name
    subject_id
  }
  object_order {
    order_sum
    order_id
    id: order_id
    order_type_id
    order_type {
      name
    }
    status {
      name
    }
    orderline_aggregate {
      aggregate {
        sum {
          quantity
          price
        }
      }
    }
    manager {
      surname
      phone
      name
    }
    manager_id
    format_ins_date
    customer {
      full_name
      surname
      name
    }
    customer_id
  }
}
    `;
export const CurrentTaskInfoFragmentDoc = gql`
    fragment currentTaskInfo on t_task {
  deadline
  deadline_string
  deadline_time_string
  description
  doc
  priority_id
  task_priority {
    name
    task_priority_id
  }
  gdrive_file_list: doc(path: "gdrive_file_list")
  tags: doc(path: "tags")
  status {
    name
    task_status_id
    id: task_status_id
  }
  task_type {
    brief
    name
    task_type_id
    id: task_type_id
  }
  task_id
  id: task_id
  type_id
  subject_id
  status_id
  ins_date_string
  ins_time_string
  ins_user
  user_created {
    id: user_id
    subject_id
    subject {
      id: subject_id
      name
      lastname
    }
  }
  subject_owner {
    lastname
    email
    name
    phone
    subject_id
    id: subject_id
  }
  owner_id
  manager_id
  subject_manager {
    name
    lastname
    subject_id
    id: subject_id
  }
  name
  subject {
    lastname
    name
    subject_id
    id: subject_id
  }
  object_id
  object_type {
    brief
    object_type_id
  }
  watchers: task_links(
    where: {link_type: {brief: {_eq: "WATCHER"}}, object_type: {brief: {_eq: "SUBJECT"}}}
    order_by: {object_subject: {subject_id: asc}}
  ) {
    id: task_link_id
    task_link_id
    link_type_id
    object_id
    subject: object_subject {
      subject_id
      id: subject_id
      name
      lastname
      post: props(where: {prop: {brief: {_eq: "DOLZHNOST"}}}, limit: 1) {
        subject_prop_id
        id: subject_prop_id
        prop_value {
          value
          prop_value_id
        }
        prop {
          name
          prop_id
        }
      }
    }
  }
  soispolnitelList: task_links(
    where: {link_type: {brief: {_eq: "SOISPOLNITEL"}}, object_type: {brief: {_eq: "SUBJECT"}}}
    order_by: {object_subject: {subject_id: asc}}
  ) {
    id: task_link_id
    task_link_id
    link_type_id
    object_id
    subject: object_subject {
      subject_id
      id: subject_id
      name
      lastname
      post: props(where: {prop: {brief: {_eq: "DOLZHNOST"}}}, limit: 1) {
        subject_prop_id
        id: subject_prop_id
        prop_value {
          value
          prop_value_id
        }
        prop {
          name
          prop_id
        }
      }
    }
  }
}
    `;
export const CheckListTaskInfoFragmentDoc = gql`
    fragment checkListTaskInfo on t_task {
  id: task_id
  task_id
  name
  owner_id
  subject_owner {
    id: subject_id
    subject_id
    name
    lastname
  }
  deadline: deadline_string
  deadline_time: deadline_time_string
  parent_id
  priority_id
  task_priority {
    name
    task_priority_id
    id: task_priority_id
  }
  task_type {
    brief
    name
    task_type_id
    id: task_type_id
  }
  status_id
  status {
    name
    task_status_id
    id: task_status_id
  }
}
    `;
export const CalendarTaskInfoFragmentDoc = gql`
    fragment calendarTaskInfo on t_task {
  id: task_id
  task_id
  name
  deadline
  description
  deadline_time
  ins_date
  object_subject {
    id: subject_id
    subject_id
    full_name
    name
    surname
  }
  status {
    name
    task_status_id
    id: task_status_id
  }
}
    `;
export const TaskForTaskListPageInfoFragmentDoc = gql`
    fragment taskForTaskListPageInfo on t_task {
  id: task_id
  last_comment
  task_id
  ins_date
  deleted
  deadline: deadline_string
  manager_id
  name
  description
  status_id
  type_id
  date_start_string
  ins_date_string
  ins_time_string
  percent_completed_check_list
  task_type {
    id: task_type_id
    task_type_id
    name
    brief
  }
  task_priority {
    name
  }
  task_props {
    id: task_prop_id
    task_prop_id
    prop_id
    prop_value_id
    text_val
    bigint_val
    datetime_val
    int_val
    prop {
      brief
      name
    }
    prop_value {
      value
      brief
    }
  }
  tip_zadachi: task_props(where: {prop: {brief: {_eq: "TIP_ZADACHI"}}}) {
    id: task_prop_id
    prop_value_id
    prop_value {
      id: prop_value_id
      value
      brief
    }
  }
  status {
    name
    task_status_id
    id: task_status_id
  }
  subject_id
  subject {
    id: subject_id
    subject_id
    full_name
    name
    surname
  }
  object_id
  subject_owner {
    id: subject_id
    subject_id
    full_name
    name
    surname
    lastname
  }
  subject_manager {
    lastname
    name
    subject_id
    id: subject_id
  }
  object_type {
    brief
    object_type_id
    id: object_type_id
  }
  object_order {
    id: order_id
    order_id
  }
  object_subject {
    name
    id: subject_id
    subject_id
    lastname
    subject_type {
      brief
    }
  }
  object_task {
    id: task_id
    task_id
  }
}
    `;
export const DefaultUserInfoFragmentDoc = gql`
    fragment defaultUserInfo on t_user {
  id: user_id
  user_id
  email
  is_admin
  surname
  login
  name
  phone
  subject_id
  subject {
    email
    address
    lastname
    ins_date
    inn
    ins_date_format
    name
    phone
    subject_id
    id: subject_id
    surname
    type_id
    subject_type {
      brief
      name
      subject_type_id
    }
    avatar
    deleted
  }
}
    `;
export const AddCommentDocument = gql`
    mutation addComment($comment: t_comment_insert_input!) {
  insert_t_comment_one(object: $comment) {
    comment_id
  }
}
    `;
export type AddCommentMutationFn = Apollo.MutationFunction<Types.AddCommentMutation, Types.AddCommentMutationVariables>;

/**
 * __useAddCommentMutation__
 *
 * To run a mutation, you first call `useAddCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentMutation, { data, loading, error }] = useAddCommentMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useAddCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddCommentMutation, Types.AddCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddCommentMutation, Types.AddCommentMutationVariables>(AddCommentDocument, options);
      }
export type AddCommentMutationHookResult = ReturnType<typeof useAddCommentMutation>;
export type AddCommentMutationResult = Apollo.MutationResult<Types.AddCommentMutation>;
export type AddCommentMutationOptions = Apollo.BaseMutationOptions<Types.AddCommentMutation, Types.AddCommentMutationVariables>;
export const AddCommentListDocument = gql`
    mutation addCommentList($commentList: [t_comment_insert_input!]!) {
  comment_list: insert_t_comment(objects: $commentList) {
    returning {
      comment_id
    }
  }
}
    `;
export type AddCommentListMutationFn = Apollo.MutationFunction<Types.AddCommentListMutation, Types.AddCommentListMutationVariables>;

/**
 * __useAddCommentListMutation__
 *
 * To run a mutation, you first call `useAddCommentListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentListMutation, { data, loading, error }] = useAddCommentListMutation({
 *   variables: {
 *      commentList: // value for 'commentList'
 *   },
 * });
 */
export function useAddCommentListMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddCommentListMutation, Types.AddCommentListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddCommentListMutation, Types.AddCommentListMutationVariables>(AddCommentListDocument, options);
      }
export type AddCommentListMutationHookResult = ReturnType<typeof useAddCommentListMutation>;
export type AddCommentListMutationResult = Apollo.MutationResult<Types.AddCommentListMutation>;
export type AddCommentListMutationOptions = Apollo.BaseMutationOptions<Types.AddCommentListMutation, Types.AddCommentListMutationVariables>;
export const UpdateCommentByPkDocument = gql`
    mutation updateCommentByPk($commentId: bigint!, $set: t_comment_set_input) {
  update_t_comment_by_pk(pk_columns: {comment_id: $commentId}, _set: $set) {
    comment_id
  }
}
    `;
export type UpdateCommentByPkMutationFn = Apollo.MutationFunction<Types.UpdateCommentByPkMutation, Types.UpdateCommentByPkMutationVariables>;

/**
 * __useUpdateCommentByPkMutation__
 *
 * To run a mutation, you first call `useUpdateCommentByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentByPkMutation, { data, loading, error }] = useUpdateCommentByPkMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateCommentByPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCommentByPkMutation, Types.UpdateCommentByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCommentByPkMutation, Types.UpdateCommentByPkMutationVariables>(UpdateCommentByPkDocument, options);
      }
export type UpdateCommentByPkMutationHookResult = ReturnType<typeof useUpdateCommentByPkMutation>;
export type UpdateCommentByPkMutationResult = Apollo.MutationResult<Types.UpdateCommentByPkMutation>;
export type UpdateCommentByPkMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCommentByPkMutation, Types.UpdateCommentByPkMutationVariables>;
export const AddContentDocument = gql`
    mutation addContent($body: String, $coverImageId: bigint, $description: String, $title: String, $ownerId: bigint) {
  insert_t_content_one(
    object: {body: $body, cover_image_id: $coverImageId, description: $description, title: $title, owner_id: $ownerId}
  ) {
    id: content_id
    content_id
  }
}
    `;
export type AddContentMutationFn = Apollo.MutationFunction<Types.AddContentMutation, Types.AddContentMutationVariables>;

/**
 * __useAddContentMutation__
 *
 * To run a mutation, you first call `useAddContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContentMutation, { data, loading, error }] = useAddContentMutation({
 *   variables: {
 *      body: // value for 'body'
 *      coverImageId: // value for 'coverImageId'
 *      description: // value for 'description'
 *      title: // value for 'title'
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useAddContentMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddContentMutation, Types.AddContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddContentMutation, Types.AddContentMutationVariables>(AddContentDocument, options);
      }
export type AddContentMutationHookResult = ReturnType<typeof useAddContentMutation>;
export type AddContentMutationResult = Apollo.MutationResult<Types.AddContentMutation>;
export type AddContentMutationOptions = Apollo.BaseMutationOptions<Types.AddContentMutation, Types.AddContentMutationVariables>;
export const DeleteContentDocument = gql`
    mutation deleteContent($contentId: Int!) {
  update_t_content_by_pk(
    pk_columns: {content_id: $contentId}
    _set: {deleted: true}
  ) {
    content_id
    id: content_id
  }
}
    `;
export type DeleteContentMutationFn = Apollo.MutationFunction<Types.DeleteContentMutation, Types.DeleteContentMutationVariables>;

/**
 * __useDeleteContentMutation__
 *
 * To run a mutation, you first call `useDeleteContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContentMutation, { data, loading, error }] = useDeleteContentMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useDeleteContentMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteContentMutation, Types.DeleteContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteContentMutation, Types.DeleteContentMutationVariables>(DeleteContentDocument, options);
      }
export type DeleteContentMutationHookResult = ReturnType<typeof useDeleteContentMutation>;
export type DeleteContentMutationResult = Apollo.MutationResult<Types.DeleteContentMutation>;
export type DeleteContentMutationOptions = Apollo.BaseMutationOptions<Types.DeleteContentMutation, Types.DeleteContentMutationVariables>;
export const AddDocumentDocument = gql`
    mutation addDocument($amount: numeric, $description: String, $docTypeId: Int, $firmId: bigint, $insUser: Int, $managerId: bigint, $number: String, $partnerId: bigint, $date: date, $orderId: bigint) {
  insert_t_doc(
    objects: {amount: $amount, description: $description, doc_type_id: $docTypeId, firm_id: $firmId, ins_user: $insUser, manager_id: $managerId, number: $number, partner_id: $partnerId, oper_date: $date, order_id: $orderId}
  ) {
    returning {
      amount
      id: doc_id
      ins_date
      manager_id
      number
      oper_date
      partner_id
      order_id
    }
  }
}
    `;
export type AddDocumentMutationFn = Apollo.MutationFunction<Types.AddDocumentMutation, Types.AddDocumentMutationVariables>;

/**
 * __useAddDocumentMutation__
 *
 * To run a mutation, you first call `useAddDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDocumentMutation, { data, loading, error }] = useAddDocumentMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      description: // value for 'description'
 *      docTypeId: // value for 'docTypeId'
 *      firmId: // value for 'firmId'
 *      insUser: // value for 'insUser'
 *      managerId: // value for 'managerId'
 *      number: // value for 'number'
 *      partnerId: // value for 'partnerId'
 *      date: // value for 'date'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useAddDocumentMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddDocumentMutation, Types.AddDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddDocumentMutation, Types.AddDocumentMutationVariables>(AddDocumentDocument, options);
      }
export type AddDocumentMutationHookResult = ReturnType<typeof useAddDocumentMutation>;
export type AddDocumentMutationResult = Apollo.MutationResult<Types.AddDocumentMutation>;
export type AddDocumentMutationOptions = Apollo.BaseMutationOptions<Types.AddDocumentMutation, Types.AddDocumentMutationVariables>;
export const UpdateDocumentDocument = gql`
    mutation updateDocument($docId: bigint, $set: t_doc_set_input) {
  update_t_doc(where: {doc_id: {_eq: $docId}}, _set: $set) {
    affected_rows
  }
}
    `;
export type UpdateDocumentMutationFn = Apollo.MutationFunction<Types.UpdateDocumentMutation, Types.UpdateDocumentMutationVariables>;

/**
 * __useUpdateDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentMutation, { data, loading, error }] = useUpdateDocumentMutation({
 *   variables: {
 *      docId: // value for 'docId'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDocumentMutation, Types.UpdateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDocumentMutation, Types.UpdateDocumentMutationVariables>(UpdateDocumentDocument, options);
      }
export type UpdateDocumentMutationHookResult = ReturnType<typeof useUpdateDocumentMutation>;
export type UpdateDocumentMutationResult = Apollo.MutationResult<Types.UpdateDocumentMutation>;
export type UpdateDocumentMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDocumentMutation, Types.UpdateDocumentMutationVariables>;
export const HoldDocDocument = gql`
    mutation holdDoc($queryGuid: String!, $hold: Boolean) {
  update_t_doc_imports(
    where: {query_guid: {_eq: $queryGuid}}
    _set: {is_hold: $hold}
  ) {
    affected_rows
  }
}
    `;
export type HoldDocMutationFn = Apollo.MutationFunction<Types.HoldDocMutation, Types.HoldDocMutationVariables>;

/**
 * __useHoldDocMutation__
 *
 * To run a mutation, you first call `useHoldDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHoldDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [holdDocMutation, { data, loading, error }] = useHoldDocMutation({
 *   variables: {
 *      queryGuid: // value for 'queryGuid'
 *      hold: // value for 'hold'
 *   },
 * });
 */
export function useHoldDocMutation(baseOptions?: Apollo.MutationHookOptions<Types.HoldDocMutation, Types.HoldDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.HoldDocMutation, Types.HoldDocMutationVariables>(HoldDocDocument, options);
      }
export type HoldDocMutationHookResult = ReturnType<typeof useHoldDocMutation>;
export type HoldDocMutationResult = Apollo.MutationResult<Types.HoldDocMutation>;
export type HoldDocMutationOptions = Apollo.BaseMutationOptions<Types.HoldDocMutation, Types.HoldDocMutationVariables>;
export const SetDocPartnerDocument = gql`
    mutation setDocPartner($queryGuid: String!, $partnerId: Int) {
  update_t_doc_imports(
    where: {query_guid: {_eq: $queryGuid}}
    _set: {partner_id: $partnerId}
  ) {
    affected_rows
  }
}
    `;
export type SetDocPartnerMutationFn = Apollo.MutationFunction<Types.SetDocPartnerMutation, Types.SetDocPartnerMutationVariables>;

/**
 * __useSetDocPartnerMutation__
 *
 * To run a mutation, you first call `useSetDocPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDocPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDocPartnerMutation, { data, loading, error }] = useSetDocPartnerMutation({
 *   variables: {
 *      queryGuid: // value for 'queryGuid'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useSetDocPartnerMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetDocPartnerMutation, Types.SetDocPartnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetDocPartnerMutation, Types.SetDocPartnerMutationVariables>(SetDocPartnerDocument, options);
      }
export type SetDocPartnerMutationHookResult = ReturnType<typeof useSetDocPartnerMutation>;
export type SetDocPartnerMutationResult = Apollo.MutationResult<Types.SetDocPartnerMutation>;
export type SetDocPartnerMutationOptions = Apollo.BaseMutationOptions<Types.SetDocPartnerMutation, Types.SetDocPartnerMutationVariables>;
export const UpdateDocDocument = gql`
    mutation updateDoc($id: Int!, $set: t_doc_imports_set_input) {
  update_t_doc_imports_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
  }
}
    `;
export type UpdateDocMutationFn = Apollo.MutationFunction<Types.UpdateDocMutation, Types.UpdateDocMutationVariables>;

/**
 * __useUpdateDocMutation__
 *
 * To run a mutation, you first call `useUpdateDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocMutation, { data, loading, error }] = useUpdateDocMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateDocMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDocMutation, Types.UpdateDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDocMutation, Types.UpdateDocMutationVariables>(UpdateDocDocument, options);
      }
export type UpdateDocMutationHookResult = ReturnType<typeof useUpdateDocMutation>;
export type UpdateDocMutationResult = Apollo.MutationResult<Types.UpdateDocMutation>;
export type UpdateDocMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDocMutation, Types.UpdateDocMutationVariables>;
export const AddDocFTranDocument = gql`
    mutation addDocFTran($orderId: Int, $amount: numeric, $operDate: date, $description: String) {
  ftran: insert_t_ftran_one(
    object: {amount: $amount, oper_date: $operDate, order_id: $orderId, description: $description}
  ) {
    ftran_id
  }
}
    `;
export type AddDocFTranMutationFn = Apollo.MutationFunction<Types.AddDocFTranMutation, Types.AddDocFTranMutationVariables>;

/**
 * __useAddDocFTranMutation__
 *
 * To run a mutation, you first call `useAddDocFTranMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDocFTranMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDocFTranMutation, { data, loading, error }] = useAddDocFTranMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      amount: // value for 'amount'
 *      operDate: // value for 'operDate'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAddDocFTranMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddDocFTranMutation, Types.AddDocFTranMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddDocFTranMutation, Types.AddDocFTranMutationVariables>(AddDocFTranDocument, options);
      }
export type AddDocFTranMutationHookResult = ReturnType<typeof useAddDocFTranMutation>;
export type AddDocFTranMutationResult = Apollo.MutationResult<Types.AddDocFTranMutation>;
export type AddDocFTranMutationOptions = Apollo.BaseMutationOptions<Types.AddDocFTranMutation, Types.AddDocFTranMutationVariables>;
export const DeleteDocFTranDocument = gql`
    mutation deleteDocFTran($docId: Int!) {
  delete_t_doc_imports_by_pk(id: $docId) {
    id
  }
}
    `;
export type DeleteDocFTranMutationFn = Apollo.MutationFunction<Types.DeleteDocFTranMutation, Types.DeleteDocFTranMutationVariables>;

/**
 * __useDeleteDocFTranMutation__
 *
 * To run a mutation, you first call `useDeleteDocFTranMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocFTranMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocFTranMutation, { data, loading, error }] = useDeleteDocFTranMutation({
 *   variables: {
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useDeleteDocFTranMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDocFTranMutation, Types.DeleteDocFTranMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteDocFTranMutation, Types.DeleteDocFTranMutationVariables>(DeleteDocFTranDocument, options);
      }
export type DeleteDocFTranMutationHookResult = ReturnType<typeof useDeleteDocFTranMutation>;
export type DeleteDocFTranMutationResult = Apollo.MutationResult<Types.DeleteDocFTranMutation>;
export type DeleteDocFTranMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDocFTranMutation, Types.DeleteDocFTranMutationVariables>;
export const EditEmployeeDocument = gql`
    mutation editEmployee($subject: t_subject_set_input, $subjectId: Int!) {
  subject: update_t_subject_by_pk(
    pk_columns: {subject_id: $subjectId}
    _set: $subject
  ) {
    subject_id
  }
}
    `;
export type EditEmployeeMutationFn = Apollo.MutationFunction<Types.EditEmployeeMutation, Types.EditEmployeeMutationVariables>;

/**
 * __useEditEmployeeMutation__
 *
 * To run a mutation, you first call `useEditEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEmployeeMutation, { data, loading, error }] = useEditEmployeeMutation({
 *   variables: {
 *      subject: // value for 'subject'
 *      subjectId: // value for 'subjectId'
 *   },
 * });
 */
export function useEditEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditEmployeeMutation, Types.EditEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditEmployeeMutation, Types.EditEmployeeMutationVariables>(EditEmployeeDocument, options);
      }
export type EditEmployeeMutationHookResult = ReturnType<typeof useEditEmployeeMutation>;
export type EditEmployeeMutationResult = Apollo.MutationResult<Types.EditEmployeeMutation>;
export type EditEmployeeMutationOptions = Apollo.BaseMutationOptions<Types.EditEmployeeMutation, Types.EditEmployeeMutationVariables>;
export const MoveEmployeeMessagesDocument = gql`
    mutation moveEmployeeMessages($oldEmpl: Int, $newEmpl: Int) {
  update_t_message(
    where: {recipient_id: {_eq: $oldEmpl}}
    _set: {recipient_id: $newEmpl}
  ) {
    affected_rows
  }
}
    `;
export type MoveEmployeeMessagesMutationFn = Apollo.MutationFunction<Types.MoveEmployeeMessagesMutation, Types.MoveEmployeeMessagesMutationVariables>;

/**
 * __useMoveEmployeeMessagesMutation__
 *
 * To run a mutation, you first call `useMoveEmployeeMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveEmployeeMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveEmployeeMessagesMutation, { data, loading, error }] = useMoveEmployeeMessagesMutation({
 *   variables: {
 *      oldEmpl: // value for 'oldEmpl'
 *      newEmpl: // value for 'newEmpl'
 *   },
 * });
 */
export function useMoveEmployeeMessagesMutation(baseOptions?: Apollo.MutationHookOptions<Types.MoveEmployeeMessagesMutation, Types.MoveEmployeeMessagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MoveEmployeeMessagesMutation, Types.MoveEmployeeMessagesMutationVariables>(MoveEmployeeMessagesDocument, options);
      }
export type MoveEmployeeMessagesMutationHookResult = ReturnType<typeof useMoveEmployeeMessagesMutation>;
export type MoveEmployeeMessagesMutationResult = Apollo.MutationResult<Types.MoveEmployeeMessagesMutation>;
export type MoveEmployeeMessagesMutationOptions = Apollo.BaseMutationOptions<Types.MoveEmployeeMessagesMutation, Types.MoveEmployeeMessagesMutationVariables>;
export const MoveEmployeePartnersDocument = gql`
    mutation moveEmployeePartners($oldEmpl: Int, $newEmpl: Int) {
  update_t_subject_link(
    where: {link_type_id: {_in: [8, 11]}, object_id: {_eq: $oldEmpl}}
    _set: {object_id: $newEmpl}
  ) {
    affected_rows
  }
}
    `;
export type MoveEmployeePartnersMutationFn = Apollo.MutationFunction<Types.MoveEmployeePartnersMutation, Types.MoveEmployeePartnersMutationVariables>;

/**
 * __useMoveEmployeePartnersMutation__
 *
 * To run a mutation, you first call `useMoveEmployeePartnersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveEmployeePartnersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveEmployeePartnersMutation, { data, loading, error }] = useMoveEmployeePartnersMutation({
 *   variables: {
 *      oldEmpl: // value for 'oldEmpl'
 *      newEmpl: // value for 'newEmpl'
 *   },
 * });
 */
export function useMoveEmployeePartnersMutation(baseOptions?: Apollo.MutationHookOptions<Types.MoveEmployeePartnersMutation, Types.MoveEmployeePartnersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MoveEmployeePartnersMutation, Types.MoveEmployeePartnersMutationVariables>(MoveEmployeePartnersDocument, options);
      }
export type MoveEmployeePartnersMutationHookResult = ReturnType<typeof useMoveEmployeePartnersMutation>;
export type MoveEmployeePartnersMutationResult = Apollo.MutationResult<Types.MoveEmployeePartnersMutation>;
export type MoveEmployeePartnersMutationOptions = Apollo.BaseMutationOptions<Types.MoveEmployeePartnersMutation, Types.MoveEmployeePartnersMutationVariables>;
export const SetEmployeeInActiveDocument = gql`
    mutation setEmployeeInActive($empl: Int!) {
  update_t_subject_by_pk(
    pk_columns: {subject_id: $empl}
    _set: {is_active: false}
  ) {
    subject_id
  }
}
    `;
export type SetEmployeeInActiveMutationFn = Apollo.MutationFunction<Types.SetEmployeeInActiveMutation, Types.SetEmployeeInActiveMutationVariables>;

/**
 * __useSetEmployeeInActiveMutation__
 *
 * To run a mutation, you first call `useSetEmployeeInActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEmployeeInActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEmployeeInActiveMutation, { data, loading, error }] = useSetEmployeeInActiveMutation({
 *   variables: {
 *      empl: // value for 'empl'
 *   },
 * });
 */
export function useSetEmployeeInActiveMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetEmployeeInActiveMutation, Types.SetEmployeeInActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetEmployeeInActiveMutation, Types.SetEmployeeInActiveMutationVariables>(SetEmployeeInActiveDocument, options);
      }
export type SetEmployeeInActiveMutationHookResult = ReturnType<typeof useSetEmployeeInActiveMutation>;
export type SetEmployeeInActiveMutationResult = Apollo.MutationResult<Types.SetEmployeeInActiveMutation>;
export type SetEmployeeInActiveMutationOptions = Apollo.BaseMutationOptions<Types.SetEmployeeInActiveMutation, Types.SetEmployeeInActiveMutationVariables>;
export const FileUploadDocument = gql`
    mutation fileUpload($objectId: Int, $objectTypeId: Int, $linkTypeId: Int, $fileType: String, $userId: Int, $base64str: String!, $fileName: String!, $mimeType: String!) {
  fileUpload(
    objectId: $objectId
    linkTypeId: $linkTypeId
    fileType: $fileType
    userId: $userId
    objectTypeId: $objectTypeId
    base64str: $base64str
    fileName: $fileName
    mimeType: $mimeType
  ) {
    file_id
    file_name
    file_path
    file_type
    ins_date
    mime_type
    user_id
    deleted
  }
}
    `;
export type FileUploadMutationFn = Apollo.MutationFunction<Types.FileUploadMutation, Types.FileUploadMutationVariables>;

/**
 * __useFileUploadMutation__
 *
 * To run a mutation, you first call `useFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileUploadMutation, { data, loading, error }] = useFileUploadMutation({
 *   variables: {
 *      objectId: // value for 'objectId'
 *      objectTypeId: // value for 'objectTypeId'
 *      linkTypeId: // value for 'linkTypeId'
 *      fileType: // value for 'fileType'
 *      userId: // value for 'userId'
 *      base64str: // value for 'base64str'
 *      fileName: // value for 'fileName'
 *      mimeType: // value for 'mimeType'
 *   },
 * });
 */
export function useFileUploadMutation(baseOptions?: Apollo.MutationHookOptions<Types.FileUploadMutation, Types.FileUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.FileUploadMutation, Types.FileUploadMutationVariables>(FileUploadDocument, options);
      }
export type FileUploadMutationHookResult = ReturnType<typeof useFileUploadMutation>;
export type FileUploadMutationResult = Apollo.MutationResult<Types.FileUploadMutation>;
export type FileUploadMutationOptions = Apollo.BaseMutationOptions<Types.FileUploadMutation, Types.FileUploadMutationVariables>;
export const AddFTranDocument = gql`
    mutation addFTran($documentId: bigint, $amount: numeric, $subjectId: Int, $orderId: Int, $description: String, $orderlineId: bigint) {
  insert_t_ftran(
    objects: {doc_id: $documentId, amount: $amount, subject_id: $subjectId, order_id: $orderId, description: $description, orderline_id: $orderlineId}
  ) {
    returning {
      amount
      subject_id
      ftran_id
      id: ftran_id
      doc_id
      orderline_id
      doc: t_doc {
        number
        oper_date
        partner {
          name
        }
      }
    }
  }
}
    `;
export type AddFTranMutationFn = Apollo.MutationFunction<Types.AddFTranMutation, Types.AddFTranMutationVariables>;

/**
 * __useAddFTranMutation__
 *
 * To run a mutation, you first call `useAddFTranMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFTranMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFTranMutation, { data, loading, error }] = useAddFTranMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      amount: // value for 'amount'
 *      subjectId: // value for 'subjectId'
 *      orderId: // value for 'orderId'
 *      description: // value for 'description'
 *      orderlineId: // value for 'orderlineId'
 *   },
 * });
 */
export function useAddFTranMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddFTranMutation, Types.AddFTranMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddFTranMutation, Types.AddFTranMutationVariables>(AddFTranDocument, options);
      }
export type AddFTranMutationHookResult = ReturnType<typeof useAddFTranMutation>;
export type AddFTranMutationResult = Apollo.MutationResult<Types.AddFTranMutation>;
export type AddFTranMutationOptions = Apollo.BaseMutationOptions<Types.AddFTranMutation, Types.AddFTranMutationVariables>;
export const RemoveFTranDocument = gql`
    mutation removeFTran($ftranId: bigint!) {
  delete_t_ftran_by_pk(ftran_id: $ftranId) {
    ftran_id
  }
}
    `;
export type RemoveFTranMutationFn = Apollo.MutationFunction<Types.RemoveFTranMutation, Types.RemoveFTranMutationVariables>;

/**
 * __useRemoveFTranMutation__
 *
 * To run a mutation, you first call `useRemoveFTranMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFTranMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFTranMutation, { data, loading, error }] = useRemoveFTranMutation({
 *   variables: {
 *      ftranId: // value for 'ftranId'
 *   },
 * });
 */
export function useRemoveFTranMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveFTranMutation, Types.RemoveFTranMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveFTranMutation, Types.RemoveFTranMutationVariables>(RemoveFTranDocument, options);
      }
export type RemoveFTranMutationHookResult = ReturnType<typeof useRemoveFTranMutation>;
export type RemoveFTranMutationResult = Apollo.MutationResult<Types.RemoveFTranMutation>;
export type RemoveFTranMutationOptions = Apollo.BaseMutationOptions<Types.RemoveFTranMutation, Types.RemoveFTranMutationVariables>;
export const RemoveDocumentFTranDocument = gql`
    mutation removeDocumentFTran($docId: bigint) {
  delete_t_ftran(where: {doc_id: {_eq: $docId}}) {
    affected_rows
  }
}
    `;
export type RemoveDocumentFTranMutationFn = Apollo.MutationFunction<Types.RemoveDocumentFTranMutation, Types.RemoveDocumentFTranMutationVariables>;

/**
 * __useRemoveDocumentFTranMutation__
 *
 * To run a mutation, you first call `useRemoveDocumentFTranMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDocumentFTranMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDocumentFTranMutation, { data, loading, error }] = useRemoveDocumentFTranMutation({
 *   variables: {
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useRemoveDocumentFTranMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveDocumentFTranMutation, Types.RemoveDocumentFTranMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveDocumentFTranMutation, Types.RemoveDocumentFTranMutationVariables>(RemoveDocumentFTranDocument, options);
      }
export type RemoveDocumentFTranMutationHookResult = ReturnType<typeof useRemoveDocumentFTranMutation>;
export type RemoveDocumentFTranMutationResult = Apollo.MutationResult<Types.RemoveDocumentFTranMutation>;
export type RemoveDocumentFTranMutationOptions = Apollo.BaseMutationOptions<Types.RemoveDocumentFTranMutation, Types.RemoveDocumentFTranMutationVariables>;
export const UpdateFTranDocument = gql`
    mutation updateFTran($ftranId: bigint!, $amount: numeric, $description: String) {
  update_t_ftran_by_pk(
    pk_columns: {ftran_id: $ftranId}
    _set: {amount: $amount, description: $description}
  ) {
    ftran_id
    amount
    description
  }
}
    `;
export type UpdateFTranMutationFn = Apollo.MutationFunction<Types.UpdateFTranMutation, Types.UpdateFTranMutationVariables>;

/**
 * __useUpdateFTranMutation__
 *
 * To run a mutation, you first call `useUpdateFTranMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFTranMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFTranMutation, { data, loading, error }] = useUpdateFTranMutation({
 *   variables: {
 *      ftranId: // value for 'ftranId'
 *      amount: // value for 'amount'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateFTranMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateFTranMutation, Types.UpdateFTranMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateFTranMutation, Types.UpdateFTranMutationVariables>(UpdateFTranDocument, options);
      }
export type UpdateFTranMutationHookResult = ReturnType<typeof useUpdateFTranMutation>;
export type UpdateFTranMutationResult = Apollo.MutationResult<Types.UpdateFTranMutation>;
export type UpdateFTranMutationOptions = Apollo.BaseMutationOptions<Types.UpdateFTranMutation, Types.UpdateFTranMutationVariables>;
export const UpdateMessageDocument = gql`
    mutation updateMessage($set: t_message_set_input, $messageId: bigint!) {
  update_t_message_by_pk(_set: $set, pk_columns: {message_id: $messageId}) {
    message_id
  }
}
    `;
export type UpdateMessageMutationFn = Apollo.MutationFunction<Types.UpdateMessageMutation, Types.UpdateMessageMutationVariables>;

/**
 * __useUpdateMessageMutation__
 *
 * To run a mutation, you first call `useUpdateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageMutation, { data, loading, error }] = useUpdateMessageMutation({
 *   variables: {
 *      set: // value for 'set'
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useUpdateMessageMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateMessageMutation, Types.UpdateMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateMessageMutation, Types.UpdateMessageMutationVariables>(UpdateMessageDocument, options);
      }
export type UpdateMessageMutationHookResult = ReturnType<typeof useUpdateMessageMutation>;
export type UpdateMessageMutationResult = Apollo.MutationResult<Types.UpdateMessageMutation>;
export type UpdateMessageMutationOptions = Apollo.BaseMutationOptions<Types.UpdateMessageMutation, Types.UpdateMessageMutationVariables>;
export const UpdateMessagesDocument = gql`
    mutation updateMessages($where: t_message_bool_exp!, $set: t_message_set_input) {
  update_t_message(where: $where, _set: $set) {
    affected_rows
    returning {
      message_id
    }
  }
}
    `;
export type UpdateMessagesMutationFn = Apollo.MutationFunction<Types.UpdateMessagesMutation, Types.UpdateMessagesMutationVariables>;

/**
 * __useUpdateMessagesMutation__
 *
 * To run a mutation, you first call `useUpdateMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessagesMutation, { data, loading, error }] = useUpdateMessagesMutation({
 *   variables: {
 *      where: // value for 'where'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateMessagesMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateMessagesMutation, Types.UpdateMessagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateMessagesMutation, Types.UpdateMessagesMutationVariables>(UpdateMessagesDocument, options);
      }
export type UpdateMessagesMutationHookResult = ReturnType<typeof useUpdateMessagesMutation>;
export type UpdateMessagesMutationResult = Apollo.MutationResult<Types.UpdateMessagesMutation>;
export type UpdateMessagesMutationOptions = Apollo.BaseMutationOptions<Types.UpdateMessagesMutation, Types.UpdateMessagesMutationVariables>;
export const AddOrderlineDocument = gql`
    mutation addOrderline($orderId: Int) {
  insert_t_orderline(objects: {order_id: $orderId, caption: ""}) {
    affected_rows
  }
}
    `;
export type AddOrderlineMutationFn = Apollo.MutationFunction<Types.AddOrderlineMutation, Types.AddOrderlineMutationVariables>;

/**
 * __useAddOrderlineMutation__
 *
 * To run a mutation, you first call `useAddOrderlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrderlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrderlineMutation, { data, loading, error }] = useAddOrderlineMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useAddOrderlineMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddOrderlineMutation, Types.AddOrderlineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddOrderlineMutation, Types.AddOrderlineMutationVariables>(AddOrderlineDocument, options);
      }
export type AddOrderlineMutationHookResult = ReturnType<typeof useAddOrderlineMutation>;
export type AddOrderlineMutationResult = Apollo.MutationResult<Types.AddOrderlineMutation>;
export type AddOrderlineMutationOptions = Apollo.BaseMutationOptions<Types.AddOrderlineMutation, Types.AddOrderlineMutationVariables>;
export const UpdateOrderlineDocument = gql`
    mutation updateOrderline($orderlineId: Int!, $set: t_orderline_set_input) {
  update_t_orderline_by_pk(pk_columns: {orderline_id: $orderlineId}, _set: $set) {
    orderline_id
  }
}
    `;
export type UpdateOrderlineMutationFn = Apollo.MutationFunction<Types.UpdateOrderlineMutation, Types.UpdateOrderlineMutationVariables>;

/**
 * __useUpdateOrderlineMutation__
 *
 * To run a mutation, you first call `useUpdateOrderlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderlineMutation, { data, loading, error }] = useUpdateOrderlineMutation({
 *   variables: {
 *      orderlineId: // value for 'orderlineId'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateOrderlineMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOrderlineMutation, Types.UpdateOrderlineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOrderlineMutation, Types.UpdateOrderlineMutationVariables>(UpdateOrderlineDocument, options);
      }
export type UpdateOrderlineMutationHookResult = ReturnType<typeof useUpdateOrderlineMutation>;
export type UpdateOrderlineMutationResult = Apollo.MutationResult<Types.UpdateOrderlineMutation>;
export type UpdateOrderlineMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOrderlineMutation, Types.UpdateOrderlineMutationVariables>;
export const AddOrderDocument = gql`
    mutation addOrder($cartId: bigint, $comment: String, $customerId: Int, $description: String, $firmId: bigint, $managerId: Int, $number: String, $type: Int, $partnerId: bigint, $status: Int, $userId: Int, $insDate: timestamp) {
  insert_t_order(
    objects: {cart_id: $cartId, comment: $comment, customer_id: $customerId, description: $description, firm_id: $firmId, manager_id: $managerId, number: $number, order_type_id: $type, partner_id: $partnerId, status_id: $status, user_id: $userId, ins_date: $insDate}
  ) {
    returning {
      order_id
    }
  }
}
    `;
export type AddOrderMutationFn = Apollo.MutationFunction<Types.AddOrderMutation, Types.AddOrderMutationVariables>;

/**
 * __useAddOrderMutation__
 *
 * To run a mutation, you first call `useAddOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrderMutation, { data, loading, error }] = useAddOrderMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      comment: // value for 'comment'
 *      customerId: // value for 'customerId'
 *      description: // value for 'description'
 *      firmId: // value for 'firmId'
 *      managerId: // value for 'managerId'
 *      number: // value for 'number'
 *      type: // value for 'type'
 *      partnerId: // value for 'partnerId'
 *      status: // value for 'status'
 *      userId: // value for 'userId'
 *      insDate: // value for 'insDate'
 *   },
 * });
 */
export function useAddOrderMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddOrderMutation, Types.AddOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddOrderMutation, Types.AddOrderMutationVariables>(AddOrderDocument, options);
      }
export type AddOrderMutationHookResult = ReturnType<typeof useAddOrderMutation>;
export type AddOrderMutationResult = Apollo.MutationResult<Types.AddOrderMutation>;
export type AddOrderMutationOptions = Apollo.BaseMutationOptions<Types.AddOrderMutation, Types.AddOrderMutationVariables>;
export const AddOrderOneDocument = gql`
    mutation addOrderOne($order: t_order_insert_input!) {
  order: insert_t_order_one(object: $order) {
    id: order_id
  }
}
    `;
export type AddOrderOneMutationFn = Apollo.MutationFunction<Types.AddOrderOneMutation, Types.AddOrderOneMutationVariables>;

/**
 * __useAddOrderOneMutation__
 *
 * To run a mutation, you first call `useAddOrderOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrderOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrderOneMutation, { data, loading, error }] = useAddOrderOneMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAddOrderOneMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddOrderOneMutation, Types.AddOrderOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddOrderOneMutation, Types.AddOrderOneMutationVariables>(AddOrderOneDocument, options);
      }
export type AddOrderOneMutationHookResult = ReturnType<typeof useAddOrderOneMutation>;
export type AddOrderOneMutationResult = Apollo.MutationResult<Types.AddOrderOneMutation>;
export type AddOrderOneMutationOptions = Apollo.BaseMutationOptions<Types.AddOrderOneMutation, Types.AddOrderOneMutationVariables>;
export const AddOrderOnePropDocument = gql`
    mutation addOrderOneProp($propList: [t_order_prop_insert_input!]!) {
  order_prop_list: insert_t_order_prop(objects: $propList) {
    returning {
      id: order_prop_id
    }
  }
}
    `;
export type AddOrderOnePropMutationFn = Apollo.MutationFunction<Types.AddOrderOnePropMutation, Types.AddOrderOnePropMutationVariables>;

/**
 * __useAddOrderOnePropMutation__
 *
 * To run a mutation, you first call `useAddOrderOnePropMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrderOnePropMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrderOnePropMutation, { data, loading, error }] = useAddOrderOnePropMutation({
 *   variables: {
 *      propList: // value for 'propList'
 *   },
 * });
 */
export function useAddOrderOnePropMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddOrderOnePropMutation, Types.AddOrderOnePropMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddOrderOnePropMutation, Types.AddOrderOnePropMutationVariables>(AddOrderOnePropDocument, options);
      }
export type AddOrderOnePropMutationHookResult = ReturnType<typeof useAddOrderOnePropMutation>;
export type AddOrderOnePropMutationResult = Apollo.MutationResult<Types.AddOrderOnePropMutation>;
export type AddOrderOnePropMutationOptions = Apollo.BaseMutationOptions<Types.AddOrderOnePropMutation, Types.AddOrderOnePropMutationVariables>;
export const AddNewOrderDocument = gql`
    mutation addNewOrder($description: String!, $customer_id: Int, $oper_date: date) {
  newOrder: insert_t_order(
    objects: {description: $description, customer_id: $customer_id, delivery_date: $oper_date}
  ) {
    returning {
      order_id
    }
  }
}
    `;
export type AddNewOrderMutationFn = Apollo.MutationFunction<Types.AddNewOrderMutation, Types.AddNewOrderMutationVariables>;

/**
 * __useAddNewOrderMutation__
 *
 * To run a mutation, you first call `useAddNewOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewOrderMutation, { data, loading, error }] = useAddNewOrderMutation({
 *   variables: {
 *      description: // value for 'description'
 *      customer_id: // value for 'customer_id'
 *      oper_date: // value for 'oper_date'
 *   },
 * });
 */
export function useAddNewOrderMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddNewOrderMutation, Types.AddNewOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddNewOrderMutation, Types.AddNewOrderMutationVariables>(AddNewOrderDocument, options);
      }
export type AddNewOrderMutationHookResult = ReturnType<typeof useAddNewOrderMutation>;
export type AddNewOrderMutationResult = Apollo.MutationResult<Types.AddNewOrderMutation>;
export type AddNewOrderMutationOptions = Apollo.BaseMutationOptions<Types.AddNewOrderMutation, Types.AddNewOrderMutationVariables>;
export const UpdateOrderDocument = gql`
    mutation updateOrder($orderId: Int!, $description: String!, $customer_id: Int, $oper_date: date) {
  updateOrder: update_t_order(
    _set: {description: $description, delivery_date: $oper_date, customer_id: $customer_id}
    where: {order_id: {_eq: $orderId}}
  ) {
    returning {
      order_id
    }
  }
}
    `;
export type UpdateOrderMutationFn = Apollo.MutationFunction<Types.UpdateOrderMutation, Types.UpdateOrderMutationVariables>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      description: // value for 'description'
 *      customer_id: // value for 'customer_id'
 *      oper_date: // value for 'oper_date'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOrderMutation, Types.UpdateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOrderMutation, Types.UpdateOrderMutationVariables>(UpdateOrderDocument, options);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<Types.UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOrderMutation, Types.UpdateOrderMutationVariables>;
export const UpdateOrderByPkDocument = gql`
    mutation updateOrderByPk($set: t_order_set_input, $orderId: Int!) {
  update_t_order_by_pk(pk_columns: {order_id: $orderId}, _set: $set) {
    id: order_id
    status_id
  }
}
    `;
export type UpdateOrderByPkMutationFn = Apollo.MutationFunction<Types.UpdateOrderByPkMutation, Types.UpdateOrderByPkMutationVariables>;

/**
 * __useUpdateOrderByPkMutation__
 *
 * To run a mutation, you first call `useUpdateOrderByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderByPkMutation, { data, loading, error }] = useUpdateOrderByPkMutation({
 *   variables: {
 *      set: // value for 'set'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useUpdateOrderByPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOrderByPkMutation, Types.UpdateOrderByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOrderByPkMutation, Types.UpdateOrderByPkMutationVariables>(UpdateOrderByPkDocument, options);
      }
export type UpdateOrderByPkMutationHookResult = ReturnType<typeof useUpdateOrderByPkMutation>;
export type UpdateOrderByPkMutationResult = Apollo.MutationResult<Types.UpdateOrderByPkMutation>;
export type UpdateOrderByPkMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOrderByPkMutation, Types.UpdateOrderByPkMutationVariables>;
export const DeleteOrderVersionDocument = gql`
    mutation deleteOrderVersion($orderId: Int, $ver: String) {
  delete_t_orderline(where: {_and: {order_id: {_eq: $orderId}}, ver: {_eq: $ver}}) {
    affected_rows
  }
}
    `;
export type DeleteOrderVersionMutationFn = Apollo.MutationFunction<Types.DeleteOrderVersionMutation, Types.DeleteOrderVersionMutationVariables>;

/**
 * __useDeleteOrderVersionMutation__
 *
 * To run a mutation, you first call `useDeleteOrderVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderVersionMutation, { data, loading, error }] = useDeleteOrderVersionMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      ver: // value for 'ver'
 *   },
 * });
 */
export function useDeleteOrderVersionMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteOrderVersionMutation, Types.DeleteOrderVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteOrderVersionMutation, Types.DeleteOrderVersionMutationVariables>(DeleteOrderVersionDocument, options);
      }
export type DeleteOrderVersionMutationHookResult = ReturnType<typeof useDeleteOrderVersionMutation>;
export type DeleteOrderVersionMutationResult = Apollo.MutationResult<Types.DeleteOrderVersionMutation>;
export type DeleteOrderVersionMutationOptions = Apollo.BaseMutationOptions<Types.DeleteOrderVersionMutation, Types.DeleteOrderVersionMutationVariables>;
export const AddOrdersToArchiveDocument = gql`
    mutation addOrdersToArchive($orderIds: [Int!]) {
  update_t_order(where: {order_id: {_in: $orderIds}}, _set: {archived: true}) {
    affected_rows
  }
}
    `;
export type AddOrdersToArchiveMutationFn = Apollo.MutationFunction<Types.AddOrdersToArchiveMutation, Types.AddOrdersToArchiveMutationVariables>;

/**
 * __useAddOrdersToArchiveMutation__
 *
 * To run a mutation, you first call `useAddOrdersToArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrdersToArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrdersToArchiveMutation, { data, loading, error }] = useAddOrdersToArchiveMutation({
 *   variables: {
 *      orderIds: // value for 'orderIds'
 *   },
 * });
 */
export function useAddOrdersToArchiveMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddOrdersToArchiveMutation, Types.AddOrdersToArchiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddOrdersToArchiveMutation, Types.AddOrdersToArchiveMutationVariables>(AddOrdersToArchiveDocument, options);
      }
export type AddOrdersToArchiveMutationHookResult = ReturnType<typeof useAddOrdersToArchiveMutation>;
export type AddOrdersToArchiveMutationResult = Apollo.MutationResult<Types.AddOrdersToArchiveMutation>;
export type AddOrdersToArchiveMutationOptions = Apollo.BaseMutationOptions<Types.AddOrdersToArchiveMutation, Types.AddOrdersToArchiveMutationVariables>;
export const AddPropDocument = gql`
    mutation addProp($value: t_prop_value_insert_input!) {
  value: insert_t_prop_value_one(object: $value) {
    brief
    prop_id
    value
    prop_value_id
    id: prop_value_id
  }
}
    `;
export type AddPropMutationFn = Apollo.MutationFunction<Types.AddPropMutation, Types.AddPropMutationVariables>;

/**
 * __useAddPropMutation__
 *
 * To run a mutation, you first call `useAddPropMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPropMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPropMutation, { data, loading, error }] = useAddPropMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useAddPropMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddPropMutation, Types.AddPropMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddPropMutation, Types.AddPropMutationVariables>(AddPropDocument, options);
      }
export type AddPropMutationHookResult = ReturnType<typeof useAddPropMutation>;
export type AddPropMutationResult = Apollo.MutationResult<Types.AddPropMutation>;
export type AddPropMutationOptions = Apollo.BaseMutationOptions<Types.AddPropMutation, Types.AddPropMutationVariables>;
export const DeleteSalesAreaLinksDocument = gql`
    mutation deleteSalesAreaLinks($subjectId: Int) {
  delete_t_sales_area_link(
    where: {link_type_id: {_eq: 14}, object_type_id: {_eq: 3}, object_id: {_eq: $subjectId}}
  ) {
    returning {
      sales_area_id
    }
  }
}
    `;
export type DeleteSalesAreaLinksMutationFn = Apollo.MutationFunction<Types.DeleteSalesAreaLinksMutation, Types.DeleteSalesAreaLinksMutationVariables>;

/**
 * __useDeleteSalesAreaLinksMutation__
 *
 * To run a mutation, you first call `useDeleteSalesAreaLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSalesAreaLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSalesAreaLinksMutation, { data, loading, error }] = useDeleteSalesAreaLinksMutation({
 *   variables: {
 *      subjectId: // value for 'subjectId'
 *   },
 * });
 */
export function useDeleteSalesAreaLinksMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteSalesAreaLinksMutation, Types.DeleteSalesAreaLinksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteSalesAreaLinksMutation, Types.DeleteSalesAreaLinksMutationVariables>(DeleteSalesAreaLinksDocument, options);
      }
export type DeleteSalesAreaLinksMutationHookResult = ReturnType<typeof useDeleteSalesAreaLinksMutation>;
export type DeleteSalesAreaLinksMutationResult = Apollo.MutationResult<Types.DeleteSalesAreaLinksMutation>;
export type DeleteSalesAreaLinksMutationOptions = Apollo.BaseMutationOptions<Types.DeleteSalesAreaLinksMutation, Types.DeleteSalesAreaLinksMutationVariables>;
export const AddSalesAreaLinksDocument = gql`
    mutation addSalesAreaLinks($linkList: [t_sales_area_link_insert_input!]!) {
  sales_area_link_list: insert_t_sales_area_link(objects: $linkList) {
    returning {
      id: sales_area_link_id
    }
  }
}
    `;
export type AddSalesAreaLinksMutationFn = Apollo.MutationFunction<Types.AddSalesAreaLinksMutation, Types.AddSalesAreaLinksMutationVariables>;

/**
 * __useAddSalesAreaLinksMutation__
 *
 * To run a mutation, you first call `useAddSalesAreaLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSalesAreaLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSalesAreaLinksMutation, { data, loading, error }] = useAddSalesAreaLinksMutation({
 *   variables: {
 *      linkList: // value for 'linkList'
 *   },
 * });
 */
export function useAddSalesAreaLinksMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddSalesAreaLinksMutation, Types.AddSalesAreaLinksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddSalesAreaLinksMutation, Types.AddSalesAreaLinksMutationVariables>(AddSalesAreaLinksDocument, options);
      }
export type AddSalesAreaLinksMutationHookResult = ReturnType<typeof useAddSalesAreaLinksMutation>;
export type AddSalesAreaLinksMutationResult = Apollo.MutationResult<Types.AddSalesAreaLinksMutation>;
export type AddSalesAreaLinksMutationOptions = Apollo.BaseMutationOptions<Types.AddSalesAreaLinksMutation, Types.AddSalesAreaLinksMutationVariables>;
export const AddSubjectLinkOneDocument = gql`
    mutation addSubjectLinkOne($link: t_subject_link_insert_input!) {
  subject_link: insert_t_subject_link_one(object: $link) {
    id: subject_link_id
  }
}
    `;
export type AddSubjectLinkOneMutationFn = Apollo.MutationFunction<Types.AddSubjectLinkOneMutation, Types.AddSubjectLinkOneMutationVariables>;

/**
 * __useAddSubjectLinkOneMutation__
 *
 * To run a mutation, you first call `useAddSubjectLinkOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubjectLinkOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubjectLinkOneMutation, { data, loading, error }] = useAddSubjectLinkOneMutation({
 *   variables: {
 *      link: // value for 'link'
 *   },
 * });
 */
export function useAddSubjectLinkOneMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddSubjectLinkOneMutation, Types.AddSubjectLinkOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddSubjectLinkOneMutation, Types.AddSubjectLinkOneMutationVariables>(AddSubjectLinkOneDocument, options);
      }
export type AddSubjectLinkOneMutationHookResult = ReturnType<typeof useAddSubjectLinkOneMutation>;
export type AddSubjectLinkOneMutationResult = Apollo.MutationResult<Types.AddSubjectLinkOneMutation>;
export type AddSubjectLinkOneMutationOptions = Apollo.BaseMutationOptions<Types.AddSubjectLinkOneMutation, Types.AddSubjectLinkOneMutationVariables>;
export const AddSubjectLinkListDocument = gql`
    mutation addSubjectLinkList($linkList: [t_subject_link_insert_input!]!) {
  link_list: insert_t_subject_link(objects: $linkList) {
    returning {
      link_type_id
      object_id
      object_type_id
      subject_id
      subject_link_id
    }
  }
}
    `;
export type AddSubjectLinkListMutationFn = Apollo.MutationFunction<Types.AddSubjectLinkListMutation, Types.AddSubjectLinkListMutationVariables>;

/**
 * __useAddSubjectLinkListMutation__
 *
 * To run a mutation, you first call `useAddSubjectLinkListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubjectLinkListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubjectLinkListMutation, { data, loading, error }] = useAddSubjectLinkListMutation({
 *   variables: {
 *      linkList: // value for 'linkList'
 *   },
 * });
 */
export function useAddSubjectLinkListMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddSubjectLinkListMutation, Types.AddSubjectLinkListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddSubjectLinkListMutation, Types.AddSubjectLinkListMutationVariables>(AddSubjectLinkListDocument, options);
      }
export type AddSubjectLinkListMutationHookResult = ReturnType<typeof useAddSubjectLinkListMutation>;
export type AddSubjectLinkListMutationResult = Apollo.MutationResult<Types.AddSubjectLinkListMutation>;
export type AddSubjectLinkListMutationOptions = Apollo.BaseMutationOptions<Types.AddSubjectLinkListMutation, Types.AddSubjectLinkListMutationVariables>;
export const DeleteSubjectLinkDocument = gql`
    mutation deleteSubjectLink($where: t_subject_link_bool_exp!) {
  delete_t_subject_link(where: $where) {
    affected_rows
    returning {
      subject_link_id
    }
  }
}
    `;
export type DeleteSubjectLinkMutationFn = Apollo.MutationFunction<Types.DeleteSubjectLinkMutation, Types.DeleteSubjectLinkMutationVariables>;

/**
 * __useDeleteSubjectLinkMutation__
 *
 * To run a mutation, you first call `useDeleteSubjectLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubjectLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubjectLinkMutation, { data, loading, error }] = useDeleteSubjectLinkMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteSubjectLinkMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteSubjectLinkMutation, Types.DeleteSubjectLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteSubjectLinkMutation, Types.DeleteSubjectLinkMutationVariables>(DeleteSubjectLinkDocument, options);
      }
export type DeleteSubjectLinkMutationHookResult = ReturnType<typeof useDeleteSubjectLinkMutation>;
export type DeleteSubjectLinkMutationResult = Apollo.MutationResult<Types.DeleteSubjectLinkMutation>;
export type DeleteSubjectLinkMutationOptions = Apollo.BaseMutationOptions<Types.DeleteSubjectLinkMutation, Types.DeleteSubjectLinkMutationVariables>;
export const DeleteSubjectLinkByPkDocument = gql`
    mutation deleteSubjectLinkByPk($linkId: bigint!) {
  delete_t_subject_link_by_pk(subject_link_id: $linkId) {
    id: subject_link_id
  }
}
    `;
export type DeleteSubjectLinkByPkMutationFn = Apollo.MutationFunction<Types.DeleteSubjectLinkByPkMutation, Types.DeleteSubjectLinkByPkMutationVariables>;

/**
 * __useDeleteSubjectLinkByPkMutation__
 *
 * To run a mutation, you first call `useDeleteSubjectLinkByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubjectLinkByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubjectLinkByPkMutation, { data, loading, error }] = useDeleteSubjectLinkByPkMutation({
 *   variables: {
 *      linkId: // value for 'linkId'
 *   },
 * });
 */
export function useDeleteSubjectLinkByPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteSubjectLinkByPkMutation, Types.DeleteSubjectLinkByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteSubjectLinkByPkMutation, Types.DeleteSubjectLinkByPkMutationVariables>(DeleteSubjectLinkByPkDocument, options);
      }
export type DeleteSubjectLinkByPkMutationHookResult = ReturnType<typeof useDeleteSubjectLinkByPkMutation>;
export type DeleteSubjectLinkByPkMutationResult = Apollo.MutationResult<Types.DeleteSubjectLinkByPkMutation>;
export type DeleteSubjectLinkByPkMutationOptions = Apollo.BaseMutationOptions<Types.DeleteSubjectLinkByPkMutation, Types.DeleteSubjectLinkByPkMutationVariables>;
export const UpdateSubjectLinkByPkDocument = gql`
    mutation updateSubjectLinkByPk($set: t_subject_link_set_input, $subjectLinkId: bigint!) {
  subject_link: update_t_subject_link_by_pk(
    _set: $set
    pk_columns: {subject_link_id: $subjectLinkId}
  ) {
    id: subject_link_id
  }
}
    `;
export type UpdateSubjectLinkByPkMutationFn = Apollo.MutationFunction<Types.UpdateSubjectLinkByPkMutation, Types.UpdateSubjectLinkByPkMutationVariables>;

/**
 * __useUpdateSubjectLinkByPkMutation__
 *
 * To run a mutation, you first call `useUpdateSubjectLinkByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubjectLinkByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubjectLinkByPkMutation, { data, loading, error }] = useUpdateSubjectLinkByPkMutation({
 *   variables: {
 *      set: // value for 'set'
 *      subjectLinkId: // value for 'subjectLinkId'
 *   },
 * });
 */
export function useUpdateSubjectLinkByPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateSubjectLinkByPkMutation, Types.UpdateSubjectLinkByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateSubjectLinkByPkMutation, Types.UpdateSubjectLinkByPkMutationVariables>(UpdateSubjectLinkByPkDocument, options);
      }
export type UpdateSubjectLinkByPkMutationHookResult = ReturnType<typeof useUpdateSubjectLinkByPkMutation>;
export type UpdateSubjectLinkByPkMutationResult = Apollo.MutationResult<Types.UpdateSubjectLinkByPkMutation>;
export type UpdateSubjectLinkByPkMutationOptions = Apollo.BaseMutationOptions<Types.UpdateSubjectLinkByPkMutation, Types.UpdateSubjectLinkByPkMutationVariables>;
export const AddSubjectPropListDocument = gql`
    mutation addSubjectPropList($objects: [t_subject_prop_insert_input!]!) {
  subject_prop_list: insert_t_subject_prop(objects: $objects) {
    returning {
      id: subject_prop_id
    }
  }
}
    `;
export type AddSubjectPropListMutationFn = Apollo.MutationFunction<Types.AddSubjectPropListMutation, Types.AddSubjectPropListMutationVariables>;

/**
 * __useAddSubjectPropListMutation__
 *
 * To run a mutation, you first call `useAddSubjectPropListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubjectPropListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubjectPropListMutation, { data, loading, error }] = useAddSubjectPropListMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddSubjectPropListMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddSubjectPropListMutation, Types.AddSubjectPropListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddSubjectPropListMutation, Types.AddSubjectPropListMutationVariables>(AddSubjectPropListDocument, options);
      }
export type AddSubjectPropListMutationHookResult = ReturnType<typeof useAddSubjectPropListMutation>;
export type AddSubjectPropListMutationResult = Apollo.MutationResult<Types.AddSubjectPropListMutation>;
export type AddSubjectPropListMutationOptions = Apollo.BaseMutationOptions<Types.AddSubjectPropListMutation, Types.AddSubjectPropListMutationVariables>;
export const AddSubjectPropDocument = gql`
    mutation addSubjectProp($prop: t_subject_prop_insert_input!) {
  insert_t_subject_prop_one(object: $prop) {
    subject_prop_id
  }
}
    `;
export type AddSubjectPropMutationFn = Apollo.MutationFunction<Types.AddSubjectPropMutation, Types.AddSubjectPropMutationVariables>;

/**
 * __useAddSubjectPropMutation__
 *
 * To run a mutation, you first call `useAddSubjectPropMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubjectPropMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubjectPropMutation, { data, loading, error }] = useAddSubjectPropMutation({
 *   variables: {
 *      prop: // value for 'prop'
 *   },
 * });
 */
export function useAddSubjectPropMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddSubjectPropMutation, Types.AddSubjectPropMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddSubjectPropMutation, Types.AddSubjectPropMutationVariables>(AddSubjectPropDocument, options);
      }
export type AddSubjectPropMutationHookResult = ReturnType<typeof useAddSubjectPropMutation>;
export type AddSubjectPropMutationResult = Apollo.MutationResult<Types.AddSubjectPropMutation>;
export type AddSubjectPropMutationOptions = Apollo.BaseMutationOptions<Types.AddSubjectPropMutation, Types.AddSubjectPropMutationVariables>;
export const UpdateSubjectPropDocument = gql`
    mutation updateSubjectProp($set: t_subject_prop_set_input, $where: t_subject_prop_bool_exp!) {
  update_t_subject_prop(where: $where, _set: $set) {
    returning {
      id: subject_prop_id
      subject_prop_id
    }
  }
}
    `;
export type UpdateSubjectPropMutationFn = Apollo.MutationFunction<Types.UpdateSubjectPropMutation, Types.UpdateSubjectPropMutationVariables>;

/**
 * __useUpdateSubjectPropMutation__
 *
 * To run a mutation, you first call `useUpdateSubjectPropMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubjectPropMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubjectPropMutation, { data, loading, error }] = useUpdateSubjectPropMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateSubjectPropMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateSubjectPropMutation, Types.UpdateSubjectPropMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateSubjectPropMutation, Types.UpdateSubjectPropMutationVariables>(UpdateSubjectPropDocument, options);
      }
export type UpdateSubjectPropMutationHookResult = ReturnType<typeof useUpdateSubjectPropMutation>;
export type UpdateSubjectPropMutationResult = Apollo.MutationResult<Types.UpdateSubjectPropMutation>;
export type UpdateSubjectPropMutationOptions = Apollo.BaseMutationOptions<Types.UpdateSubjectPropMutation, Types.UpdateSubjectPropMutationVariables>;
export const DeleteSubjectPropDocument = gql`
    mutation deleteSubjectProp($where: t_subject_prop_bool_exp!) {
  delete_t_subject_prop(where: $where) {
    affected_rows
  }
}
    `;
export type DeleteSubjectPropMutationFn = Apollo.MutationFunction<Types.DeleteSubjectPropMutation, Types.DeleteSubjectPropMutationVariables>;

/**
 * __useDeleteSubjectPropMutation__
 *
 * To run a mutation, you first call `useDeleteSubjectPropMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubjectPropMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubjectPropMutation, { data, loading, error }] = useDeleteSubjectPropMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteSubjectPropMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteSubjectPropMutation, Types.DeleteSubjectPropMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteSubjectPropMutation, Types.DeleteSubjectPropMutationVariables>(DeleteSubjectPropDocument, options);
      }
export type DeleteSubjectPropMutationHookResult = ReturnType<typeof useDeleteSubjectPropMutation>;
export type DeleteSubjectPropMutationResult = Apollo.MutationResult<Types.DeleteSubjectPropMutation>;
export type DeleteSubjectPropMutationOptions = Apollo.BaseMutationOptions<Types.DeleteSubjectPropMutation, Types.DeleteSubjectPropMutationVariables>;
export const UpdateSubjectByPkDocument = gql`
    mutation updateSubjectByPk($subject: t_subject_set_input, $subjectId: Int!, $append: t_subject_append_input) {
  subject: update_t_subject_by_pk(
    pk_columns: {subject_id: $subjectId}
    _set: $subject
    _append: $append
  ) {
    id: subject_id
  }
}
    `;
export type UpdateSubjectByPkMutationFn = Apollo.MutationFunction<Types.UpdateSubjectByPkMutation, Types.UpdateSubjectByPkMutationVariables>;

/**
 * __useUpdateSubjectByPkMutation__
 *
 * To run a mutation, you first call `useUpdateSubjectByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubjectByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubjectByPkMutation, { data, loading, error }] = useUpdateSubjectByPkMutation({
 *   variables: {
 *      subject: // value for 'subject'
 *      subjectId: // value for 'subjectId'
 *      append: // value for 'append'
 *   },
 * });
 */
export function useUpdateSubjectByPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateSubjectByPkMutation, Types.UpdateSubjectByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateSubjectByPkMutation, Types.UpdateSubjectByPkMutationVariables>(UpdateSubjectByPkDocument, options);
      }
export type UpdateSubjectByPkMutationHookResult = ReturnType<typeof useUpdateSubjectByPkMutation>;
export type UpdateSubjectByPkMutationResult = Apollo.MutationResult<Types.UpdateSubjectByPkMutation>;
export type UpdateSubjectByPkMutationOptions = Apollo.BaseMutationOptions<Types.UpdateSubjectByPkMutation, Types.UpdateSubjectByPkMutationVariables>;
export const AddSubjectDocument = gql`
    mutation addSubject($subject: t_subject_insert_input!) {
  subject: insert_t_subject_one(object: $subject) {
    id: subject_id
  }
}
    `;
export type AddSubjectMutationFn = Apollo.MutationFunction<Types.AddSubjectMutation, Types.AddSubjectMutationVariables>;

/**
 * __useAddSubjectMutation__
 *
 * To run a mutation, you first call `useAddSubjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubjectMutation, { data, loading, error }] = useAddSubjectMutation({
 *   variables: {
 *      subject: // value for 'subject'
 *   },
 * });
 */
export function useAddSubjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddSubjectMutation, Types.AddSubjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddSubjectMutation, Types.AddSubjectMutationVariables>(AddSubjectDocument, options);
      }
export type AddSubjectMutationHookResult = ReturnType<typeof useAddSubjectMutation>;
export type AddSubjectMutationResult = Apollo.MutationResult<Types.AddSubjectMutation>;
export type AddSubjectMutationOptions = Apollo.BaseMutationOptions<Types.AddSubjectMutation, Types.AddSubjectMutationVariables>;
export const AddArrayTaskLinkDocument = gql`
    mutation addArrayTaskLink($links: [t_task_link_insert_input!]!) {
  task_link: insert_t_task_link(objects: $links) {
    affected_rows
    returning {
      link_type_id
    }
  }
}
    `;
export type AddArrayTaskLinkMutationFn = Apollo.MutationFunction<Types.AddArrayTaskLinkMutation, Types.AddArrayTaskLinkMutationVariables>;

/**
 * __useAddArrayTaskLinkMutation__
 *
 * To run a mutation, you first call `useAddArrayTaskLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddArrayTaskLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addArrayTaskLinkMutation, { data, loading, error }] = useAddArrayTaskLinkMutation({
 *   variables: {
 *      links: // value for 'links'
 *   },
 * });
 */
export function useAddArrayTaskLinkMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddArrayTaskLinkMutation, Types.AddArrayTaskLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddArrayTaskLinkMutation, Types.AddArrayTaskLinkMutationVariables>(AddArrayTaskLinkDocument, options);
      }
export type AddArrayTaskLinkMutationHookResult = ReturnType<typeof useAddArrayTaskLinkMutation>;
export type AddArrayTaskLinkMutationResult = Apollo.MutationResult<Types.AddArrayTaskLinkMutation>;
export type AddArrayTaskLinkMutationOptions = Apollo.BaseMutationOptions<Types.AddArrayTaskLinkMutation, Types.AddArrayTaskLinkMutationVariables>;
export const DeleteArrayTaskLinkDocument = gql`
    mutation deleteArrayTaskLink($linksId: [bigint!]!) {
  delete_t_task_link(where: {task_link_id: {_in: $linksId}}) {
    affected_rows
  }
}
    `;
export type DeleteArrayTaskLinkMutationFn = Apollo.MutationFunction<Types.DeleteArrayTaskLinkMutation, Types.DeleteArrayTaskLinkMutationVariables>;

/**
 * __useDeleteArrayTaskLinkMutation__
 *
 * To run a mutation, you first call `useDeleteArrayTaskLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArrayTaskLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArrayTaskLinkMutation, { data, loading, error }] = useDeleteArrayTaskLinkMutation({
 *   variables: {
 *      linksId: // value for 'linksId'
 *   },
 * });
 */
export function useDeleteArrayTaskLinkMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteArrayTaskLinkMutation, Types.DeleteArrayTaskLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteArrayTaskLinkMutation, Types.DeleteArrayTaskLinkMutationVariables>(DeleteArrayTaskLinkDocument, options);
      }
export type DeleteArrayTaskLinkMutationHookResult = ReturnType<typeof useDeleteArrayTaskLinkMutation>;
export type DeleteArrayTaskLinkMutationResult = Apollo.MutationResult<Types.DeleteArrayTaskLinkMutation>;
export type DeleteArrayTaskLinkMutationOptions = Apollo.BaseMutationOptions<Types.DeleteArrayTaskLinkMutation, Types.DeleteArrayTaskLinkMutationVariables>;
export const AddTaskPropDocument = gql`
    mutation addTaskProp($taskProp: [t_task_prop_insert_input!]!) {
  insert_t_task_prop(objects: $taskProp) {
    returning {
      task_prop_id
    }
  }
}
    `;
export type AddTaskPropMutationFn = Apollo.MutationFunction<Types.AddTaskPropMutation, Types.AddTaskPropMutationVariables>;

/**
 * __useAddTaskPropMutation__
 *
 * To run a mutation, you first call `useAddTaskPropMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaskPropMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaskPropMutation, { data, loading, error }] = useAddTaskPropMutation({
 *   variables: {
 *      taskProp: // value for 'taskProp'
 *   },
 * });
 */
export function useAddTaskPropMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddTaskPropMutation, Types.AddTaskPropMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddTaskPropMutation, Types.AddTaskPropMutationVariables>(AddTaskPropDocument, options);
      }
export type AddTaskPropMutationHookResult = ReturnType<typeof useAddTaskPropMutation>;
export type AddTaskPropMutationResult = Apollo.MutationResult<Types.AddTaskPropMutation>;
export type AddTaskPropMutationOptions = Apollo.BaseMutationOptions<Types.AddTaskPropMutation, Types.AddTaskPropMutationVariables>;
export const AddTaskDocument = gql`
    mutation addTask($objects: [t_task_insert_input!]!) {
  insert_t_task(objects: $objects) {
    returning {
      task_id
    }
  }
}
    `;
export type AddTaskMutationFn = Apollo.MutationFunction<Types.AddTaskMutation, Types.AddTaskMutationVariables>;

/**
 * __useAddTaskMutation__
 *
 * To run a mutation, you first call `useAddTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaskMutation, { data, loading, error }] = useAddTaskMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddTaskMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddTaskMutation, Types.AddTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddTaskMutation, Types.AddTaskMutationVariables>(AddTaskDocument, options);
      }
export type AddTaskMutationHookResult = ReturnType<typeof useAddTaskMutation>;
export type AddTaskMutationResult = Apollo.MutationResult<Types.AddTaskMutation>;
export type AddTaskMutationOptions = Apollo.BaseMutationOptions<Types.AddTaskMutation, Types.AddTaskMutationVariables>;
export const AddTaskOneDocument = gql`
    mutation addTaskOne($task: t_task_insert_input!) {
  insert_t_task_one(object: $task) {
    task_id
    id: task_id
  }
}
    `;
export type AddTaskOneMutationFn = Apollo.MutationFunction<Types.AddTaskOneMutation, Types.AddTaskOneMutationVariables>;

/**
 * __useAddTaskOneMutation__
 *
 * To run a mutation, you first call `useAddTaskOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaskOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaskOneMutation, { data, loading, error }] = useAddTaskOneMutation({
 *   variables: {
 *      task: // value for 'task'
 *   },
 * });
 */
export function useAddTaskOneMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddTaskOneMutation, Types.AddTaskOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddTaskOneMutation, Types.AddTaskOneMutationVariables>(AddTaskOneDocument, options);
      }
export type AddTaskOneMutationHookResult = ReturnType<typeof useAddTaskOneMutation>;
export type AddTaskOneMutationResult = Apollo.MutationResult<Types.AddTaskOneMutation>;
export type AddTaskOneMutationOptions = Apollo.BaseMutationOptions<Types.AddTaskOneMutation, Types.AddTaskOneMutationVariables>;
export const UpdateTaskByPkDocument = gql`
    mutation updateTaskByPk($taskId: bigint!, $set: t_task_set_input, $append: t_task_append_input) {
  task: update_t_task_by_pk(
    pk_columns: {task_id: $taskId}
    _set: $set
    _append: $append
  ) {
    task_id
  }
}
    `;
export type UpdateTaskByPkMutationFn = Apollo.MutationFunction<Types.UpdateTaskByPkMutation, Types.UpdateTaskByPkMutationVariables>;

/**
 * __useUpdateTaskByPkMutation__
 *
 * To run a mutation, you first call `useUpdateTaskByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskByPkMutation, { data, loading, error }] = useUpdateTaskByPkMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      set: // value for 'set'
 *      append: // value for 'append'
 *   },
 * });
 */
export function useUpdateTaskByPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTaskByPkMutation, Types.UpdateTaskByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTaskByPkMutation, Types.UpdateTaskByPkMutationVariables>(UpdateTaskByPkDocument, options);
      }
export type UpdateTaskByPkMutationHookResult = ReturnType<typeof useUpdateTaskByPkMutation>;
export type UpdateTaskByPkMutationResult = Apollo.MutationResult<Types.UpdateTaskByPkMutation>;
export type UpdateTaskByPkMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTaskByPkMutation, Types.UpdateTaskByPkMutationVariables>;
export const UpdateTaskDocument = gql`
    mutation updateTask($taskId: bigint, $statusId: Int, $updUser: Int) {
  update_t_task(
    where: {task_id: {_eq: $taskId}}
    _set: {status_id: $statusId, upd_user: $updUser}
  ) {
    returning {
      task_id
    }
  }
}
    `;
export type UpdateTaskMutationFn = Apollo.MutationFunction<Types.UpdateTaskMutation, Types.UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      statusId: // value for 'statusId'
 *      updUser: // value for 'updUser'
 *   },
 * });
 */
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTaskMutation, Types.UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTaskMutation, Types.UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<Types.UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTaskMutation, Types.UpdateTaskMutationVariables>;
export const AddUserDocument = gql`
    mutation addUser($user: t_user_insert_input!) {
  insert_t_user_one(object: $user) {
    ...defaultUserInfo
  }
}
    ${DefaultUserInfoFragmentDoc}`;
export type AddUserMutationFn = Apollo.MutationFunction<Types.AddUserMutation, Types.AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddUserMutation, Types.AddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddUserMutation, Types.AddUserMutationVariables>(AddUserDocument, options);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = Apollo.MutationResult<Types.AddUserMutation>;
export type AddUserMutationOptions = Apollo.BaseMutationOptions<Types.AddUserMutation, Types.AddUserMutationVariables>;
export const GetPaymentsListDocument = gql`
    query getPaymentsList($offset: Int, $limit: Int, $orderBy: [t_payment_imports_order_by!], $where: t_payment_imports_bool_exp) {
  t_payment_imports(
    where: $where
    offset: $offset
    limit: $limit
    order_by: $orderBy
  ) {
    date_invoice
    description
    guid_bds
    guid_invoice
    num_invoice
    is_partial
    partner_guid
    partner_name
    payment_id
    sum_bds
    sum_invoice
  }
}
    `;

/**
 * __useGetPaymentsListQuery__
 *
 * To run a query within a React component, call `useGetPaymentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsListQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPaymentsListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetPaymentsListQuery, Types.GetPaymentsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPaymentsListQuery, Types.GetPaymentsListQueryVariables>(GetPaymentsListDocument, options);
      }
export function useGetPaymentsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPaymentsListQuery, Types.GetPaymentsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPaymentsListQuery, Types.GetPaymentsListQueryVariables>(GetPaymentsListDocument, options);
        }
export type GetPaymentsListQueryHookResult = ReturnType<typeof useGetPaymentsListQuery>;
export type GetPaymentsListLazyQueryHookResult = ReturnType<typeof useGetPaymentsListLazyQuery>;
export type GetPaymentsListQueryResult = Apollo.QueryResult<Types.GetPaymentsListQuery, Types.GetPaymentsListQueryVariables>;
export const GetVPaymentsListDocument = gql`
    query getVPaymentsList($offset: Int, $limit: Int, $orderBy: [v_payments_order_by!], $where: v_payments_bool_exp) {
  v_payments(where: $where, offset: $offset, limit: $limit, order_by: $orderBy) {
    date_invoice
    description
    guid_bds
    guid_invoice
    num_invoice
    is_partial
    partner_guid
    partner_name
    payment_id
    sum_bds
    sum_invoice
    subject_id
    doc_id
  }
}
    `;

/**
 * __useGetVPaymentsListQuery__
 *
 * To run a query within a React component, call `useGetVPaymentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVPaymentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVPaymentsListQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetVPaymentsListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetVPaymentsListQuery, Types.GetVPaymentsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetVPaymentsListQuery, Types.GetVPaymentsListQueryVariables>(GetVPaymentsListDocument, options);
      }
export function useGetVPaymentsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetVPaymentsListQuery, Types.GetVPaymentsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetVPaymentsListQuery, Types.GetVPaymentsListQueryVariables>(GetVPaymentsListDocument, options);
        }
export type GetVPaymentsListQueryHookResult = ReturnType<typeof useGetVPaymentsListQuery>;
export type GetVPaymentsListLazyQueryHookResult = ReturnType<typeof useGetVPaymentsListLazyQuery>;
export type GetVPaymentsListQueryResult = Apollo.QueryResult<Types.GetVPaymentsListQuery, Types.GetVPaymentsListQueryVariables>;
export const GetCommentActivityDocument = gql`
    query getCommentActivity($limit: Int, $offset: Int, $where: v_comment_activity_bool_exp, $orderBy: [v_comment_activity_order_by!]) {
  comments: v_comment_activity(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    key: comment_id
    text
    partner_name
    ins_date
    char_date
    manager {
      lastname
      name
      avatar
    }
    comment_id
    subject_id
    task_id
  }
}
    `;

/**
 * __useGetCommentActivityQuery__
 *
 * To run a query within a React component, call `useGetCommentActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentActivityQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetCommentActivityQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCommentActivityQuery, Types.GetCommentActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCommentActivityQuery, Types.GetCommentActivityQueryVariables>(GetCommentActivityDocument, options);
      }
export function useGetCommentActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCommentActivityQuery, Types.GetCommentActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCommentActivityQuery, Types.GetCommentActivityQueryVariables>(GetCommentActivityDocument, options);
        }
export type GetCommentActivityQueryHookResult = ReturnType<typeof useGetCommentActivityQuery>;
export type GetCommentActivityLazyQueryHookResult = ReturnType<typeof useGetCommentActivityLazyQuery>;
export type GetCommentActivityQueryResult = Apollo.QueryResult<Types.GetCommentActivityQuery, Types.GetCommentActivityQueryVariables>;
export const GetContentListDocument = gql`
    query getContentList($where: t_content_bool_exp = {deleted: {_eq: false}}, $orderBy: [t_content_order_by!] = [{ins_date: desc}], $limit: Int) {
  t_content(order_by: $orderBy, where: $where) {
    body
    brief
    content_id
    id: content_id
    ins_date_string
    ins_time_string
    description
    title
    view_order
    deleted
    image: object_file_links(
      where: {object_type: {brief: {_eq: "CONTENT"}}, link_type: {brief: {_eq: "FOTOGRAFIYA"}}, file: {file_type: {_eq: "Image"}, deleted: {_eq: false}}}
      limit: 1
    ) {
      id: file_link_id
      file_link_id
      file {
        id: file_id
        file_id
        file_name
        file_path
      }
    }
    file_list: object_file_links(
      where: {object_type: {brief: {_eq: "CONTENT"}}, link_type: {brief: {_eq: "FILE"}}, file: {file_type: {_eq: "File"}, deleted: {_eq: false}}}
    ) {
      id: file_link_id
      file_link_id
      file {
        id: file_id
        file_id
        file_name
        file_path
      }
    }
    owner_subject {
      lastname
      name
      phone
      surname
      subject_id
      if: subject_id
    }
    owner_id
  }
}
    `;

/**
 * __useGetContentListQuery__
 *
 * To run a query within a React component, call `useGetContentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetContentListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetContentListQuery, Types.GetContentListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetContentListQuery, Types.GetContentListQueryVariables>(GetContentListDocument, options);
      }
export function useGetContentListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetContentListQuery, Types.GetContentListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetContentListQuery, Types.GetContentListQueryVariables>(GetContentListDocument, options);
        }
export type GetContentListQueryHookResult = ReturnType<typeof useGetContentListQuery>;
export type GetContentListLazyQueryHookResult = ReturnType<typeof useGetContentListLazyQuery>;
export type GetContentListQueryResult = Apollo.QueryResult<Types.GetContentListQuery, Types.GetContentListQueryVariables>;
///
export const GetDocument1cListDocument = gql`
  query getDocumentList($offset: Int, $limit: Int, $orderBy: [t_doc_order_by!], $where: t_doc_bool_exp) {
    t_doc(where: $where, offset: $offset, limit: $limit, order_by: $orderBy) {
      id: doc_id
      number
      num_order
      ddmmyyyy
      partner {
        name
        subject_id
        __typename
      }
      amount
      t_order {
        order_sum
        __typename
      }
      order_id
      partner_1c
      __typename
    }
    __typename
  }
`;
export function useGetDocument1cListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetDocumentListQuery, Types.GetDocumentListQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<Types.GetDocumentListQuery, Types.GetDocumentListQueryVariables>(GetDocument1cListDocument, options);
}
export function useGetDocument1cListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDocumentListQuery, Types.GetDocumentListQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<Types.GetDocumentListQuery, Types.GetDocumentListQueryVariables>(GetDocument1cListDocument, options);
  }
export type GetDocument1cListQueryHookResult = ReturnType<typeof useGetDocument1cListQuery>;
export type GetDocument1cListLazyQueryHookResult = ReturnType<typeof useGetDocument1cListLazyQuery>;
export type GetDocument1cListQueryResult = Apollo.QueryResult<Types.GetDocumentListQuery, Types.GetDocumentListQueryVariables>;
///


export const GetDocumentListDocument = gql`
    query getDocumentList($offset: Int, $limit: Int, $orderBy: [t_doc_order_by!], $where: t_doc_bool_exp) {
  t_doc(where: $where, offset: $offset, limit: $limit, order_by: $orderBy) {
    ...documentInfo
  }
}
    ${DocumentInfoFragmentDoc}`;

/**
 * __useGetDocumentListQuery__
 *
 * To run a query within a React component, call `useGetDocumentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentListQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDocumentListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetDocumentListQuery, Types.GetDocumentListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDocumentListQuery, Types.GetDocumentListQueryVariables>(GetDocumentListDocument, options);
      }
export function useGetDocumentListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDocumentListQuery, Types.GetDocumentListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDocumentListQuery, Types.GetDocumentListQueryVariables>(GetDocumentListDocument, options);
        }
export type GetDocumentListQueryHookResult = ReturnType<typeof useGetDocumentListQuery>;
export type GetDocumentListLazyQueryHookResult = ReturnType<typeof useGetDocumentListLazyQuery>;
export type GetDocumentListQueryResult = Apollo.QueryResult<Types.GetDocumentListQuery, Types.GetDocumentListQueryVariables>;
export const GetDocumentByParamsDocument = gql`
    query getDocumentByParams($partnerId: bigint) {
  t_doc(
    where: {partner_id: {_eq: $partnerId}, deleted: {_eq: false}}
    order_by: {number: asc}
  ) {
    ...documentInfo
  }
}
    ${DocumentInfoFragmentDoc}`;

/**
 * __useGetDocumentByParamsQuery__
 *
 * To run a query within a React component, call `useGetDocumentByParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentByParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentByParamsQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useGetDocumentByParamsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetDocumentByParamsQuery, Types.GetDocumentByParamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDocumentByParamsQuery, Types.GetDocumentByParamsQueryVariables>(GetDocumentByParamsDocument, options);
      }
export function useGetDocumentByParamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDocumentByParamsQuery, Types.GetDocumentByParamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDocumentByParamsQuery, Types.GetDocumentByParamsQueryVariables>(GetDocumentByParamsDocument, options);
        }
export type GetDocumentByParamsQueryHookResult = ReturnType<typeof useGetDocumentByParamsQuery>;
export type GetDocumentByParamsLazyQueryHookResult = ReturnType<typeof useGetDocumentByParamsLazyQuery>;
export type GetDocumentByParamsQueryResult = Apollo.QueryResult<Types.GetDocumentByParamsQuery, Types.GetDocumentByParamsQueryVariables>;
export const GetDocumentSearchDocument = gql`
    query getDocumentSearch($partnerId: bigint, $num: String) {
  t_doc(
    where: {partner_id: {_eq: $partnerId}, number: {_ilike: $num}, deleted: {_eq: false}}
    order_by: {ins_date: desc}
  ) {
    ...documentInfo
  }
}
    ${DocumentInfoFragmentDoc}`;

/**
 * __useGetDocumentSearchQuery__
 *
 * To run a query within a React component, call `useGetDocumentSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentSearchQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      num: // value for 'num'
 *   },
 * });
 */
export function useGetDocumentSearchQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetDocumentSearchQuery, Types.GetDocumentSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDocumentSearchQuery, Types.GetDocumentSearchQueryVariables>(GetDocumentSearchDocument, options);
      }
export function useGetDocumentSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDocumentSearchQuery, Types.GetDocumentSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDocumentSearchQuery, Types.GetDocumentSearchQueryVariables>(GetDocumentSearchDocument, options);
        }
export type GetDocumentSearchQueryHookResult = ReturnType<typeof useGetDocumentSearchQuery>;
export type GetDocumentSearchLazyQueryHookResult = ReturnType<typeof useGetDocumentSearchLazyQuery>;
export type GetDocumentSearchQueryResult = Apollo.QueryResult<Types.GetDocumentSearchQuery, Types.GetDocumentSearchQueryVariables>;
export const GetDocumentByIdDocument = gql`
    query getDocumentById($id: bigint!) {
  doc: t_doc_by_pk(doc_id: $id) {
    ...documentInfo
  }
}
    ${DocumentInfoFragmentDoc}`;

/**
 * __useGetDocumentByIdQuery__
 *
 * To run a query within a React component, call `useGetDocumentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocumentByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetDocumentByIdQuery, Types.GetDocumentByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDocumentByIdQuery, Types.GetDocumentByIdQueryVariables>(GetDocumentByIdDocument, options);
      }
export function useGetDocumentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDocumentByIdQuery, Types.GetDocumentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDocumentByIdQuery, Types.GetDocumentByIdQueryVariables>(GetDocumentByIdDocument, options);
        }
export type GetDocumentByIdQueryHookResult = ReturnType<typeof useGetDocumentByIdQuery>;
export type GetDocumentByIdLazyQueryHookResult = ReturnType<typeof useGetDocumentByIdLazyQuery>;
export type GetDocumentByIdQueryResult = Apollo.QueryResult<Types.GetDocumentByIdQuery, Types.GetDocumentByIdQueryVariables>;
export const CheckDocumentExistDocument = gql`
    query checkDocumentExist($operDate: date, $partnerId: bigint, $number: String, $amount: numeric) {
  docs: t_doc(
    where: {number: {_eq: $number}, amount: {_eq: $amount}, oper_date: {_eq: $operDate}, partner_id: {_eq: $partnerId}, deleted: {_neq: true}}
  ) {
    doc_id
    amount
    number
    oper_date
    description
  }
}
    `;

/**
 * __useCheckDocumentExistQuery__
 *
 * To run a query within a React component, call `useCheckDocumentExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDocumentExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDocumentExistQuery({
 *   variables: {
 *      operDate: // value for 'operDate'
 *      partnerId: // value for 'partnerId'
 *      number: // value for 'number'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useCheckDocumentExistQuery(baseOptions?: Apollo.QueryHookOptions<Types.CheckDocumentExistQuery, Types.CheckDocumentExistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckDocumentExistQuery, Types.CheckDocumentExistQueryVariables>(CheckDocumentExistDocument, options);
      }
export function useCheckDocumentExistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckDocumentExistQuery, Types.CheckDocumentExistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckDocumentExistQuery, Types.CheckDocumentExistQueryVariables>(CheckDocumentExistDocument, options);
        }
export type CheckDocumentExistQueryHookResult = ReturnType<typeof useCheckDocumentExistQuery>;
export type CheckDocumentExistLazyQueryHookResult = ReturnType<typeof useCheckDocumentExistLazyQuery>;
export type CheckDocumentExistQueryResult = Apollo.QueryResult<Types.CheckDocumentExistQuery, Types.CheckDocumentExistQueryVariables>;
export const GetCountDocumentsDocument = gql`
    query getCountDocuments($where: t_doc_bool_exp) {
  t_doc_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetCountDocumentsQuery__
 *
 * To run a query within a React component, call `useGetCountDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountDocumentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCountDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCountDocumentsQuery, Types.GetCountDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCountDocumentsQuery, Types.GetCountDocumentsQueryVariables>(GetCountDocumentsDocument, options);
      }
export function useGetCountDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCountDocumentsQuery, Types.GetCountDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCountDocumentsQuery, Types.GetCountDocumentsQueryVariables>(GetCountDocumentsDocument, options);
        }
export type GetCountDocumentsQueryHookResult = ReturnType<typeof useGetCountDocumentsQuery>;
export type GetCountDocumentsLazyQueryHookResult = ReturnType<typeof useGetCountDocumentsLazyQuery>;
export type GetCountDocumentsQueryResult = Apollo.QueryResult<Types.GetCountDocumentsQuery, Types.GetCountDocumentsQueryVariables>;
export const GetDocsListDocument = gql`
    query getDocsList($where: v_doc_import_bool_exp, $orderBy: [v_doc_import_order_by!], $limit: Int) {
  rows: v_doc_import(where: $where, order_by: $orderBy, limit: $limit) {
    ins_date
    is_hold
    partner_id
    query_guid
    sum
    sum_bill
    sum_by_orders
    sum_free
    doc_date
    sum_akt
    doc_not_exist
    partner {
      name
    }
  }
}
    `;

/**
 * __useGetDocsListQuery__
 *
 * To run a query within a React component, call `useGetDocsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocsListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDocsListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetDocsListQuery, Types.GetDocsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDocsListQuery, Types.GetDocsListQueryVariables>(GetDocsListDocument, options);
      }
export function useGetDocsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDocsListQuery, Types.GetDocsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDocsListQuery, Types.GetDocsListQueryVariables>(GetDocsListDocument, options);
        }
export type GetDocsListQueryHookResult = ReturnType<typeof useGetDocsListQuery>;
export type GetDocsListLazyQueryHookResult = ReturnType<typeof useGetDocsListLazyQuery>;
export type GetDocsListQueryResult = Apollo.QueryResult<Types.GetDocsListQuery, Types.GetDocsListQueryVariables>;
export const GetDocRowsDocument = gql`
    query getDocRows($where: t_doc_imports_bool_exp, $orderBy: [t_doc_imports_order_by!] = [{ftran_id: desc_nulls_first, id: desc}]) {
  rows: t_doc_imports(where: $where, order_by: $orderBy) {
    amount
    bill_required
    date
    doc_number
    ftran_id
    maket
    manager
    manager_id
    owner {
      name
      lastname
    }
    order_date
    order_date_2
    pp_number
    pp_date
    product
    status
    query_guid
    tirazh
    is_hold
    key: id
    id
    ftran {
      t_doc {
        doc_id
        number
        oper_date
      }
      t_order {
        order_id
      }
    }
    partner_id
    manager_comment
    buh_comment
    price
  }
}
    `;

/**
 * __useGetDocRowsQuery__
 *
 * To run a query within a React component, call `useGetDocRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocRowsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetDocRowsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetDocRowsQuery, Types.GetDocRowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDocRowsQuery, Types.GetDocRowsQueryVariables>(GetDocRowsDocument, options);
      }
export function useGetDocRowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDocRowsQuery, Types.GetDocRowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDocRowsQuery, Types.GetDocRowsQueryVariables>(GetDocRowsDocument, options);
        }
export type GetDocRowsQueryHookResult = ReturnType<typeof useGetDocRowsQuery>;
export type GetDocRowsLazyQueryHookResult = ReturnType<typeof useGetDocRowsLazyQuery>;
export type GetDocRowsQueryResult = Apollo.QueryResult<Types.GetDocRowsQuery, Types.GetDocRowsQueryVariables>;
export const GetDocDocument = gql`
    query getDoc($queryGuid: String) {
  rows: v_doc_import(where: {query_guid: {_eq: $queryGuid}}) {
    ins_date
    is_hold
    partner_id
    query_guid
    sum
    sum_bill
    sum_by_orders
    sum_free
    doc_date
    partner {
      name
    }
  }
}
    `;

/**
 * __useGetDocQuery__
 *
 * To run a query within a React component, call `useGetDocQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocQuery({
 *   variables: {
 *      queryGuid: // value for 'queryGuid'
 *   },
 * });
 */
export function useGetDocQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetDocQuery, Types.GetDocQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDocQuery, Types.GetDocQueryVariables>(GetDocDocument, options);
      }
export function useGetDocLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDocQuery, Types.GetDocQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDocQuery, Types.GetDocQueryVariables>(GetDocDocument, options);
        }
export type GetDocQueryHookResult = ReturnType<typeof useGetDocQuery>;
export type GetDocLazyQueryHookResult = ReturnType<typeof useGetDocLazyQuery>;
export type GetDocQueryResult = Apollo.QueryResult<Types.GetDocQuery, Types.GetDocQueryVariables>;
export const SetDocsDocument = gql`
    query setDocs($queryGuid: String, $docId: Int) {
  set_imported_doc(args: {query: $queryGuid, docid: $docId}) {
    ftrans: json
  }
}
    `;

/**
 * __useSetDocsQuery__
 *
 * To run a query within a React component, call `useSetDocsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetDocsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetDocsQuery({
 *   variables: {
 *      queryGuid: // value for 'queryGuid'
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useSetDocsQuery(baseOptions?: Apollo.QueryHookOptions<Types.SetDocsQuery, Types.SetDocsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SetDocsQuery, Types.SetDocsQueryVariables>(SetDocsDocument, options);
      }
export function useSetDocsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SetDocsQuery, Types.SetDocsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SetDocsQuery, Types.SetDocsQueryVariables>(SetDocsDocument, options);
        }
export type SetDocsQueryHookResult = ReturnType<typeof useSetDocsQuery>;
export type SetDocsLazyQueryHookResult = ReturnType<typeof useSetDocsLazyQuery>;
export type SetDocsQueryResult = Apollo.QueryResult<Types.SetDocsQuery, Types.SetDocsQueryVariables>;
export const UpdateImportedRowDocument = gql`
    query updateImportedRow($rowId: Int, $docId: Int) {
  set_imported_row(args: {row_id: $rowId, docid: $docId}) {
    totals: json
  }
}
    `;

/**
 * __useUpdateImportedRowQuery__
 *
 * To run a query within a React component, call `useUpdateImportedRowQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateImportedRowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateImportedRowQuery({
 *   variables: {
 *      rowId: // value for 'rowId'
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useUpdateImportedRowQuery(baseOptions?: Apollo.QueryHookOptions<Types.UpdateImportedRowQuery, Types.UpdateImportedRowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UpdateImportedRowQuery, Types.UpdateImportedRowQueryVariables>(UpdateImportedRowDocument, options);
      }
export function useUpdateImportedRowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UpdateImportedRowQuery, Types.UpdateImportedRowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UpdateImportedRowQuery, Types.UpdateImportedRowQueryVariables>(UpdateImportedRowDocument, options);
        }
export type UpdateImportedRowQueryHookResult = ReturnType<typeof useUpdateImportedRowQuery>;
export type UpdateImportedRowLazyQueryHookResult = ReturnType<typeof useUpdateImportedRowLazyQuery>;
export type UpdateImportedRowQueryResult = Apollo.QueryResult<Types.UpdateImportedRowQuery, Types.UpdateImportedRowQueryVariables>;
export const CopyImportedRowDocument = gql`
    query copyImportedRow($rowId: Int!) {
  copy_imported_row(args: {rowid: $rowId}) {
    totals: json
  }
}
    `;

/**
 * __useCopyImportedRowQuery__
 *
 * To run a query within a React component, call `useCopyImportedRowQuery` and pass it any options that fit your needs.
 * When your component renders, `useCopyImportedRowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCopyImportedRowQuery({
 *   variables: {
 *      rowId: // value for 'rowId'
 *   },
 * });
 */
export function useCopyImportedRowQuery(baseOptions: Apollo.QueryHookOptions<Types.CopyImportedRowQuery, Types.CopyImportedRowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CopyImportedRowQuery, Types.CopyImportedRowQueryVariables>(CopyImportedRowDocument, options);
      }
export function useCopyImportedRowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CopyImportedRowQuery, Types.CopyImportedRowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CopyImportedRowQuery, Types.CopyImportedRowQueryVariables>(CopyImportedRowDocument, options);
        }
export type CopyImportedRowQueryHookResult = ReturnType<typeof useCopyImportedRowQuery>;
export type CopyImportedRowLazyQueryHookResult = ReturnType<typeof useCopyImportedRowLazyQuery>;
export type CopyImportedRowQueryResult = Apollo.QueryResult<Types.CopyImportedRowQuery, Types.CopyImportedRowQueryVariables>;
export const GetEmployeesListDocument = gql`
    query getEmployeesList($where: t_subject_bool_exp = {deleted: {_eq: false}}, $limit: Int, $offset: Int, $orderBy: [t_subject_order_by!] = [{name: asc}]) {
  employee_list: t_subject(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    subject_id
    id: subject_id
    name
    lastname
    phone
    email
    is_active
    avatar
    sales_areas {
      object_id
      sales_area {
        sales_area_id
      }
    }
    branch: subject_branch
    city: props(where: {prop: {brief: {_eq: "CITY"}}}) {
      prop_value {
        brief
        value
        id: prop_value_id
      }
      prop {
        brief
        name
        id: prop_id
      }
      prop_value_id
      prop_id
      id: subject_prop_id
      subject_prop_id
    }
    dolznost: props(where: {prop: {brief: {_eq: "DOLZHNOST"}}}) {
      prop_value {
        brief
        value
        id: prop_value_id
      }
      prop {
        brief
        name
        id: prop_id
      }
      prop_value_id
      prop_id
      id: subject_prop_id
      subject_prop_id
    }
  }
}
    `;

/**
 * __useGetEmployeesListQuery__
 *
 * To run a query within a React component, call `useGetEmployeesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetEmployeesListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetEmployeesListQuery, Types.GetEmployeesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmployeesListQuery, Types.GetEmployeesListQueryVariables>(GetEmployeesListDocument, options);
      }
export function useGetEmployeesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployeesListQuery, Types.GetEmployeesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmployeesListQuery, Types.GetEmployeesListQueryVariables>(GetEmployeesListDocument, options);
        }
export type GetEmployeesListQueryHookResult = ReturnType<typeof useGetEmployeesListQuery>;
export type GetEmployeesListLazyQueryHookResult = ReturnType<typeof useGetEmployeesListLazyQuery>;
export type GetEmployeesListQueryResult = Apollo.QueryResult<Types.GetEmployeesListQuery, Types.GetEmployeesListQueryVariables>;
export const GetEmployeesCountDocument = gql`
    query getEmployeesCount($where: t_subject_bool_exp = {deleted: {_eq: false}}) {
  employee: t_subject_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetEmployeesCountQuery__
 *
 * To run a query within a React component, call `useGetEmployeesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetEmployeesCountQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetEmployeesCountQuery, Types.GetEmployeesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmployeesCountQuery, Types.GetEmployeesCountQueryVariables>(GetEmployeesCountDocument, options);
      }
export function useGetEmployeesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployeesCountQuery, Types.GetEmployeesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmployeesCountQuery, Types.GetEmployeesCountQueryVariables>(GetEmployeesCountDocument, options);
        }
export type GetEmployeesCountQueryHookResult = ReturnType<typeof useGetEmployeesCountQuery>;
export type GetEmployeesCountLazyQueryHookResult = ReturnType<typeof useGetEmployeesCountLazyQuery>;
export type GetEmployeesCountQueryResult = Apollo.QueryResult<Types.GetEmployeesCountQuery, Types.GetEmployeesCountQueryVariables>;
export const GetCurrentEmployeeDocument = gql`
    query getCurrentEmployee($where: t_subject_bool_exp = {deleted: {_eq: false}}, $limit: Int, $offset: Int, $orderBy: [t_subject_order_by!] = [{subject_id: desc}]) {
  employee: t_subject(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...currentEmployeeInfo
  }
}
    ${CurrentEmployeeInfoFragmentDoc}`;

/**
 * __useGetCurrentEmployeeQuery__
 *
 * To run a query within a React component, call `useGetCurrentEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentEmployeeQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetCurrentEmployeeQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCurrentEmployeeQuery, Types.GetCurrentEmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCurrentEmployeeQuery, Types.GetCurrentEmployeeQueryVariables>(GetCurrentEmployeeDocument, options);
      }
export function useGetCurrentEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCurrentEmployeeQuery, Types.GetCurrentEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCurrentEmployeeQuery, Types.GetCurrentEmployeeQueryVariables>(GetCurrentEmployeeDocument, options);
        }
export type GetCurrentEmployeeQueryHookResult = ReturnType<typeof useGetCurrentEmployeeQuery>;
export type GetCurrentEmployeeLazyQueryHookResult = ReturnType<typeof useGetCurrentEmployeeLazyQuery>;
export type GetCurrentEmployeeQueryResult = Apollo.QueryResult<Types.GetCurrentEmployeeQuery, Types.GetCurrentEmployeeQueryVariables>;
export const GetFtranListDocument = gql`
    query getFtranList($where: t_ftran_bool_exp, $limit: Int, $offset: Int, $orderBy: [t_ftran_order_by!]) {
  t_ftran(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    doc_id
    id: ftran_id
    subject_id
    amount
    description
    t_doc {
      number
      oper_date
      doc_rest_expenses
      amount
      partner {
        lastname
        surname
        name
        full_name
        id: subject_id
        subject_id
      }
    }
    manager: t_subject {
      lastname
      name
      full_name
      surname
      id: subject_id
      subject_id
    }
    doc_import {
      id
      query_guid
      partner_id
      partner {
        name
        subject_id
      }
    }
  }
}
    `;

/**
 * __useGetFtranListQuery__
 *
 * To run a query within a React component, call `useGetFtranListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFtranListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFtranListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetFtranListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetFtranListQuery, Types.GetFtranListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFtranListQuery, Types.GetFtranListQueryVariables>(GetFtranListDocument, options);
      }
export function useGetFtranListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFtranListQuery, Types.GetFtranListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFtranListQuery, Types.GetFtranListQueryVariables>(GetFtranListDocument, options);
        }
export type GetFtranListQueryHookResult = ReturnType<typeof useGetFtranListQuery>;
export type GetFtranListLazyQueryHookResult = ReturnType<typeof useGetFtranListLazyQuery>;
export type GetFtranListQueryResult = Apollo.QueryResult<Types.GetFtranListQuery, Types.GetFtranListQueryVariables>;
export const GetFtranCountDocument = gql`
    query getFtranCount($where: t_ftran_bool_exp) {
  t_ftran_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetFtranCountQuery__
 *
 * To run a query within a React component, call `useGetFtranCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFtranCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFtranCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetFtranCountQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetFtranCountQuery, Types.GetFtranCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFtranCountQuery, Types.GetFtranCountQueryVariables>(GetFtranCountDocument, options);
      }
export function useGetFtranCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFtranCountQuery, Types.GetFtranCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFtranCountQuery, Types.GetFtranCountQueryVariables>(GetFtranCountDocument, options);
        }
export type GetFtranCountQueryHookResult = ReturnType<typeof useGetFtranCountQuery>;
export type GetFtranCountLazyQueryHookResult = ReturnType<typeof useGetFtranCountLazyQuery>;
export type GetFtranCountQueryResult = Apollo.QueryResult<Types.GetFtranCountQuery, Types.GetFtranCountQueryVariables>;
export const FindManagersDocument = gql`
    query findManagers($queryGuid: String) {
  find_imported_managers(args: {query: $queryGuid}) {
    managers: json
  }
}
    `;

/**
 * __useFindManagersQuery__
 *
 * To run a query within a React component, call `useFindManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManagersQuery({
 *   variables: {
 *      queryGuid: // value for 'queryGuid'
 *   },
 * });
 */
export function useFindManagersQuery(baseOptions?: Apollo.QueryHookOptions<Types.FindManagersQuery, Types.FindManagersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FindManagersQuery, Types.FindManagersQueryVariables>(FindManagersDocument, options);
      }
export function useFindManagersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FindManagersQuery, Types.FindManagersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FindManagersQuery, Types.FindManagersQueryVariables>(FindManagersDocument, options);
        }
export type FindManagersQueryHookResult = ReturnType<typeof useFindManagersQuery>;
export type FindManagersLazyQueryHookResult = ReturnType<typeof useFindManagersLazyQuery>;
export type FindManagersQueryResult = Apollo.QueryResult<Types.FindManagersQuery, Types.FindManagersQueryVariables>;
export const NotifyManagersDocument = gql`
    query notifyManagers($queryGuid: String) {
  notify_managers_import(args: {query: $queryGuid}) {
    managers: json
  }
}
    `;

/**
 * __useNotifyManagersQuery__
 *
 * To run a query within a React component, call `useNotifyManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotifyManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotifyManagersQuery({
 *   variables: {
 *      queryGuid: // value for 'queryGuid'
 *   },
 * });
 */
export function useNotifyManagersQuery(baseOptions?: Apollo.QueryHookOptions<Types.NotifyManagersQuery, Types.NotifyManagersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NotifyManagersQuery, Types.NotifyManagersQueryVariables>(NotifyManagersDocument, options);
      }
export function useNotifyManagersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NotifyManagersQuery, Types.NotifyManagersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NotifyManagersQuery, Types.NotifyManagersQueryVariables>(NotifyManagersDocument, options);
        }
export type NotifyManagersQueryHookResult = ReturnType<typeof useNotifyManagersQuery>;
export type NotifyManagersLazyQueryHookResult = ReturnType<typeof useNotifyManagersLazyQuery>;
export type NotifyManagersQueryResult = Apollo.QueryResult<Types.NotifyManagersQuery, Types.NotifyManagersQueryVariables>;
export const GetMessagesListDocument = gql`
    query getMessagesList($where: t_message_bool_exp, $orderBy: [t_message_order_by!] = [{ins_date: desc}], $limit: Int, $offset: Int) {
  messages: t_message(
    where: $where
    order_by: $orderBy
    limit: $limit
    offset: $offset
  ) {
    body
    doc
    hold
    ins_date_string
    ins_time_string
    message_id
    id: message_id
    msg_type
    task: doc(path: "task")
    link: doc(path: "link")
    url: doc(path: "url")
    external_url: doc(path: "external_url")
    comment: doc(path: "comment")
    task_update: doc(path: "task_update")
    subject_link: doc(path: "subject_link")
    recipient {
      lastname
      name
      subject_id
      id: subject_id
    }
    see
    recipient_id
    sent
    title
    sender
  }
}
    `;

/**
 * __useGetMessagesListQuery__
 *
 * To run a query within a React component, call `useGetMessagesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessagesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessagesListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetMessagesListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetMessagesListQuery, Types.GetMessagesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMessagesListQuery, Types.GetMessagesListQueryVariables>(GetMessagesListDocument, options);
      }
export function useGetMessagesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMessagesListQuery, Types.GetMessagesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMessagesListQuery, Types.GetMessagesListQueryVariables>(GetMessagesListDocument, options);
        }
export type GetMessagesListQueryHookResult = ReturnType<typeof useGetMessagesListQuery>;
export type GetMessagesListLazyQueryHookResult = ReturnType<typeof useGetMessagesListLazyQuery>;
export type GetMessagesListQueryResult = Apollo.QueryResult<Types.GetMessagesListQuery, Types.GetMessagesListQueryVariables>;
export const GetMessagesCountDocument = gql`
    query getMessagesCount($where: t_message_bool_exp) {
  messages: t_message_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetMessagesCountQuery__
 *
 * To run a query within a React component, call `useGetMessagesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessagesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessagesCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetMessagesCountQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetMessagesCountQuery, Types.GetMessagesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMessagesCountQuery, Types.GetMessagesCountQueryVariables>(GetMessagesCountDocument, options);
      }
export function useGetMessagesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMessagesCountQuery, Types.GetMessagesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMessagesCountQuery, Types.GetMessagesCountQueryVariables>(GetMessagesCountDocument, options);
        }
export type GetMessagesCountQueryHookResult = ReturnType<typeof useGetMessagesCountQuery>;
export type GetMessagesCountLazyQueryHookResult = ReturnType<typeof useGetMessagesCountLazyQuery>;
export type GetMessagesCountQueryResult = Apollo.QueryResult<Types.GetMessagesCountQuery, Types.GetMessagesCountQueryVariables>;
export const GetStatusListDocument = gql`
    query getStatusList($orderTypeId: Int, $orderBy: [t_order_status_order_by!] = [{view_order: asc}]) {
  t_order_status(where: {order_type_id: {_eq: $orderTypeId}}, order_by: $orderBy) {
    name
    order_status_id
    id: order_status_id
    order_type_id
  }
}
    `;

/**
 * __useGetStatusListQuery__
 *
 * To run a query within a React component, call `useGetStatusListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusListQuery({
 *   variables: {
 *      orderTypeId: // value for 'orderTypeId'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetStatusListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetStatusListQuery, Types.GetStatusListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStatusListQuery, Types.GetStatusListQueryVariables>(GetStatusListDocument, options);
      }
export function useGetStatusListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStatusListQuery, Types.GetStatusListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStatusListQuery, Types.GetStatusListQueryVariables>(GetStatusListDocument, options);
        }
export type GetStatusListQueryHookResult = ReturnType<typeof useGetStatusListQuery>;
export type GetStatusListLazyQueryHookResult = ReturnType<typeof useGetStatusListLazyQuery>;
export type GetStatusListQueryResult = Apollo.QueryResult<Types.GetStatusListQuery, Types.GetStatusListQueryVariables>;
export const GetOrderTypeListDocument = gql`
    query getOrderTypeList {
  t_order_type {
    brief
    id: order_type_id
    name
    order_statuses {
      name
      id: order_status_id
    }
  }
}
    `;

/**
 * __useGetOrderTypeListQuery__
 *
 * To run a query within a React component, call `useGetOrderTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderTypeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrderTypeListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetOrderTypeListQuery, Types.GetOrderTypeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOrderTypeListQuery, Types.GetOrderTypeListQueryVariables>(GetOrderTypeListDocument, options);
      }
export function useGetOrderTypeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOrderTypeListQuery, Types.GetOrderTypeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOrderTypeListQuery, Types.GetOrderTypeListQueryVariables>(GetOrderTypeListDocument, options);
        }
export type GetOrderTypeListQueryHookResult = ReturnType<typeof useGetOrderTypeListQuery>;
export type GetOrderTypeListLazyQueryHookResult = ReturnType<typeof useGetOrderTypeListLazyQuery>;
export type GetOrderTypeListQueryResult = Apollo.QueryResult<Types.GetOrderTypeListQuery, Types.GetOrderTypeListQueryVariables>;
export const GetOrderlineListDocument = gql`
    query getOrderlineList($where: t_orderline_bool_exp, $orderBy: [t_orderline_order_by!], $limit: Int, $offset: Int) {
  orderline_list: t_orderline(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    comment
    caption
    quantity
    orderline_id
    id: orderline_id
    status
    quantity
    purchase_price
    purchase_amount
    fixed_cost
    price
    amount
    provider
    marzh
    marzh_percent
    jdoc
    expenses
  }
  count: t_orderline_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetOrderlineListQuery__
 *
 * To run a query within a React component, call `useGetOrderlineListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderlineListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderlineListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetOrderlineListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetOrderlineListQuery, Types.GetOrderlineListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOrderlineListQuery, Types.GetOrderlineListQueryVariables>(GetOrderlineListDocument, options);
      }
export function useGetOrderlineListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOrderlineListQuery, Types.GetOrderlineListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOrderlineListQuery, Types.GetOrderlineListQueryVariables>(GetOrderlineListDocument, options);
        }
export type GetOrderlineListQueryHookResult = ReturnType<typeof useGetOrderlineListQuery>;
export type GetOrderlineListLazyQueryHookResult = ReturnType<typeof useGetOrderlineListLazyQuery>;
export type GetOrderlineListQueryResult = Apollo.QueryResult<Types.GetOrderlineListQuery, Types.GetOrderlineListQueryVariables>;
export const OrderlineRowNumUpDocument = gql`
    query orderlineRowNumUp($rowId: bigint) {
  orderline_rownum_up_wrap(args: {rowid: $rowId}) {
    result: json
  }
}
    `;

/**
 * __useOrderlineRowNumUpQuery__
 *
 * To run a query within a React component, call `useOrderlineRowNumUpQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderlineRowNumUpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderlineRowNumUpQuery({
 *   variables: {
 *      rowId: // value for 'rowId'
 *   },
 * });
 */
export function useOrderlineRowNumUpQuery(baseOptions?: Apollo.QueryHookOptions<Types.OrderlineRowNumUpQuery, Types.OrderlineRowNumUpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrderlineRowNumUpQuery, Types.OrderlineRowNumUpQueryVariables>(OrderlineRowNumUpDocument, options);
      }
export function useOrderlineRowNumUpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrderlineRowNumUpQuery, Types.OrderlineRowNumUpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrderlineRowNumUpQuery, Types.OrderlineRowNumUpQueryVariables>(OrderlineRowNumUpDocument, options);
        }
export type OrderlineRowNumUpQueryHookResult = ReturnType<typeof useOrderlineRowNumUpQuery>;
export type OrderlineRowNumUpLazyQueryHookResult = ReturnType<typeof useOrderlineRowNumUpLazyQuery>;
export type OrderlineRowNumUpQueryResult = Apollo.QueryResult<Types.OrderlineRowNumUpQuery, Types.OrderlineRowNumUpQueryVariables>;
export const OrderlineRowNumDownDocument = gql`
    query orderlineRowNumDown($rowId: bigint) {
  orderline_rownum_down_wrap(args: {rowid: $rowId}) {
    result: json
  }
}
    `;

/**
 * __useOrderlineRowNumDownQuery__
 *
 * To run a query within a React component, call `useOrderlineRowNumDownQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderlineRowNumDownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderlineRowNumDownQuery({
 *   variables: {
 *      rowId: // value for 'rowId'
 *   },
 * });
 */
export function useOrderlineRowNumDownQuery(baseOptions?: Apollo.QueryHookOptions<Types.OrderlineRowNumDownQuery, Types.OrderlineRowNumDownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrderlineRowNumDownQuery, Types.OrderlineRowNumDownQueryVariables>(OrderlineRowNumDownDocument, options);
      }
export function useOrderlineRowNumDownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrderlineRowNumDownQuery, Types.OrderlineRowNumDownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrderlineRowNumDownQuery, Types.OrderlineRowNumDownQueryVariables>(OrderlineRowNumDownDocument, options);
        }
export type OrderlineRowNumDownQueryHookResult = ReturnType<typeof useOrderlineRowNumDownQuery>;
export type OrderlineRowNumDownLazyQueryHookResult = ReturnType<typeof useOrderlineRowNumDownLazyQuery>;
export type OrderlineRowNumDownQueryResult = Apollo.QueryResult<Types.OrderlineRowNumDownQuery, Types.OrderlineRowNumDownQueryVariables>;
export const GetOrderListDocument = gql`
    query getOrderList($where: t_order_bool_exp, $limit: Int, $offset: Int, $orderBy: [t_order_order_by!]) {
  order_list: t_order(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    last_1c_order_date
    last_1c_order_number
    max_date_upd
    max_date_invoice
    list_numbers_upd
    list_numbers_invoice
    archived
    marzh_order_fakt
    order_expenses_fakt
    marzh_order_fakt_perc
    invoice_date
    invoice_number
    bill_number
    bill_date
    deal_owner {
      name
      lastname
    }
    id: order_id
    order_id
    customer_id
    format_ins_date
    customer {
      name
      lastname
      subject_id
    }
    description
    order_sum
    orderline {
      id: orderline_id
      orderline_id
    }
    status_id
    status {
      name
    }
    order_quarter
    manager: customer {
      subject_links(where: {t_link_type: {brief: {_in: ["PARTNER_MANAGER"]}}}) {
        subject_object {
          avatar
          lastname
          name
        }
      }
    }
    priority: props(where: {prop: {brief: {_eq: "ORDER_PRIORITY"}}}) {
      id: order_prop_id
      order_prop_id
      prop_id
      prop_value_id
      prop {
        name
      }
      prop_value {
        value
      }
    }
  }
  order_list_count: t_order_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetOrderListQuery__
 *
 * To run a query within a React component, call `useGetOrderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetOrderListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetOrderListQuery, Types.GetOrderListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOrderListQuery, Types.GetOrderListQueryVariables>(GetOrderListDocument, options);
      }
export function useGetOrderListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOrderListQuery, Types.GetOrderListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOrderListQuery, Types.GetOrderListQueryVariables>(GetOrderListDocument, options);
        }
export type GetOrderListQueryHookResult = ReturnType<typeof useGetOrderListQuery>;
export type GetOrderListLazyQueryHookResult = ReturnType<typeof useGetOrderListLazyQuery>;
export type GetOrderListQueryResult = Apollo.QueryResult<Types.GetOrderListQuery, Types.GetOrderListQueryVariables>;
export const GetCurrentOrderDocument = gql`
    query getCurrentOrder($orderId: Int!, $limit: Int) {
  t_order(
    where: {deleted: {_eq: false}, order_id: {_eq: $orderId}}
    limit: $limit
  ) {
    ...defaultCurrentOrderInfo
  }
}
    ${DefaultCurrentOrderInfoFragmentDoc}`;

/**
 * __useGetCurrentOrderQuery__
 *
 * To run a query within a React component, call `useGetCurrentOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCurrentOrderQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCurrentOrderQuery, Types.GetCurrentOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCurrentOrderQuery, Types.GetCurrentOrderQueryVariables>(GetCurrentOrderDocument, options);
      }
export function useGetCurrentOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCurrentOrderQuery, Types.GetCurrentOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCurrentOrderQuery, Types.GetCurrentOrderQueryVariables>(GetCurrentOrderDocument, options);
        }
export type GetCurrentOrderQueryHookResult = ReturnType<typeof useGetCurrentOrderQuery>;
export type GetCurrentOrderLazyQueryHookResult = ReturnType<typeof useGetCurrentOrderLazyQuery>;
export type GetCurrentOrderQueryResult = Apollo.QueryResult<Types.GetCurrentOrderQuery, Types.GetCurrentOrderQueryVariables>;
export const GetCountOrdersDocument = gql`
    query getCountOrders($customerId: Int) {
  t_order_aggregate(
    where: {deleted: {_eq: false}, customer_id: {_eq: $customerId}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetCountOrdersQuery__
 *
 * To run a query within a React component, call `useGetCountOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountOrdersQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCountOrdersQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCountOrdersQuery, Types.GetCountOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCountOrdersQuery, Types.GetCountOrdersQueryVariables>(GetCountOrdersDocument, options);
      }
export function useGetCountOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCountOrdersQuery, Types.GetCountOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCountOrdersQuery, Types.GetCountOrdersQueryVariables>(GetCountOrdersDocument, options);
        }
export type GetCountOrdersQueryHookResult = ReturnType<typeof useGetCountOrdersQuery>;
export type GetCountOrdersLazyQueryHookResult = ReturnType<typeof useGetCountOrdersLazyQuery>;
export type GetCountOrdersQueryResult = Apollo.QueryResult<Types.GetCountOrdersQuery, Types.GetCountOrdersQueryVariables>;
export const GetOrderListDashboardDocument = gql`
    query getOrderListDashboard($where: t_order_bool_exp, $orderBy: [t_order_order_by!] = [{order_id: asc}], $limit: Int, $offset: Int) {
  t_order(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
    ...defaultOrderInfo
  }
}
    ${DefaultOrderInfoFragmentDoc}`;

/**
 * __useGetOrderListDashboardQuery__
 *
 * To run a query within a React component, call `useGetOrderListDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderListDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderListDashboardQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetOrderListDashboardQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetOrderListDashboardQuery, Types.GetOrderListDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOrderListDashboardQuery, Types.GetOrderListDashboardQueryVariables>(GetOrderListDashboardDocument, options);
      }
export function useGetOrderListDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOrderListDashboardQuery, Types.GetOrderListDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOrderListDashboardQuery, Types.GetOrderListDashboardQueryVariables>(GetOrderListDashboardDocument, options);
        }
export type GetOrderListDashboardQueryHookResult = ReturnType<typeof useGetOrderListDashboardQuery>;
export type GetOrderListDashboardLazyQueryHookResult = ReturnType<typeof useGetOrderListDashboardLazyQuery>;
export type GetOrderListDashboardQueryResult = Apollo.QueryResult<Types.GetOrderListDashboardQuery, Types.GetOrderListDashboardQueryVariables>;
export const GetOrderCountDocument = gql`
    query getOrderCount($where: t_order_bool_exp) {
  t_order_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetOrderCountQuery__
 *
 * To run a query within a React component, call `useGetOrderCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetOrderCountQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetOrderCountQuery, Types.GetOrderCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOrderCountQuery, Types.GetOrderCountQueryVariables>(GetOrderCountDocument, options);
      }
export function useGetOrderCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOrderCountQuery, Types.GetOrderCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOrderCountQuery, Types.GetOrderCountQueryVariables>(GetOrderCountDocument, options);
        }
export type GetOrderCountQueryHookResult = ReturnType<typeof useGetOrderCountQuery>;
export type GetOrderCountLazyQueryHookResult = ReturnType<typeof useGetOrderCountLazyQuery>;
export type GetOrderCountQueryResult = Apollo.QueryResult<Types.GetOrderCountQuery, Types.GetOrderCountQueryVariables>;
export const GetOrderDocTotalsDocument = gql`
    query getOrderDocTotals($orderId: bigint) {
  data: get_order_doc_totals(args: {order_id: $orderId}) {
    totals: json
  }
}
    `;

/**
 * __useGetOrderDocTotalsQuery__
 *
 * To run a query within a React component, call `useGetOrderDocTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderDocTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderDocTotalsQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderDocTotalsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetOrderDocTotalsQuery, Types.GetOrderDocTotalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOrderDocTotalsQuery, Types.GetOrderDocTotalsQueryVariables>(GetOrderDocTotalsDocument, options);
      }
export function useGetOrderDocTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOrderDocTotalsQuery, Types.GetOrderDocTotalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOrderDocTotalsQuery, Types.GetOrderDocTotalsQueryVariables>(GetOrderDocTotalsDocument, options);
        }
export type GetOrderDocTotalsQueryHookResult = ReturnType<typeof useGetOrderDocTotalsQuery>;
export type GetOrderDocTotalsLazyQueryHookResult = ReturnType<typeof useGetOrderDocTotalsLazyQuery>;
export type GetOrderDocTotalsQueryResult = Apollo.QueryResult<Types.GetOrderDocTotalsQuery, Types.GetOrderDocTotalsQueryVariables>;
export const AddOrderCopyDocument = gql`
    query addOrderCopy($orderId: Int) {
  orders: copy_order_wrap(args: {order_id: $orderId}) {
    order_id
  }
}
    `;

/**
 * __useAddOrderCopyQuery__
 *
 * To run a query within a React component, call `useAddOrderCopyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddOrderCopyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddOrderCopyQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useAddOrderCopyQuery(baseOptions?: Apollo.QueryHookOptions<Types.AddOrderCopyQuery, Types.AddOrderCopyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AddOrderCopyQuery, Types.AddOrderCopyQueryVariables>(AddOrderCopyDocument, options);
      }
export function useAddOrderCopyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AddOrderCopyQuery, Types.AddOrderCopyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AddOrderCopyQuery, Types.AddOrderCopyQueryVariables>(AddOrderCopyDocument, options);
        }
export type AddOrderCopyQueryHookResult = ReturnType<typeof useAddOrderCopyQuery>;
export type AddOrderCopyLazyQueryHookResult = ReturnType<typeof useAddOrderCopyLazyQuery>;
export type AddOrderCopyQueryResult = Apollo.QueryResult<Types.AddOrderCopyQuery, Types.AddOrderCopyQueryVariables>;
export const NewOrderVersionDocument = gql`
    query newOrderVersion($orderId: bigint) {
  orders: new_order_version_wrap(args: {order_id: $orderId}) {
    ver
  }
}
    `;

/**
 * __useNewOrderVersionQuery__
 *
 * To run a query within a React component, call `useNewOrderVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewOrderVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewOrderVersionQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useNewOrderVersionQuery(baseOptions?: Apollo.QueryHookOptions<Types.NewOrderVersionQuery, Types.NewOrderVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NewOrderVersionQuery, Types.NewOrderVersionQueryVariables>(NewOrderVersionDocument, options);
      }
export function useNewOrderVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NewOrderVersionQuery, Types.NewOrderVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NewOrderVersionQuery, Types.NewOrderVersionQueryVariables>(NewOrderVersionDocument, options);
        }
export type NewOrderVersionQueryHookResult = ReturnType<typeof useNewOrderVersionQuery>;
export type NewOrderVersionLazyQueryHookResult = ReturnType<typeof useNewOrderVersionLazyQuery>;
export type NewOrderVersionQueryResult = Apollo.QueryResult<Types.NewOrderVersionQuery, Types.NewOrderVersionQueryVariables>;
export const GetOrderVersionsDocument = gql`
    query getOrderVersions($orderId: Int) {
  versions: v_order_versions(
    where: {order_id: {_eq: $orderId}, ver: {_is_null: false}}
    order_by: {ins_date: desc}
  ) {
    ver
  }
}
    `;

/**
 * __useGetOrderVersionsQuery__
 *
 * To run a query within a React component, call `useGetOrderVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderVersionsQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderVersionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetOrderVersionsQuery, Types.GetOrderVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOrderVersionsQuery, Types.GetOrderVersionsQueryVariables>(GetOrderVersionsDocument, options);
      }
export function useGetOrderVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOrderVersionsQuery, Types.GetOrderVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOrderVersionsQuery, Types.GetOrderVersionsQueryVariables>(GetOrderVersionsDocument, options);
        }
export type GetOrderVersionsQueryHookResult = ReturnType<typeof useGetOrderVersionsQuery>;
export type GetOrderVersionsLazyQueryHookResult = ReturnType<typeof useGetOrderVersionsLazyQuery>;
export type GetOrderVersionsQueryResult = Apollo.QueryResult<Types.GetOrderVersionsQuery, Types.GetOrderVersionsQueryVariables>;
export const GetOrderVersionTotalsDocument = gql`
    query getOrderVersionTotals($orderId: bigint, $version: String) {
  data: get_order_version_totals(args: {order_id: $orderId, ver: $version}) {
    totals: json
  }
}
    `;

/**
 * __useGetOrderVersionTotalsQuery__
 *
 * To run a query within a React component, call `useGetOrderVersionTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderVersionTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderVersionTotalsQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetOrderVersionTotalsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetOrderVersionTotalsQuery, Types.GetOrderVersionTotalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOrderVersionTotalsQuery, Types.GetOrderVersionTotalsQueryVariables>(GetOrderVersionTotalsDocument, options);
      }
export function useGetOrderVersionTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOrderVersionTotalsQuery, Types.GetOrderVersionTotalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOrderVersionTotalsQuery, Types.GetOrderVersionTotalsQueryVariables>(GetOrderVersionTotalsDocument, options);
        }
export type GetOrderVersionTotalsQueryHookResult = ReturnType<typeof useGetOrderVersionTotalsQuery>;
export type GetOrderVersionTotalsLazyQueryHookResult = ReturnType<typeof useGetOrderVersionTotalsLazyQuery>;
export type GetOrderVersionTotalsQueryResult = Apollo.QueryResult<Types.GetOrderVersionTotalsQuery, Types.GetOrderVersionTotalsQueryVariables>;
export const ChangeOrderVersionDocument = gql`
    query changeOrderVersion($orderId: bigint, $ver: String) {
  orders: change_order_version_wrap(args: {order_id: $orderId, source_ver: $ver}) {
    ver
  }
}
    `;

/**
 * __useChangeOrderVersionQuery__
 *
 * To run a query within a React component, call `useChangeOrderVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useChangeOrderVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChangeOrderVersionQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      ver: // value for 'ver'
 *   },
 * });
 */
export function useChangeOrderVersionQuery(baseOptions?: Apollo.QueryHookOptions<Types.ChangeOrderVersionQuery, Types.ChangeOrderVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ChangeOrderVersionQuery, Types.ChangeOrderVersionQueryVariables>(ChangeOrderVersionDocument, options);
      }
export function useChangeOrderVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ChangeOrderVersionQuery, Types.ChangeOrderVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ChangeOrderVersionQuery, Types.ChangeOrderVersionQueryVariables>(ChangeOrderVersionDocument, options);
        }
export type ChangeOrderVersionQueryHookResult = ReturnType<typeof useChangeOrderVersionQuery>;
export type ChangeOrderVersionLazyQueryHookResult = ReturnType<typeof useChangeOrderVersionLazyQuery>;
export type ChangeOrderVersionQueryResult = Apollo.QueryResult<Types.ChangeOrderVersionQuery, Types.ChangeOrderVersionQueryVariables>;
export const CheckOrderRestDocument = gql`
    query checkOrderRest($orderid: bigint) {
  order_doc_rest_expenses(args: {orderid: $orderid}) {
    result: json
  }
}
    `;

/**
 * __useCheckOrderRestQuery__
 *
 * To run a query within a React component, call `useCheckOrderRestQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckOrderRestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckOrderRestQuery({
 *   variables: {
 *      orderid: // value for 'orderid'
 *   },
 * });
 */
export function useCheckOrderRestQuery(baseOptions?: Apollo.QueryHookOptions<Types.CheckOrderRestQuery, Types.CheckOrderRestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckOrderRestQuery, Types.CheckOrderRestQueryVariables>(CheckOrderRestDocument, options);
      }
export function useCheckOrderRestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckOrderRestQuery, Types.CheckOrderRestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckOrderRestQuery, Types.CheckOrderRestQueryVariables>(CheckOrderRestDocument, options);
        }
export type CheckOrderRestQueryHookResult = ReturnType<typeof useCheckOrderRestQuery>;
export type CheckOrderRestLazyQueryHookResult = ReturnType<typeof useCheckOrderRestLazyQuery>;
export type CheckOrderRestQueryResult = Apollo.QueryResult<Types.CheckOrderRestQuery, Types.CheckOrderRestQueryVariables>;
export const CheckOrderDocUnclosedDocument = gql`
    query checkOrderDocUnclosed($orderid: bigint) {
  order_doc_unclosed(args: {orderid: $orderid}) {
    result: json
  }
}
    `;

/**
 * __useCheckOrderDocUnclosedQuery__
 *
 * To run a query within a React component, call `useCheckOrderDocUnclosedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckOrderDocUnclosedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckOrderDocUnclosedQuery({
 *   variables: {
 *      orderid: // value for 'orderid'
 *   },
 * });
 */
export function useCheckOrderDocUnclosedQuery(baseOptions?: Apollo.QueryHookOptions<Types.CheckOrderDocUnclosedQuery, Types.CheckOrderDocUnclosedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckOrderDocUnclosedQuery, Types.CheckOrderDocUnclosedQueryVariables>(CheckOrderDocUnclosedDocument, options);
      }
export function useCheckOrderDocUnclosedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckOrderDocUnclosedQuery, Types.CheckOrderDocUnclosedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckOrderDocUnclosedQuery, Types.CheckOrderDocUnclosedQueryVariables>(CheckOrderDocUnclosedDocument, options);
        }
export type CheckOrderDocUnclosedQueryHookResult = ReturnType<typeof useCheckOrderDocUnclosedQuery>;
export type CheckOrderDocUnclosedLazyQueryHookResult = ReturnType<typeof useCheckOrderDocUnclosedLazyQuery>;
export type CheckOrderDocUnclosedQueryResult = Apollo.QueryResult<Types.CheckOrderDocUnclosedQuery, Types.CheckOrderDocUnclosedQueryVariables>;
export const CheckOrderByInvoiceNumberAndYearDocument = gql`
    query CheckOrderByInvoiceNumberAndYear($invoiceNumber: String, $year: Int) {
  order: check_order_by_invoice_number_and_year(
    args: {invoice_number: $invoiceNumber, invoice_year: $year}
  ) {
    result: json
  }
}
    `;

/**
 * __useCheckOrderByInvoiceNumberAndYearQuery__
 *
 * To run a query within a React component, call `useCheckOrderByInvoiceNumberAndYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckOrderByInvoiceNumberAndYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckOrderByInvoiceNumberAndYearQuery({
 *   variables: {
 *      invoiceNumber: // value for 'invoiceNumber'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useCheckOrderByInvoiceNumberAndYearQuery(baseOptions?: Apollo.QueryHookOptions<Types.CheckOrderByInvoiceNumberAndYearQuery, Types.CheckOrderByInvoiceNumberAndYearQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckOrderByInvoiceNumberAndYearQuery, Types.CheckOrderByInvoiceNumberAndYearQueryVariables>(CheckOrderByInvoiceNumberAndYearDocument, options);
      }
export function useCheckOrderByInvoiceNumberAndYearLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckOrderByInvoiceNumberAndYearQuery, Types.CheckOrderByInvoiceNumberAndYearQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckOrderByInvoiceNumberAndYearQuery, Types.CheckOrderByInvoiceNumberAndYearQueryVariables>(CheckOrderByInvoiceNumberAndYearDocument, options);
        }
export type CheckOrderByInvoiceNumberAndYearQueryHookResult = ReturnType<typeof useCheckOrderByInvoiceNumberAndYearQuery>;
export type CheckOrderByInvoiceNumberAndYearLazyQueryHookResult = ReturnType<typeof useCheckOrderByInvoiceNumberAndYearLazyQuery>;
export type CheckOrderByInvoiceNumberAndYearQueryResult = Apollo.QueryResult<Types.CheckOrderByInvoiceNumberAndYearQuery, Types.CheckOrderByInvoiceNumberAndYearQueryVariables>;
export const CheckOrderByBillNumberAndYearDocument = gql`
    query CheckOrderByBillNumberAndYear($billNumber: String, $year: Int) {
  order: check_order_by_bill_number_and_year(
    args: {bill_number: $billNumber, bill_year: $year}
  ) {
    result: json
  }
}
    `;

/**
 * __useCheckOrderByBillNumberAndYearQuery__
 *
 * To run a query within a React component, call `useCheckOrderByBillNumberAndYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckOrderByBillNumberAndYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckOrderByBillNumberAndYearQuery({
 *   variables: {
 *      billNumber: // value for 'billNumber'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useCheckOrderByBillNumberAndYearQuery(baseOptions?: Apollo.QueryHookOptions<Types.CheckOrderByBillNumberAndYearQuery, Types.CheckOrderByBillNumberAndYearQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckOrderByBillNumberAndYearQuery, Types.CheckOrderByBillNumberAndYearQueryVariables>(CheckOrderByBillNumberAndYearDocument, options);
      }
export function useCheckOrderByBillNumberAndYearLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckOrderByBillNumberAndYearQuery, Types.CheckOrderByBillNumberAndYearQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckOrderByBillNumberAndYearQuery, Types.CheckOrderByBillNumberAndYearQueryVariables>(CheckOrderByBillNumberAndYearDocument, options);
        }
export type CheckOrderByBillNumberAndYearQueryHookResult = ReturnType<typeof useCheckOrderByBillNumberAndYearQuery>;
export type CheckOrderByBillNumberAndYearLazyQueryHookResult = ReturnType<typeof useCheckOrderByBillNumberAndYearLazyQuery>;
export type CheckOrderByBillNumberAndYearQueryResult = Apollo.QueryResult<Types.CheckOrderByBillNumberAndYearQuery, Types.CheckOrderByBillNumberAndYearQueryVariables>;


export const GetOrders1cByOrderDocument = gql`
    query getPaymentsByOrder($orderId: Int!, $limit: Int) {
  t_order(
    where: {deleted: {_eq: false}, order_id: {_eq: $orderId}}
    limit: $limit
  ) {
    customer {
      partner_docs(where: {doc_type_id: {_eq: 5}}) {
        doc_id
        ID: doc_id
        amount
        posted
        partner_id
        order_id
        oper_date
        number
        num_order
      }
    }
  }
}
    `;

/**
 * __useGetOrders1cByOrderQuery__
 *
 * To run a query within a React component, call `useGetOrders1cByOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrders1cByOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrders1cByOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetOrders1cByOrderQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPaymentsByOrderQuery, Types.GetPaymentsByOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPaymentsByOrderQuery, Types.GetPaymentsByOrderQueryVariables>(GetOrders1cByOrderDocument, options);
      }
export function useGetOrders1cByOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPaymentsByOrderQuery, Types.GetPaymentsByOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPaymentsByOrderQuery, Types.GetPaymentsByOrderQueryVariables>(GetOrders1cByOrderDocument, options);
        }
export type GetOrders1cByOrderQueryHookResult = ReturnType<typeof useGetOrders1cByOrderQuery>;
export type GetOrders1cByOrderLazyQueryHookResult = ReturnType<typeof useGetOrders1cByOrderLazyQuery>;
export type GetOrders1cByOrderQueryResult = Apollo.QueryResult<Types.GetPaymentsByOrderQuery, Types.GetPaymentsByOrderQueryVariables>;



export const GetPaymentsByOrderDocument = gql`
    query getPaymentsByOrder($orderId: Int!, $limit: Int) {
  t_order(
    where: {deleted: {_eq: false}, order_id: {_eq: $orderId}}
    limit: $limit
  ) {
    customer {
      partner_docs(where: {doc_type_id: {_eq: 3}}) {
        doc_id
        ID: doc_id
        amount
        posted
        partner_id
        order_id
        oper_date
        number
        num_order
      }
    }
  }
}
    `;

/**
 * __useGetPaymentsByOrderQuery__
 *
 * To run a query within a React component, call `useGetPaymentsByOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsByOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsByOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetPaymentsByOrderQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPaymentsByOrderQuery, Types.GetPaymentsByOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPaymentsByOrderQuery, Types.GetPaymentsByOrderQueryVariables>(GetPaymentsByOrderDocument, options);
      }
export function useGetPaymentsByOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPaymentsByOrderQuery, Types.GetPaymentsByOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPaymentsByOrderQuery, Types.GetPaymentsByOrderQueryVariables>(GetPaymentsByOrderDocument, options);
        }
export type GetPaymentsByOrderQueryHookResult = ReturnType<typeof useGetPaymentsByOrderQuery>;
export type GetPaymentsByOrderLazyQueryHookResult = ReturnType<typeof useGetPaymentsByOrderLazyQuery>;
export type GetPaymentsByOrderQueryResult = Apollo.QueryResult<Types.GetPaymentsByOrderQuery, Types.GetPaymentsByOrderQueryVariables>;

export const GetSalesByOrderDocument = gql`
    query getSalesByOrder($orderId: Int!, $limit: Int) {
  t_order(
    where: {deleted: {_eq: false}, order_id: {_eq: $orderId}}
    limit: $limit
  ) {
    customer {
      partner_docs(where: {doc_type_id: {_eq: 4}}) {
        doc_id
        ID: doc_id
        amount
        posted
        partner_id
        order_id
        oper_date
        number
      }
    }
  }
}
    `;

/**
 * __useGetSalesByOrderQuery__
 *
 * To run a query within a React component, call `useGetSalesByOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesByOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesByOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetSalesByOrderQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPaymentsByOrderQuery, Types.GetPaymentsByOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPaymentsByOrderQuery, Types.GetPaymentsByOrderQueryVariables>(GetSalesByOrderDocument, options);
      }
export function useGetSalesByOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPaymentsByOrderQuery, Types.GetPaymentsByOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPaymentsByOrderQuery, Types.GetPaymentsByOrderQueryVariables>(GetSalesByOrderDocument, options);
        }
export type GetSalesByOrderQueryHookResult = ReturnType<typeof useGetSalesByOrderQuery>;
export type GetSalesByOrderLazyQueryHookResult = ReturnType<typeof useGetSalesByOrderLazyQuery>;
export type GetSalesByOrderQueryResult = Apollo.QueryResult<Types.GetPaymentsByOrderQuery, Types.GetPaymentsByOrderQueryVariables>;

export const GetPropValueListDocument = gql`
    query getPropValueList($where: t_prop_value_bool_exp, $limit: Int, $orderBy: [t_prop_value_order_by!]) {
  t_prop_value(where: $where, limit: $limit, order_by: $orderBy) {
    brief
    prop_id
    value
    prop_value_id
    id: prop_value_id
    prop {
      brief
      name
    }
  }
}
    `;

/**
 * __useGetPropValueListQuery__
 *
 * To run a query within a React component, call `useGetPropValueListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropValueListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropValueListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetPropValueListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetPropValueListQuery, Types.GetPropValueListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPropValueListQuery, Types.GetPropValueListQueryVariables>(GetPropValueListDocument, options);
      }
export function useGetPropValueListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPropValueListQuery, Types.GetPropValueListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPropValueListQuery, Types.GetPropValueListQueryVariables>(GetPropValueListDocument, options);
        }
export type GetPropValueListQueryHookResult = ReturnType<typeof useGetPropValueListQuery>;
export type GetPropValueListLazyQueryHookResult = ReturnType<typeof useGetPropValueListLazyQuery>;
export type GetPropValueListQueryResult = Apollo.QueryResult<Types.GetPropValueListQuery, Types.GetPropValueListQueryVariables>;
export const GetPropsDocument = gql`
    query getProps {
  t_prop {
    id: prop_id
    name
    brief
  }
}
    `;

/**
 * __useGetPropsQuery__
 *
 * To run a query within a React component, call `useGetPropsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPropsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetPropsQuery, Types.GetPropsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPropsQuery, Types.GetPropsQueryVariables>(GetPropsDocument, options);
      }
export function useGetPropsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPropsQuery, Types.GetPropsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPropsQuery, Types.GetPropsQueryVariables>(GetPropsDocument, options);
        }
export type GetPropsQueryHookResult = ReturnType<typeof useGetPropsQuery>;
export type GetPropsLazyQueryHookResult = ReturnType<typeof useGetPropsLazyQuery>;
export type GetPropsQueryResult = Apollo.QueryResult<Types.GetPropsQuery, Types.GetPropsQueryVariables>;
export const GetCurrentBranchDocument = gql`
    query getCurrentBranch($where: t_sales_area_bool_exp, $orderBy: [t_sales_area_order_by!], $limit: Int, $offset: Int) {
  sales_area: t_sales_area(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...currentSalesAreaInfo
  }
}
    ${CurrentSalesAreaInfoFragmentDoc}`;

/**
 * __useGetCurrentBranchQuery__
 *
 * To run a query within a React component, call `useGetCurrentBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentBranchQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetCurrentBranchQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCurrentBranchQuery, Types.GetCurrentBranchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCurrentBranchQuery, Types.GetCurrentBranchQueryVariables>(GetCurrentBranchDocument, options);
      }
export function useGetCurrentBranchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCurrentBranchQuery, Types.GetCurrentBranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCurrentBranchQuery, Types.GetCurrentBranchQueryVariables>(GetCurrentBranchDocument, options);
        }
export type GetCurrentBranchQueryHookResult = ReturnType<typeof useGetCurrentBranchQuery>;
export type GetCurrentBranchLazyQueryHookResult = ReturnType<typeof useGetCurrentBranchLazyQuery>;
export type GetCurrentBranchQueryResult = Apollo.QueryResult<Types.GetCurrentBranchQuery, Types.GetCurrentBranchQueryVariables>;
export const GetSalesAreaForPartnersPageDocument = gql`
    query getSalesAreaForPartnersPage($where: t_sales_area_bool_exp, $orderBy: [t_sales_area_order_by!], $limit: Int, $offset: Int) {
  sales_area: t_sales_area(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...salesAreaForPartnerPageInfo
  }
}
    ${SalesAreaForPartnerPageInfoFragmentDoc}`;

/**
 * __useGetSalesAreaForPartnersPageQuery__
 *
 * To run a query within a React component, call `useGetSalesAreaForPartnersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesAreaForPartnersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesAreaForPartnersPageQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetSalesAreaForPartnersPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetSalesAreaForPartnersPageQuery, Types.GetSalesAreaForPartnersPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSalesAreaForPartnersPageQuery, Types.GetSalesAreaForPartnersPageQueryVariables>(GetSalesAreaForPartnersPageDocument, options);
      }
export function useGetSalesAreaForPartnersPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSalesAreaForPartnersPageQuery, Types.GetSalesAreaForPartnersPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSalesAreaForPartnersPageQuery, Types.GetSalesAreaForPartnersPageQueryVariables>(GetSalesAreaForPartnersPageDocument, options);
        }
export type GetSalesAreaForPartnersPageQueryHookResult = ReturnType<typeof useGetSalesAreaForPartnersPageQuery>;
export type GetSalesAreaForPartnersPageLazyQueryHookResult = ReturnType<typeof useGetSalesAreaForPartnersPageLazyQuery>;
export type GetSalesAreaForPartnersPageQueryResult = Apollo.QueryResult<Types.GetSalesAreaForPartnersPageQuery, Types.GetSalesAreaForPartnersPageQueryVariables>;
export const GetStatusSubjectListDocument = gql`
    query getStatusSubjectList($where: t_subject_status_bool_exp, $orderBy: [t_subject_status_order_by!]) {
  subject_status_list: t_subject_status(where: $where, order_by: $orderBy) {
    name
    id: subject_status_id
    subject_status_id
    subject_type_id
  }
}
    `;

/**
 * __useGetStatusSubjectListQuery__
 *
 * To run a query within a React component, call `useGetStatusSubjectListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusSubjectListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusSubjectListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetStatusSubjectListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetStatusSubjectListQuery, Types.GetStatusSubjectListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStatusSubjectListQuery, Types.GetStatusSubjectListQueryVariables>(GetStatusSubjectListDocument, options);
      }
export function useGetStatusSubjectListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStatusSubjectListQuery, Types.GetStatusSubjectListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStatusSubjectListQuery, Types.GetStatusSubjectListQueryVariables>(GetStatusSubjectListDocument, options);
        }
export type GetStatusSubjectListQueryHookResult = ReturnType<typeof useGetStatusSubjectListQuery>;
export type GetStatusSubjectListLazyQueryHookResult = ReturnType<typeof useGetStatusSubjectListLazyQuery>;
export type GetStatusSubjectListQueryResult = Apollo.QueryResult<Types.GetStatusSubjectListQuery, Types.GetStatusSubjectListQueryVariables>;
export const SubjectsSearchDocument = gql`
    query subjectsSearch($typeBrief: [String!], $searchString: String, $limit: Int) {
  t_subject(
    where: {name: {_ilike: $searchString}, deleted: {_eq: false}, subject_type: {brief: {_in: $typeBrief}}}
    limit: $limit
  ) {
    ...subjectInfo
  }
}
    ${SubjectInfoFragmentDoc}`;

/**
 * __useSubjectsSearchQuery__
 *
 * To run a query within a React component, call `useSubjectsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubjectsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubjectsSearchQuery({
 *   variables: {
 *      typeBrief: // value for 'typeBrief'
 *      searchString: // value for 'searchString'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSubjectsSearchQuery(baseOptions?: Apollo.QueryHookOptions<Types.SubjectsSearchQuery, Types.SubjectsSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SubjectsSearchQuery, Types.SubjectsSearchQueryVariables>(SubjectsSearchDocument, options);
      }
export function useSubjectsSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SubjectsSearchQuery, Types.SubjectsSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SubjectsSearchQuery, Types.SubjectsSearchQueryVariables>(SubjectsSearchDocument, options);
        }
export type SubjectsSearchQueryHookResult = ReturnType<typeof useSubjectsSearchQuery>;
export type SubjectsSearchLazyQueryHookResult = ReturnType<typeof useSubjectsSearchLazyQuery>;
export type SubjectsSearchQueryResult = Apollo.QueryResult<Types.SubjectsSearchQuery, Types.SubjectsSearchQueryVariables>;
export const GetCountSubjectDocument = gql`
    query getCountSubject($where: t_subject_bool_exp) {
  subject: t_subject_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetCountSubjectQuery__
 *
 * To run a query within a React component, call `useGetCountSubjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountSubjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountSubjectQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCountSubjectQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCountSubjectQuery, Types.GetCountSubjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCountSubjectQuery, Types.GetCountSubjectQueryVariables>(GetCountSubjectDocument, options);
      }
export function useGetCountSubjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCountSubjectQuery, Types.GetCountSubjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCountSubjectQuery, Types.GetCountSubjectQueryVariables>(GetCountSubjectDocument, options);
        }
export type GetCountSubjectQueryHookResult = ReturnType<typeof useGetCountSubjectQuery>;
export type GetCountSubjectLazyQueryHookResult = ReturnType<typeof useGetCountSubjectLazyQuery>;
export type GetCountSubjectQueryResult = Apollo.QueryResult<Types.GetCountSubjectQuery, Types.GetCountSubjectQueryVariables>;
export const GetSubjectByObjectLinksDocument = gql`
    query getSubjectByObjectLinks($linkType: String, $subjectId: Int) {
  t_subject(
    where: {object_subject_links: {t_link_type: {brief: {_eq: $linkType}}, t_object_type: {brief: {_eq: "SUBJECT"}}, subject_id: {_eq: $subjectId}}, deleted: {_eq: false}}
  ) {
    ...subjectInfo
  }
}
    ${SubjectInfoFragmentDoc}`;

/**
 * __useGetSubjectByObjectLinksQuery__
 *
 * To run a query within a React component, call `useGetSubjectByObjectLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubjectByObjectLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubjectByObjectLinksQuery({
 *   variables: {
 *      linkType: // value for 'linkType'
 *      subjectId: // value for 'subjectId'
 *   },
 * });
 */
export function useGetSubjectByObjectLinksQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetSubjectByObjectLinksQuery, Types.GetSubjectByObjectLinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSubjectByObjectLinksQuery, Types.GetSubjectByObjectLinksQueryVariables>(GetSubjectByObjectLinksDocument, options);
      }
export function useGetSubjectByObjectLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSubjectByObjectLinksQuery, Types.GetSubjectByObjectLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSubjectByObjectLinksQuery, Types.GetSubjectByObjectLinksQueryVariables>(GetSubjectByObjectLinksDocument, options);
        }
export type GetSubjectByObjectLinksQueryHookResult = ReturnType<typeof useGetSubjectByObjectLinksQuery>;
export type GetSubjectByObjectLinksLazyQueryHookResult = ReturnType<typeof useGetSubjectByObjectLinksLazyQuery>;
export type GetSubjectByObjectLinksQueryResult = Apollo.QueryResult<Types.GetSubjectByObjectLinksQuery, Types.GetSubjectByObjectLinksQueryVariables>;
export const GetCompanyListDocument = gql`
    query getCompanyList($where: t_subject_bool_exp = {deleted: {_eq: false}}, $limit: Int, $offset: Int, $orderBy: [t_subject_order_by!] = [{subject_id: desc}]) {
  company_list: t_subject(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...companyDefaultInfo
  }
}
    ${CompanyDefaultInfoFragmentDoc}`;

/**
 * __useGetCompanyListQuery__
 *
 * To run a query within a React component, call `useGetCompanyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetCompanyListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCompanyListQuery, Types.GetCompanyListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCompanyListQuery, Types.GetCompanyListQueryVariables>(GetCompanyListDocument, options);
      }
export function useGetCompanyListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCompanyListQuery, Types.GetCompanyListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCompanyListQuery, Types.GetCompanyListQueryVariables>(GetCompanyListDocument, options);
        }
export type GetCompanyListQueryHookResult = ReturnType<typeof useGetCompanyListQuery>;
export type GetCompanyListLazyQueryHookResult = ReturnType<typeof useGetCompanyListLazyQuery>;
export type GetCompanyListQueryResult = Apollo.QueryResult<Types.GetCompanyListQuery, Types.GetCompanyListQueryVariables>;
export const GetCountCompanyDocument = gql`
    query getCountCompany($where: t_subject_bool_exp = {deleted: {_eq: false}}) {
  company: t_subject_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetCountCompanyQuery__
 *
 * To run a query within a React component, call `useGetCountCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountCompanyQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCountCompanyQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCountCompanyQuery, Types.GetCountCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCountCompanyQuery, Types.GetCountCompanyQueryVariables>(GetCountCompanyDocument, options);
      }
export function useGetCountCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCountCompanyQuery, Types.GetCountCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCountCompanyQuery, Types.GetCountCompanyQueryVariables>(GetCountCompanyDocument, options);
        }
export type GetCountCompanyQueryHookResult = ReturnType<typeof useGetCountCompanyQuery>;
export type GetCountCompanyLazyQueryHookResult = ReturnType<typeof useGetCountCompanyLazyQuery>;
export type GetCountCompanyQueryResult = Apollo.QueryResult<Types.GetCountCompanyQuery, Types.GetCountCompanyQueryVariables>;
export const GetContactListDocument = gql`
    query getContactList($where: t_subject_bool_exp = {deleted: {_eq: false}}, $limit: Int, $offset: Int, $orderBy: [t_subject_order_by!] = [{subject_id: asc}]) {
  contact_list: t_subject(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...contactInfo
  }
}
    ${ContactInfoFragmentDoc}`;

/**
 * __useGetContactListQuery__
 *
 * To run a query within a React component, call `useGetContactListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetContactListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetContactListQuery, Types.GetContactListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetContactListQuery, Types.GetContactListQueryVariables>(GetContactListDocument, options);
      }
export function useGetContactListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetContactListQuery, Types.GetContactListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetContactListQuery, Types.GetContactListQueryVariables>(GetContactListDocument, options);
        }
export type GetContactListQueryHookResult = ReturnType<typeof useGetContactListQuery>;
export type GetContactListLazyQueryHookResult = ReturnType<typeof useGetContactListLazyQuery>;
export type GetContactListQueryResult = Apollo.QueryResult<Types.GetContactListQuery, Types.GetContactListQueryVariables>;
export const GetCountContactDocument = gql`
    query getCountContact($where: t_subject_bool_exp = {deleted: {_eq: false}}) {
  contact: t_subject_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetCountContactQuery__
 *
 * To run a query within a React component, call `useGetCountContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountContactQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCountContactQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCountContactQuery, Types.GetCountContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCountContactQuery, Types.GetCountContactQueryVariables>(GetCountContactDocument, options);
      }
export function useGetCountContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCountContactQuery, Types.GetCountContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCountContactQuery, Types.GetCountContactQueryVariables>(GetCountContactDocument, options);
        }
export type GetCountContactQueryHookResult = ReturnType<typeof useGetCountContactQuery>;
export type GetCountContactLazyQueryHookResult = ReturnType<typeof useGetCountContactLazyQuery>;
export type GetCountContactQueryResult = Apollo.QueryResult<Types.GetCountContactQuery, Types.GetCountContactQueryVariables>;
export const GetPartnerListDocument = gql`
    query getPartnerList($where: t_subject_bool_exp = {deleted: {_eq: false}}, $limit: Int, $offset: Int, $orderBy: [t_subject_order_by!] = [{subject_id: desc}]) {
  partner_list: t_subject(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...partnerInfo
  }
}
    ${PartnerInfoFragmentDoc}`;

/**
 * __useGetPartnerListQuery__
 *
 * To run a query within a React component, call `useGetPartnerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetPartnerListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetPartnerListQuery, Types.GetPartnerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPartnerListQuery, Types.GetPartnerListQueryVariables>(GetPartnerListDocument, options);
      }
export function useGetPartnerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPartnerListQuery, Types.GetPartnerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPartnerListQuery, Types.GetPartnerListQueryVariables>(GetPartnerListDocument, options);
        }
export type GetPartnerListQueryHookResult = ReturnType<typeof useGetPartnerListQuery>;
export type GetPartnerListLazyQueryHookResult = ReturnType<typeof useGetPartnerListLazyQuery>;
export type GetPartnerListQueryResult = Apollo.QueryResult<Types.GetPartnerListQuery, Types.GetPartnerListQueryVariables>;
export const GetCountPartnerDocument = gql`
    query getCountPartner($where: t_subject_bool_exp = {deleted: {_eq: false}}) {
  partner: t_subject_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetCountPartnerQuery__
 *
 * To run a query within a React component, call `useGetCountPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountPartnerQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCountPartnerQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCountPartnerQuery, Types.GetCountPartnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCountPartnerQuery, Types.GetCountPartnerQueryVariables>(GetCountPartnerDocument, options);
      }
export function useGetCountPartnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCountPartnerQuery, Types.GetCountPartnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCountPartnerQuery, Types.GetCountPartnerQueryVariables>(GetCountPartnerDocument, options);
        }
export type GetCountPartnerQueryHookResult = ReturnType<typeof useGetCountPartnerQuery>;
export type GetCountPartnerLazyQueryHookResult = ReturnType<typeof useGetCountPartnerLazyQuery>;
export type GetCountPartnerQueryResult = Apollo.QueryResult<Types.GetCountPartnerQuery, Types.GetCountPartnerQueryVariables>;
export const GetPartnerListForPartnersPageDocument = gql`
    query getPartnerListForPartnersPage($where: v_subject_search_bool_exp, $orderBy: [v_subject_search_order_by!], $offset: Int, $limit: Int) {
  partner_list: v_subject_search(
    where: $where
    order_by: $orderBy
    offset: $offset
    limit: $limit
  ) {
    ...partnerListInfoForPartnersPage
  }
  partner: v_subject_search_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    ${PartnerListInfoForPartnersPageFragmentDoc}`;

/**
 * __useGetPartnerListForPartnersPageQuery__
 *
 * To run a query within a React component, call `useGetPartnerListForPartnersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerListForPartnersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerListForPartnersPageQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetPartnerListForPartnersPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetPartnerListForPartnersPageQuery, Types.GetPartnerListForPartnersPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPartnerListForPartnersPageQuery, Types.GetPartnerListForPartnersPageQueryVariables>(GetPartnerListForPartnersPageDocument, options);
      }
export function useGetPartnerListForPartnersPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPartnerListForPartnersPageQuery, Types.GetPartnerListForPartnersPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPartnerListForPartnersPageQuery, Types.GetPartnerListForPartnersPageQueryVariables>(GetPartnerListForPartnersPageDocument, options);
        }
export type GetPartnerListForPartnersPageQueryHookResult = ReturnType<typeof useGetPartnerListForPartnersPageQuery>;
export type GetPartnerListForPartnersPageLazyQueryHookResult = ReturnType<typeof useGetPartnerListForPartnersPageLazyQuery>;
export type GetPartnerListForPartnersPageQueryResult = Apollo.QueryResult<Types.GetPartnerListForPartnersPageQuery, Types.GetPartnerListForPartnersPageQueryVariables>;
export const GetSubjectListDocument = gql`
    query getSubjectList($where: t_subject_bool_exp = {deleted: {_eq: false}}, $limit: Int, $offset: Int, $orderBy: [t_subject_order_by!] = [{name: asc}]) {
  subject_list: t_subject(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    id: subject_id
    subject_id
    name
    lastname
    phone
    email
    address
    info
    is_active
    post: doc(path: "post")
  }
}
    `;

/**
 * __useGetSubjectListQuery__
 *
 * To run a query within a React component, call `useGetSubjectListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubjectListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubjectListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetSubjectListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetSubjectListQuery, Types.GetSubjectListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSubjectListQuery, Types.GetSubjectListQueryVariables>(GetSubjectListDocument, options);
      }
export function useGetSubjectListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSubjectListQuery, Types.GetSubjectListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSubjectListQuery, Types.GetSubjectListQueryVariables>(GetSubjectListDocument, options);
        }
export type GetSubjectListQueryHookResult = ReturnType<typeof useGetSubjectListQuery>;
export type GetSubjectListLazyQueryHookResult = ReturnType<typeof useGetSubjectListLazyQuery>;
export type GetSubjectListQueryResult = Apollo.QueryResult<Types.GetSubjectListQuery, Types.GetSubjectListQueryVariables>;
export const GetSubjectByIdDocument = gql`
    query getSubjectById($subjectId: Int!) {
  subject: t_subject_by_pk(subject_id: $subjectId) {
    subject_id
    id: subject_id
    name
    lastname
    phone
    address
    email
    info
    type_id
    is_active
    organization_link: subject_links(
      where: {t_link_type: {brief: {_eq: "CONTACT_PERSON"}}, t_object_type: {brief: {_eq: "SUBJECT"}}}
    ) {
      link_type_id
      subject_link_id
      id: subject_link_id
      subject_id
      subject_object {
        name
        subject_id
      }
    }
    post: doc(path: "post")
    subject_type {
      brief
    }
  }
}
    `;

/**
 * __useGetSubjectByIdQuery__
 *
 * To run a query within a React component, call `useGetSubjectByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubjectByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubjectByIdQuery({
 *   variables: {
 *      subjectId: // value for 'subjectId'
 *   },
 * });
 */
export function useGetSubjectByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSubjectByIdQuery, Types.GetSubjectByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSubjectByIdQuery, Types.GetSubjectByIdQueryVariables>(GetSubjectByIdDocument, options);
      }
export function useGetSubjectByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSubjectByIdQuery, Types.GetSubjectByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSubjectByIdQuery, Types.GetSubjectByIdQueryVariables>(GetSubjectByIdDocument, options);
        }
export type GetSubjectByIdQueryHookResult = ReturnType<typeof useGetSubjectByIdQuery>;
export type GetSubjectByIdLazyQueryHookResult = ReturnType<typeof useGetSubjectByIdLazyQuery>;
export type GetSubjectByIdQueryResult = Apollo.QueryResult<Types.GetSubjectByIdQuery, Types.GetSubjectByIdQueryVariables>;
export const GetSubjectPaymentsByIdDocument = gql`
    query getSubjectPaymentsById($subjectId: Int!) {
  subject: t_subject_by_pk(subject_id: $subjectId) {
    subject_id
    id: subject_id
    name
    lastname
    phone
    address
    email
    info
    type_id
    is_active
    payments {
      date_invoice
      description
      guid_bds
      guid_invoice
      is_partial
      num_invoice
      partner_guid
      partner_name
      sum_bds
      sum_invoice
    }
  }
}
    `;

/**
 * __useGetSubjectPaymentsByIdQuery__
 *
 * To run a query within a React component, call `useGetSubjectPaymentsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubjectPaymentsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubjectPaymentsByIdQuery({
 *   variables: {
 *      subjectId: // value for 'subjectId'
 *   },
 * });
 */
export function useGetSubjectPaymentsByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSubjectPaymentsByIdQuery, Types.GetSubjectPaymentsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSubjectPaymentsByIdQuery, Types.GetSubjectPaymentsByIdQueryVariables>(GetSubjectPaymentsByIdDocument, options);
      }
export function useGetSubjectPaymentsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSubjectPaymentsByIdQuery, Types.GetSubjectPaymentsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSubjectPaymentsByIdQuery, Types.GetSubjectPaymentsByIdQueryVariables>(GetSubjectPaymentsByIdDocument, options);
        }
export type GetSubjectPaymentsByIdQueryHookResult = ReturnType<typeof useGetSubjectPaymentsByIdQuery>;
export type GetSubjectPaymentsByIdLazyQueryHookResult = ReturnType<typeof useGetSubjectPaymentsByIdLazyQuery>;
export type GetSubjectPaymentsByIdQueryResult = Apollo.QueryResult<Types.GetSubjectPaymentsByIdQuery, Types.GetSubjectPaymentsByIdQueryVariables>;
export const GetPriorityListDocument = gql`
    query getPriorityList($taskTypeBrief: String) {
  t_task_priority(where: {task_type: {brief: {_eq: $taskTypeBrief}}}) {
    id: task_priority_id
    task_priority_id
    task_type_id
    name
  }
}
    `;

/**
 * __useGetPriorityListQuery__
 *
 * To run a query within a React component, call `useGetPriorityListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPriorityListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPriorityListQuery({
 *   variables: {
 *      taskTypeBrief: // value for 'taskTypeBrief'
 *   },
 * });
 */
export function useGetPriorityListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetPriorityListQuery, Types.GetPriorityListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPriorityListQuery, Types.GetPriorityListQueryVariables>(GetPriorityListDocument, options);
      }
export function useGetPriorityListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPriorityListQuery, Types.GetPriorityListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPriorityListQuery, Types.GetPriorityListQueryVariables>(GetPriorityListDocument, options);
        }
export type GetPriorityListQueryHookResult = ReturnType<typeof useGetPriorityListQuery>;
export type GetPriorityListLazyQueryHookResult = ReturnType<typeof useGetPriorityListLazyQuery>;
export type GetPriorityListQueryResult = Apollo.QueryResult<Types.GetPriorityListQuery, Types.GetPriorityListQueryVariables>;
export const GetTaskStatusListDocument = gql`
    query getTaskStatusList($where: t_task_status_bool_exp) {
  task_status_list: t_task_status(where: $where) {
    id: task_status_id
    task_status_id
    name
    task_type_id
  }
}
    `;

/**
 * __useGetTaskStatusListQuery__
 *
 * To run a query within a React component, call `useGetTaskStatusListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskStatusListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskStatusListQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTaskStatusListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTaskStatusListQuery, Types.GetTaskStatusListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTaskStatusListQuery, Types.GetTaskStatusListQueryVariables>(GetTaskStatusListDocument, options);
      }
export function useGetTaskStatusListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTaskStatusListQuery, Types.GetTaskStatusListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTaskStatusListQuery, Types.GetTaskStatusListQueryVariables>(GetTaskStatusListDocument, options);
        }
export type GetTaskStatusListQueryHookResult = ReturnType<typeof useGetTaskStatusListQuery>;
export type GetTaskStatusListLazyQueryHookResult = ReturnType<typeof useGetTaskStatusListLazyQuery>;
export type GetTaskStatusListQueryResult = Apollo.QueryResult<Types.GetTaskStatusListQuery, Types.GetTaskStatusListQueryVariables>;
export const GetTaskTypeListDocument = gql`
    query getTaskTypeList($where: t_task_type_bool_exp) {
  task_type_list: t_task_type(where: $where) {
    brief
    name
    task_type_id
    id: task_type_id
  }
}
    `;

/**
 * __useGetTaskTypeListQuery__
 *
 * To run a query within a React component, call `useGetTaskTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskTypeListQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTaskTypeListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTaskTypeListQuery, Types.GetTaskTypeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTaskTypeListQuery, Types.GetTaskTypeListQueryVariables>(GetTaskTypeListDocument, options);
      }
export function useGetTaskTypeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTaskTypeListQuery, Types.GetTaskTypeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTaskTypeListQuery, Types.GetTaskTypeListQueryVariables>(GetTaskTypeListDocument, options);
        }
export type GetTaskTypeListQueryHookResult = ReturnType<typeof useGetTaskTypeListQuery>;
export type GetTaskTypeListLazyQueryHookResult = ReturnType<typeof useGetTaskTypeListLazyQuery>;
export type GetTaskTypeListQueryResult = Apollo.QueryResult<Types.GetTaskTypeListQuery, Types.GetTaskTypeListQueryVariables>;
export const GetTaskListDocument = gql`
    query getTaskList($type: String, $status: String, $limit: Int, $offset: Int, $id: bigint, $subjectId: Int, $ownerId: Int, $objectId: bigint, $objectTypeBrief: String, $orderBy: [t_task_order_by!] = [{task_id: desc}]) {
  t_task(
    where: {task_type: {brief: {_eq: $type}}, status: {name: {_eq: $status}}, task_id: {_eq: $id}, deleted: {_eq: false}, subject_id: {_eq: $subjectId}, owner_id: {_eq: $ownerId}, object_id: {_eq: $objectId}, object_type: {brief: {_eq: $objectTypeBrief}}}
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    ...taskInfoDefault
  }
}
    ${TaskInfoDefaultFragmentDoc}`;

/**
 * __useGetTaskListQuery__
 *
 * To run a query within a React component, call `useGetTaskListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskListQuery({
 *   variables: {
 *      type: // value for 'type'
 *      status: // value for 'status'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      id: // value for 'id'
 *      subjectId: // value for 'subjectId'
 *      ownerId: // value for 'ownerId'
 *      objectId: // value for 'objectId'
 *      objectTypeBrief: // value for 'objectTypeBrief'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetTaskListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTaskListQuery, Types.GetTaskListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTaskListQuery, Types.GetTaskListQueryVariables>(GetTaskListDocument, options);
      }
export function useGetTaskListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTaskListQuery, Types.GetTaskListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTaskListQuery, Types.GetTaskListQueryVariables>(GetTaskListDocument, options);
        }
export type GetTaskListQueryHookResult = ReturnType<typeof useGetTaskListQuery>;
export type GetTaskListLazyQueryHookResult = ReturnType<typeof useGetTaskListLazyQuery>;
export type GetTaskListQueryResult = Apollo.QueryResult<Types.GetTaskListQuery, Types.GetTaskListQueryVariables>;
export const GetTasksCountByOrderIdDocument = gql`
    query getTasksCountByOrderId($orderId: bigint, $taskTypeBrief: String, $objectTypeBrief: String) {
  t_task_aggregate(
    where: {object_id: {_eq: $orderId}, task_type: {brief: {_eq: $taskTypeBrief}}, object_type: {brief: {_eq: $objectTypeBrief}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetTasksCountByOrderIdQuery__
 *
 * To run a query within a React component, call `useGetTasksCountByOrderIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksCountByOrderIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksCountByOrderIdQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      taskTypeBrief: // value for 'taskTypeBrief'
 *      objectTypeBrief: // value for 'objectTypeBrief'
 *   },
 * });
 */
export function useGetTasksCountByOrderIdQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTasksCountByOrderIdQuery, Types.GetTasksCountByOrderIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTasksCountByOrderIdQuery, Types.GetTasksCountByOrderIdQueryVariables>(GetTasksCountByOrderIdDocument, options);
      }
export function useGetTasksCountByOrderIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTasksCountByOrderIdQuery, Types.GetTasksCountByOrderIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTasksCountByOrderIdQuery, Types.GetTasksCountByOrderIdQueryVariables>(GetTasksCountByOrderIdDocument, options);
        }
export type GetTasksCountByOrderIdQueryHookResult = ReturnType<typeof useGetTasksCountByOrderIdQuery>;
export type GetTasksCountByOrderIdLazyQueryHookResult = ReturnType<typeof useGetTasksCountByOrderIdLazyQuery>;
export type GetTasksCountByOrderIdQueryResult = Apollo.QueryResult<Types.GetTasksCountByOrderIdQuery, Types.GetTasksCountByOrderIdQueryVariables>;
export const GetTasksByOrderIdDocument = gql`
    query getTasksByOrderId($orderId: bigint, $taskTypeBrief: String, $objectTypeBrief: String, $offset: Int, $limit: Int) {
  t_task(
    where: {object_id: {_eq: $orderId}, task_type: {brief: {_eq: $taskTypeBrief}}, object_type: {brief: {_eq: $objectTypeBrief}}}
    limit: $limit
    offset: $offset
  ) {
    task_id
    id: task_id
    ins_date
    deleted
    deadline
    deadline_string
    date_start
    date_start_string
    manager_id
    document: doc(path: "document")
    subject {
      lastname
      name
    }
    description
    status_id
    status {
      name
    }
    selected_shipment_task {
      task_id
      status {
        name
      }
    }
    task_props {
      bigint_val
      datetime_val
      decimal_val
      int_val
      object_id
      prop_id
      prop_value_id
      text_val
      prop {
        brief
        name
      }
      prop_value {
        value
        brief
      }
    }
  }
}
    `;

/**
 * __useGetTasksByOrderIdQuery__
 *
 * To run a query within a React component, call `useGetTasksByOrderIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksByOrderIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksByOrderIdQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      taskTypeBrief: // value for 'taskTypeBrief'
 *      objectTypeBrief: // value for 'objectTypeBrief'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetTasksByOrderIdQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTasksByOrderIdQuery, Types.GetTasksByOrderIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTasksByOrderIdQuery, Types.GetTasksByOrderIdQueryVariables>(GetTasksByOrderIdDocument, options);
      }
export function useGetTasksByOrderIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTasksByOrderIdQuery, Types.GetTasksByOrderIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTasksByOrderIdQuery, Types.GetTasksByOrderIdQueryVariables>(GetTasksByOrderIdDocument, options);
        }
export type GetTasksByOrderIdQueryHookResult = ReturnType<typeof useGetTasksByOrderIdQuery>;
export type GetTasksByOrderIdLazyQueryHookResult = ReturnType<typeof useGetTasksByOrderIdLazyQuery>;
export type GetTasksByOrderIdQueryResult = Apollo.QueryResult<Types.GetTasksByOrderIdQuery, Types.GetTasksByOrderIdQueryVariables>;
export const AddTaskFuncDocument = gql`
    query addTaskFunc($params: json) {
  add_task_wrapper(args: {params: $params}) {
    task_id
  }
}
    `;

/**
 * __useAddTaskFuncQuery__
 *
 * To run a query within a React component, call `useAddTaskFuncQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddTaskFuncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddTaskFuncQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useAddTaskFuncQuery(baseOptions?: Apollo.QueryHookOptions<Types.AddTaskFuncQuery, Types.AddTaskFuncQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AddTaskFuncQuery, Types.AddTaskFuncQueryVariables>(AddTaskFuncDocument, options);
      }
export function useAddTaskFuncLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AddTaskFuncQuery, Types.AddTaskFuncQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AddTaskFuncQuery, Types.AddTaskFuncQueryVariables>(AddTaskFuncDocument, options);
        }
export type AddTaskFuncQueryHookResult = ReturnType<typeof useAddTaskFuncQuery>;
export type AddTaskFuncLazyQueryHookResult = ReturnType<typeof useAddTaskFuncLazyQuery>;
export type AddTaskFuncQueryResult = Apollo.QueryResult<Types.AddTaskFuncQuery, Types.AddTaskFuncQueryVariables>;
export const GetCountTaskDocument = gql`
    query getCountTask($type: String, $status: String) {
  t_task_aggregate(
    where: {task_type: {brief: {_eq: $type}}, status: {name: {_eq: $status}}, deleted: {_eq: false}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetCountTaskQuery__
 *
 * To run a query within a React component, call `useGetCountTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountTaskQuery({
 *   variables: {
 *      type: // value for 'type'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetCountTaskQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCountTaskQuery, Types.GetCountTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCountTaskQuery, Types.GetCountTaskQueryVariables>(GetCountTaskDocument, options);
      }
export function useGetCountTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCountTaskQuery, Types.GetCountTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCountTaskQuery, Types.GetCountTaskQueryVariables>(GetCountTaskDocument, options);
        }
export type GetCountTaskQueryHookResult = ReturnType<typeof useGetCountTaskQuery>;
export type GetCountTaskLazyQueryHookResult = ReturnType<typeof useGetCountTaskLazyQuery>;
export type GetCountTaskQueryResult = Apollo.QueryResult<Types.GetCountTaskQuery, Types.GetCountTaskQueryVariables>;
export const GetShipmentRequestListDocument = gql`
    query getShipmentRequestList($taskId: bigint, $limit: Int, $offset: Int) {
  t_task(
    where: {object_task_links: {task: {task_id: {_eq: $taskId}}, object_type: {brief: {_eq: "TASK"}}, link_type: {brief: {_eq: "SHIPMENT_REQUEST"}}}, deleted: {_eq: false}}
    limit: $limit
    offset: $offset
  ) {
    ...shipmentRequestInfo
  }
}
    ${ShipmentRequestInfoFragmentDoc}`;

/**
 * __useGetShipmentRequestListQuery__
 *
 * To run a query within a React component, call `useGetShipmentRequestListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentRequestListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShipmentRequestListQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetShipmentRequestListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetShipmentRequestListQuery, Types.GetShipmentRequestListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetShipmentRequestListQuery, Types.GetShipmentRequestListQueryVariables>(GetShipmentRequestListDocument, options);
      }
export function useGetShipmentRequestListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetShipmentRequestListQuery, Types.GetShipmentRequestListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetShipmentRequestListQuery, Types.GetShipmentRequestListQueryVariables>(GetShipmentRequestListDocument, options);
        }
export type GetShipmentRequestListQueryHookResult = ReturnType<typeof useGetShipmentRequestListQuery>;
export type GetShipmentRequestListLazyQueryHookResult = ReturnType<typeof useGetShipmentRequestListLazyQuery>;
export type GetShipmentRequestListQueryResult = Apollo.QueryResult<Types.GetShipmentRequestListQuery, Types.GetShipmentRequestListQueryVariables>;
export const GetCountShipmentRequestListDocument = gql`
    query getCountShipmentRequestList($taskId: bigint) {
  t_task_aggregate(
    where: {object_task_links: {task: {task_id: {_eq: $taskId}}, object_type: {brief: {_eq: "TASK"}}, link_type: {brief: {_eq: "SHIPMENT_REQUEST"}}}, deleted: {_eq: false}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetCountShipmentRequestListQuery__
 *
 * To run a query within a React component, call `useGetCountShipmentRequestListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountShipmentRequestListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountShipmentRequestListQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetCountShipmentRequestListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCountShipmentRequestListQuery, Types.GetCountShipmentRequestListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCountShipmentRequestListQuery, Types.GetCountShipmentRequestListQueryVariables>(GetCountShipmentRequestListDocument, options);
      }
export function useGetCountShipmentRequestListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCountShipmentRequestListQuery, Types.GetCountShipmentRequestListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCountShipmentRequestListQuery, Types.GetCountShipmentRequestListQueryVariables>(GetCountShipmentRequestListDocument, options);
        }
export type GetCountShipmentRequestListQueryHookResult = ReturnType<typeof useGetCountShipmentRequestListQuery>;
export type GetCountShipmentRequestListLazyQueryHookResult = ReturnType<typeof useGetCountShipmentRequestListLazyQuery>;
export type GetCountShipmentRequestListQueryResult = Apollo.QueryResult<Types.GetCountShipmentRequestListQuery, Types.GetCountShipmentRequestListQueryVariables>;
export const GetRequestListByProviderDocument = gql`
    query getRequestListByProvider($subjectId: Int, $taskId: Int) {
  get_request_by_provider(
    args: {subid: $subjectId, tskid: $taskId}
    where: {deleted: {_eq: false}}
  ) {
    ...shipmentRequestInfo
  }
}
    ${ShipmentRequestInfoFragmentDoc}`;

/**
 * __useGetRequestListByProviderQuery__
 *
 * To run a query within a React component, call `useGetRequestListByProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestListByProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestListByProviderQuery({
 *   variables: {
 *      subjectId: // value for 'subjectId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetRequestListByProviderQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetRequestListByProviderQuery, Types.GetRequestListByProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetRequestListByProviderQuery, Types.GetRequestListByProviderQueryVariables>(GetRequestListByProviderDocument, options);
      }
export function useGetRequestListByProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetRequestListByProviderQuery, Types.GetRequestListByProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetRequestListByProviderQuery, Types.GetRequestListByProviderQueryVariables>(GetRequestListByProviderDocument, options);
        }
export type GetRequestListByProviderQueryHookResult = ReturnType<typeof useGetRequestListByProviderQuery>;
export type GetRequestListByProviderLazyQueryHookResult = ReturnType<typeof useGetRequestListByProviderLazyQuery>;
export type GetRequestListByProviderQueryResult = Apollo.QueryResult<Types.GetRequestListByProviderQuery, Types.GetRequestListByProviderQueryVariables>;
export const GetCurrentTaskDocument = gql`
    query getCurrentTask($taskId: bigint) {
  t_task(where: {task_id: {_eq: $taskId}, deleted: {_eq: false}}) {
    ...currentTaskInfo
  }
}
    ${CurrentTaskInfoFragmentDoc}`;

/**
 * __useGetCurrentTaskQuery__
 *
 * To run a query within a React component, call `useGetCurrentTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentTaskQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetCurrentTaskQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCurrentTaskQuery, Types.GetCurrentTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCurrentTaskQuery, Types.GetCurrentTaskQueryVariables>(GetCurrentTaskDocument, options);
      }
export function useGetCurrentTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCurrentTaskQuery, Types.GetCurrentTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCurrentTaskQuery, Types.GetCurrentTaskQueryVariables>(GetCurrentTaskDocument, options);
        }
export type GetCurrentTaskQueryHookResult = ReturnType<typeof useGetCurrentTaskQuery>;
export type GetCurrentTaskLazyQueryHookResult = ReturnType<typeof useGetCurrentTaskLazyQuery>;
export type GetCurrentTaskQueryResult = Apollo.QueryResult<Types.GetCurrentTaskQuery, Types.GetCurrentTaskQueryVariables>;
export const GetTaskForTaskListPageDocument = gql`
    query getTaskForTaskListPage($where: t_task_bool_exp, $offset: Int, $limit: Int, $orderBy: [t_task_order_by!] = [{task_id: desc}]) {
  t_task(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    ...taskForTaskListPageInfo
  }
}
    ${TaskForTaskListPageInfoFragmentDoc}`;

/**
 * __useGetTaskForTaskListPageQuery__
 *
 * To run a query within a React component, call `useGetTaskForTaskListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskForTaskListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskForTaskListPageQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetTaskForTaskListPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTaskForTaskListPageQuery, Types.GetTaskForTaskListPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTaskForTaskListPageQuery, Types.GetTaskForTaskListPageQueryVariables>(GetTaskForTaskListPageDocument, options);
      }
export function useGetTaskForTaskListPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTaskForTaskListPageQuery, Types.GetTaskForTaskListPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTaskForTaskListPageQuery, Types.GetTaskForTaskListPageQueryVariables>(GetTaskForTaskListPageDocument, options);
        }
export type GetTaskForTaskListPageQueryHookResult = ReturnType<typeof useGetTaskForTaskListPageQuery>;
export type GetTaskForTaskListPageLazyQueryHookResult = ReturnType<typeof useGetTaskForTaskListPageLazyQuery>;
export type GetTaskForTaskListPageQueryResult = Apollo.QueryResult<Types.GetTaskForTaskListPageQuery, Types.GetTaskForTaskListPageQueryVariables>;
export const GetCheckListDocument = gql`
    query getCheckList($parentId: bigint, $orderBy: [t_task_order_by!] = [{task_id: asc}]) {
  t_task(
    where: {parent_id: {_eq: $parentId}, deleted: {_eq: false}, task_type: {brief: {_eq: "CHECK_LIST"}}}
    order_by: $orderBy
  ) {
    ...checkListTaskInfo
  }
}
    ${CheckListTaskInfoFragmentDoc}`;

/**
 * __useGetCheckListQuery__
 *
 * To run a query within a React component, call `useGetCheckListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckListQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetCheckListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCheckListQuery, Types.GetCheckListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCheckListQuery, Types.GetCheckListQueryVariables>(GetCheckListDocument, options);
      }
export function useGetCheckListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCheckListQuery, Types.GetCheckListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCheckListQuery, Types.GetCheckListQueryVariables>(GetCheckListDocument, options);
        }
export type GetCheckListQueryHookResult = ReturnType<typeof useGetCheckListQuery>;
export type GetCheckListLazyQueryHookResult = ReturnType<typeof useGetCheckListLazyQuery>;
export type GetCheckListQueryResult = Apollo.QueryResult<Types.GetCheckListQuery, Types.GetCheckListQueryVariables>;
export const GetTaskListDashboardDocument = gql`
    query getTaskListDashboard($where: t_task_bool_exp, $orderBy: [t_task_order_by!] = [{task_id: desc}], $limit: Int, $offset: Int) {
  t_task(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
    ...taskForTaskListPageInfo
  }
}
    ${TaskForTaskListPageInfoFragmentDoc}`;

/**
 * __useGetTaskListDashboardQuery__
 *
 * To run a query within a React component, call `useGetTaskListDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskListDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskListDashboardQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTaskListDashboardQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTaskListDashboardQuery, Types.GetTaskListDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTaskListDashboardQuery, Types.GetTaskListDashboardQueryVariables>(GetTaskListDashboardDocument, options);
      }
export function useGetTaskListDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTaskListDashboardQuery, Types.GetTaskListDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTaskListDashboardQuery, Types.GetTaskListDashboardQueryVariables>(GetTaskListDashboardDocument, options);
        }
export type GetTaskListDashboardQueryHookResult = ReturnType<typeof useGetTaskListDashboardQuery>;
export type GetTaskListDashboardLazyQueryHookResult = ReturnType<typeof useGetTaskListDashboardLazyQuery>;
export type GetTaskListDashboardQueryResult = Apollo.QueryResult<Types.GetTaskListDashboardQuery, Types.GetTaskListDashboardQueryVariables>;
export const GetTaskCountDocument = gql`
    query getTaskCount($where: t_task_bool_exp) {
  t_task_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetTaskCountQuery__
 *
 * To run a query within a React component, call `useGetTaskCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTaskCountQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTaskCountQuery, Types.GetTaskCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTaskCountQuery, Types.GetTaskCountQueryVariables>(GetTaskCountDocument, options);
      }
export function useGetTaskCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTaskCountQuery, Types.GetTaskCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTaskCountQuery, Types.GetTaskCountQueryVariables>(GetTaskCountDocument, options);
        }
export type GetTaskCountQueryHookResult = ReturnType<typeof useGetTaskCountQuery>;
export type GetTaskCountLazyQueryHookResult = ReturnType<typeof useGetTaskCountLazyQuery>;
export type GetTaskCountQueryResult = Apollo.QueryResult<Types.GetTaskCountQuery, Types.GetTaskCountQueryVariables>;
export const GetTaskListOrganizationDocument = gql`
    query getTaskListOrganization($where: t_task_bool_exp, $orderBy: [t_task_order_by!] = [{task_id: asc}], $limit: Int, $offset: Int) {
  t_task(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
    ...taskInfoDefault
  }
}
    ${TaskInfoDefaultFragmentDoc}`;

/**
 * __useGetTaskListOrganizationQuery__
 *
 * To run a query within a React component, call `useGetTaskListOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskListOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskListOrganizationQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTaskListOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTaskListOrganizationQuery, Types.GetTaskListOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTaskListOrganizationQuery, Types.GetTaskListOrganizationQueryVariables>(GetTaskListOrganizationDocument, options);
      }
export function useGetTaskListOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTaskListOrganizationQuery, Types.GetTaskListOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTaskListOrganizationQuery, Types.GetTaskListOrganizationQueryVariables>(GetTaskListOrganizationDocument, options);
        }
export type GetTaskListOrganizationQueryHookResult = ReturnType<typeof useGetTaskListOrganizationQuery>;
export type GetTaskListOrganizationLazyQueryHookResult = ReturnType<typeof useGetTaskListOrganizationLazyQuery>;
export type GetTaskListOrganizationQueryResult = Apollo.QueryResult<Types.GetTaskListOrganizationQuery, Types.GetTaskListOrganizationQueryVariables>;
export const GetTaskListCalendarDocument = gql`
    query getTaskListCalendar($where: t_task_bool_exp, $orderBy: [t_task_order_by!] = [{task_id: asc}], $limit: Int, $offset: Int) {
  t_task(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
    ...calendarTaskInfo
  }
}
    ${CalendarTaskInfoFragmentDoc}`;

/**
 * __useGetTaskListCalendarQuery__
 *
 * To run a query within a React component, call `useGetTaskListCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskListCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskListCalendarQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTaskListCalendarQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTaskListCalendarQuery, Types.GetTaskListCalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTaskListCalendarQuery, Types.GetTaskListCalendarQueryVariables>(GetTaskListCalendarDocument, options);
      }
export function useGetTaskListCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTaskListCalendarQuery, Types.GetTaskListCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTaskListCalendarQuery, Types.GetTaskListCalendarQueryVariables>(GetTaskListCalendarDocument, options);
        }
export type GetTaskListCalendarQueryHookResult = ReturnType<typeof useGetTaskListCalendarQuery>;
export type GetTaskListCalendarLazyQueryHookResult = ReturnType<typeof useGetTaskListCalendarLazyQuery>;
export type GetTaskListCalendarQueryResult = Apollo.QueryResult<Types.GetTaskListCalendarQuery, Types.GetTaskListCalendarQueryVariables>;
export const GetTaskListPartnerDocument = gql`
    query getTaskListPartner($where: t_task_bool_exp, $orderBy: [t_task_order_by!] = [{task_id: asc}], $limit: Int, $offset: Int) {
  task_list: t_task(
    where: $where
    order_by: $orderBy
    limit: $limit
    offset: $offset
  ) {
    ...taskInfoDefault
  }
}
    ${TaskInfoDefaultFragmentDoc}`;

/**
 * __useGetTaskListPartnerQuery__
 *
 * To run a query within a React component, call `useGetTaskListPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskListPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskListPartnerQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTaskListPartnerQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTaskListPartnerQuery, Types.GetTaskListPartnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTaskListPartnerQuery, Types.GetTaskListPartnerQueryVariables>(GetTaskListPartnerDocument, options);
      }
export function useGetTaskListPartnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTaskListPartnerQuery, Types.GetTaskListPartnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTaskListPartnerQuery, Types.GetTaskListPartnerQueryVariables>(GetTaskListPartnerDocument, options);
        }
export type GetTaskListPartnerQueryHookResult = ReturnType<typeof useGetTaskListPartnerQuery>;
export type GetTaskListPartnerLazyQueryHookResult = ReturnType<typeof useGetTaskListPartnerLazyQuery>;
export type GetTaskListPartnerQueryResult = Apollo.QueryResult<Types.GetTaskListPartnerQuery, Types.GetTaskListPartnerQueryVariables>;
export const GetTaskListContactDocument = gql`
    query getTaskListContact($where: t_task_bool_exp, $orderBy: [t_task_order_by!] = [{task_id: asc}], $limit: Int, $offset: Int) {
  task_list: t_task(
    where: $where
    order_by: $orderBy
    limit: $limit
    offset: $offset
  ) {
    ...taskInfoDefault
  }
}
    ${TaskInfoDefaultFragmentDoc}`;

/**
 * __useGetTaskListContactQuery__
 *
 * To run a query within a React component, call `useGetTaskListContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskListContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskListContactQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTaskListContactQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTaskListContactQuery, Types.GetTaskListContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTaskListContactQuery, Types.GetTaskListContactQueryVariables>(GetTaskListContactDocument, options);
      }
export function useGetTaskListContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTaskListContactQuery, Types.GetTaskListContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTaskListContactQuery, Types.GetTaskListContactQueryVariables>(GetTaskListContactDocument, options);
        }
export type GetTaskListContactQueryHookResult = ReturnType<typeof useGetTaskListContactQuery>;
export type GetTaskListContactLazyQueryHookResult = ReturnType<typeof useGetTaskListContactLazyQuery>;
export type GetTaskListContactQueryResult = Apollo.QueryResult<Types.GetTaskListContactQuery, Types.GetTaskListContactQueryVariables>;
export const GetTaskOrderDocument = gql`
    query getTaskOrder($orderId: Int) {
  t_order(where: {order_id: {_eq: $orderId}, deleted: {_eq: false}}) {
    customer_id
    customer {
      lastname
      email
      name
      phone
      subject_id
      id: subject_id
      surname
      dolznost: props(where: {prop: {brief: {_eq: "DOLZHNOST"}}}) {
        text_val
        prop_id
        prop {
          name
          brief
        }
        prop_value {
          brief
          value
          prop_value_id
        }
      }
    }
    firm {
      email
      lastname
      name
      subject_id
      phone
      contact_list: subject_links(where: {subject_object: {deleted: {_eq: false}}}) {
        subject_object {
          email
          lastname
          name
          phone
          subject_id
          id: subject_id
        }
      }
    }
    status {
      name
      order_status_id
    }
    type {
      brief
      name
    }
    format_ins_date
    firm_id
    manager_id
    manager {
      email
      lastname
      name
      phone
      subject_id
      surname
    }
  }
}
    `;

/**
 * __useGetTaskOrderQuery__
 *
 * To run a query within a React component, call `useGetTaskOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetTaskOrderQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTaskOrderQuery, Types.GetTaskOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTaskOrderQuery, Types.GetTaskOrderQueryVariables>(GetTaskOrderDocument, options);
      }
export function useGetTaskOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTaskOrderQuery, Types.GetTaskOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTaskOrderQuery, Types.GetTaskOrderQueryVariables>(GetTaskOrderDocument, options);
        }
export type GetTaskOrderQueryHookResult = ReturnType<typeof useGetTaskOrderQuery>;
export type GetTaskOrderLazyQueryHookResult = ReturnType<typeof useGetTaskOrderLazyQuery>;
export type GetTaskOrderQueryResult = Apollo.QueryResult<Types.GetTaskOrderQuery, Types.GetTaskOrderQueryVariables>;
export const GetTaskObjectDocument = gql`
    query getTaskObject($taskId: bigint!) {
  task: t_task_by_pk(task_id: $taskId) {
    id: task_id
    task_id
    name
    description
  }
}
    `;

/**
 * __useGetTaskObjectQuery__
 *
 * To run a query within a React component, call `useGetTaskObjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskObjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskObjectQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskObjectQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTaskObjectQuery, Types.GetTaskObjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTaskObjectQuery, Types.GetTaskObjectQueryVariables>(GetTaskObjectDocument, options);
      }
export function useGetTaskObjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTaskObjectQuery, Types.GetTaskObjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTaskObjectQuery, Types.GetTaskObjectQueryVariables>(GetTaskObjectDocument, options);
        }
export type GetTaskObjectQueryHookResult = ReturnType<typeof useGetTaskObjectQuery>;
export type GetTaskObjectLazyQueryHookResult = ReturnType<typeof useGetTaskObjectLazyQuery>;
export type GetTaskObjectQueryResult = Apollo.QueryResult<Types.GetTaskObjectQuery, Types.GetTaskObjectQueryVariables>;
export const GetUserDocument = gql`
    query getUser($where: t_user_bool_exp) {
  user: t_user(where: $where, limit: 1) {
    ...defaultUserInfo
  }
}
    ${DefaultUserInfoFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetUserQuery, Types.GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserQuery, Types.GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserQuery, Types.GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserQuery, Types.GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<Types.GetUserQuery, Types.GetUserQueryVariables>;
export const AuthUserDocument = gql`
    query authUser($args: auth_user_args!, $where: t_user_bool_exp) {
  user: auth_user(args: $args, limit: 1, where: $where) {
    ...defaultUserInfo
  }
}
    ${DefaultUserInfoFragmentDoc}`;

/**
 * __useAuthUserQuery__
 *
 * To run a query within a React component, call `useAuthUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthUserQuery({
 *   variables: {
 *      args: // value for 'args'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAuthUserQuery(baseOptions: Apollo.QueryHookOptions<Types.AuthUserQuery, Types.AuthUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AuthUserQuery, Types.AuthUserQueryVariables>(AuthUserDocument, options);
      }
export function useAuthUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AuthUserQuery, Types.AuthUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AuthUserQuery, Types.AuthUserQueryVariables>(AuthUserDocument, options);
        }
export type AuthUserQueryHookResult = ReturnType<typeof useAuthUserQuery>;
export type AuthUserLazyQueryHookResult = ReturnType<typeof useAuthUserLazyQuery>;
export type AuthUserQueryResult = Apollo.QueryResult<Types.AuthUserQuery, Types.AuthUserQueryVariables>;
export const GetCommentDocument = gql`
    subscription getComment($where: t_comment_bool_exp, $orderBy: [t_comment_order_by!] = [{ins_date: desc}], $limit: Int, $offset: Int) {
  t_comment(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
    comment_id
    id: comment_id
    ins_date_string
    ins_time_string
    upd_date_string
    upd_time_string
    upd_user
    object_id
    object_type_id
    text
    object_subject {
      id: subject_id
      name
      lastname
      avatar
    }
    subject {
      name
      lastname
      id: subject_id
      avatar
    }
    files: file_links_object(
      where: {object_type: {brief: {_eq: "COMMENT"}}, file: {file_type: {_eq: "File"}, deleted: {_eq: false}}}
    ) {
      id: file_link_id
      file_id
      file {
        file_id
        id: file_id
        name: file_name
        path: file_path
        type: file_type
      }
    }
  }
}
    `;

    // object_task {
    //   id: task_id
    //   name
    //   description
    //   deleted
    //   object_id
    //   object_subject {
    //     id: subject_id
    //     name
    //     lastname
    //   }
    // }
/**
 * __useGetCommentSubscription__
 *
 * To run a query within a React component, call `useGetCommentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetCommentSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Types.GetCommentSubscription, Types.GetCommentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.GetCommentSubscription, Types.GetCommentSubscriptionVariables>(GetCommentDocument, options);
      }
export type GetCommentSubscriptionHookResult = ReturnType<typeof useGetCommentSubscription>;
export type GetCommentSubscriptionResult = Apollo.SubscriptionResult<Types.GetCommentSubscription>;
export const GetMessageListDocument = gql`
    subscription getMessageList($where: v_message_bool_exp, $orderBy: [v_message_order_by!] = [{ins_date: desc}], $limit: Int, $offset: Int) {
  messages: v_message(
    where: $where
    order_by: $orderBy
    limit: $limit
    offset: $offset
  ) {
    body
    doc
    hold
    ins_date_string
    ins_time_string
    message_id
    id: message_id
    msg_type
    task: doc(path: "task")
    link: doc(path: "link")
    url: doc(path: "url")
    external_url: doc(path: "external_url")
    comment: doc(path: "comment")
    task_update: doc(path: "task_update")
    subject_link: doc(path: "subject_link")
    recipient {
      lastname
      name
      subject_id
      id: subject_id
    }
    see
    recipient_id
    sent
    title
    sender
  }
}
    `;

/**
 * __useGetMessageListSubscription__
 *
 * To run a query within a React component, call `useGetMessageListSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageListSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageListSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetMessageListSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Types.GetMessageListSubscription, Types.GetMessageListSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.GetMessageListSubscription, Types.GetMessageListSubscriptionVariables>(GetMessageListDocument, options);
      }
export type GetMessageListSubscriptionHookResult = ReturnType<typeof useGetMessageListSubscription>;
export type GetMessageListSubscriptionResult = Apollo.SubscriptionResult<Types.GetMessageListSubscription>;
