export type Maybe<T> = T | null;

export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  json: any;
  jsonb: any;
  numeric: any;
  smallint: any;
  time: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};


export interface TUserProps {
  __typename?: 't_user';
  user_id: number;
  email?: Maybe<string>;
  is_admin: boolean;
  surname?: Maybe<string>;
  login: string;
  name?: Maybe<string>;
  phone?: Maybe<string>;
  subject_id?: Maybe<any>;
  id: number;
  subject?: Maybe<{
    __typename?: 't_subject';
    email?: Maybe<string>;
    address?: Maybe<string>;
    lastname?: Maybe<string>;
    ins_date?: Maybe<any>;
    inn?: Maybe<string>;
    ins_date_format?: Maybe<string>;
    name?: Maybe<string>;
    phone?: Maybe<string>;
    subject_id: number;
    surname?: Maybe<string>;
    type_id?: Maybe<number>;
    avatar?: Maybe<string>;
    deleted?: Maybe<boolean>;
    id: number;
    subject_type?: Maybe<{
      __typename?: 't_subject_type';
      brief?: Maybe<string>;
      name?: Maybe<string>;
      subject_type_id: number;
    }>;
  }>;
}

export interface TSubjectProps {
  __typename?: 't_subject';
  subject_id: number;
  name?: Maybe<string>;
  lastname?: Maybe<string>;
  id: number;
}

export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

export interface TDocumentProps {
  t_order?: any;
  partner_guid?: Maybe<string> | undefined;
  partner?: {
    subject_id: number
  }
  order_id?: number;
  __typename?: 't_doc_imports';
  amount?: Maybe<string>;
  bill_required?: Maybe<boolean>;
  date?: Maybe<string>;
  doc_number?: Maybe<string>;
  ftran_id?: Maybe<any>;
  maket?: Maybe<string>;
  manager?: Maybe<string>;
  manager_id?: Maybe<any>;
  order_date?: Maybe<string>;
  pp_number?: Maybe<string>;
  pp_date?: Maybe<string>;
  product?: Maybe<string>;
  status?: Maybe<string>;
  query_guid: string;
  tirazh?: Maybe<string>;
  is_hold?: Maybe<boolean>;
  id: number;
  partner_id?: Maybe<number>;
  manager_comment?: Maybe<string>;
  buh_comment?: Maybe<string>;
  price?: Maybe<string>;
  key: number;
  owner?: Maybe<{
    __typename?: 't_subject';
    name?: Maybe<string>;
    lastname?: Maybe<string>;
  }>;
  ftran?: Maybe<{
    __typename?: 't_ftran';
    t_doc?: Maybe<{
      __typename?: 't_doc';
      doc_id: any;
      number?: Maybe<string>;
      oper_date?: Maybe<any>;
    }>;
    t_order?: Maybe<{ __typename?: 't_order'; order_id: number }>;
  }>;
}

export interface TOrderProps {
  __typename?: 't_order';
  last_1c_order_date?: Maybe<any>;
  archived?: Maybe<boolean>;
  marzh_order_fakt?: Maybe<any>;
  order_expenses_fakt?: Maybe<any>;
  marzh_order_fakt_perc?: Maybe<any>;
  invoice_date?: Maybe<any>;
  invoice_number?: Maybe<string>;
  bill_number?: Maybe<string>;
  bill_date?: Maybe<any>;
  order_id: number;
  customer_id?: Maybe<number>;
  format_ins_date?: Maybe<string>;
  description?: Maybe<string>;
  order_sum?: Maybe<any>;
  status_id?: Maybe<number>;
  id: number;
  deal_owner?: Maybe<{
    __typename?: 't_subject';
    name?: Maybe<string>;
    lastname?: Maybe<string>;
  }>;
  customer?: Maybe<{
    __typename?: 't_subject';
    name?: Maybe<string>;
    lastname?: Maybe<string>;
    subject_id: number;
  }>;
  orderline: Array<{
    __typename?: 't_orderline';
    orderline_id: number;
    id: number;
  }>;
  status?: Maybe<{
    __typename?: 't_order_status';
    name?: Maybe<string>;
  }>;
  order_quarter?: Maybe<any>,
  manager?: Maybe<{
    __typename?: 't_subject';
    subject_links: Array<{
      __typename?: 't_subject_link';
      subject_object?: Maybe<{
        __typename?: 't_subject';
        avatar?: Maybe<string>;
        lastname?: Maybe<string>;
        name?: Maybe<string>;
      }>;
    }>;
  }>;
  priority: Array<{
    __typename?: 't_order_prop';
    order_prop_id: any;
    prop_id?: Maybe<number>;
    prop_value_id?: Maybe<number>;
    id: any;
    prop?: Maybe<{ __typename?: 't_prop'; name?: Maybe<string> }>;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      value?: Maybe<string>;
    }>;
  }>;
}

export interface TCurrentOrderProps {
  __typename?: 't_order';
  archived?: Maybe<boolean>;
  deal_owner_id?: Maybe<any>;
  invoice_date?: Maybe<any>;
  invoice_number?: Maybe<string>;
  bill_number?: Maybe<string>;
  bill_date?: Maybe<any>;
  marzh_order_fakt?: Maybe<any>;
  order_expenses_fakt?: Maybe<any>;
  marzh_order_fakt_perc?: Maybe<any>;
  order_id: number;
  number?: Maybe<string>;
  manager_id?: Maybe<number>;
  comment?: Maybe<string>;
  description?: Maybe<string>;
  format_ins_date?: Maybe<string>;
  order_sum?: Maybe<any>;
  purchase_amount?: Maybe<any>;
  cost_amount_order?: Maybe<any>;
  marzh?: Maybe<any>;
  marzh_percent?: Maybe<any>;
  status_id?: Maybe<number>;
  order_type_id: number;
  order_quarter?: Maybe<any>;
  customer_id?: Maybe<number>;
  firm_id?: Maybe<any>;
  id: number;
  deal_owner?: Maybe<{
    __typename?: 't_subject';
    name?: Maybe<string>;
    lastname?: Maybe<string>;
  }>;
  status?: Maybe<{
    __typename?: 't_order_status';
    name?: Maybe<string>;
    order_status_id: number;
    id: number;
  }>;
  order_type: {
    __typename?: 't_order_type';
    brief: string;
    order_type_id: number;
    name: string;
    id: number;
  };
  manager?: Maybe<{
    __typename?: 't_subject';
    name?: Maybe<string>;
    lastname?: Maybe<string>;
    surname?: Maybe<string>;
  }>;
  customer?: Maybe<{
    __typename?: 't_subject';
    subject_id: number;
    lastname?: Maybe<string>;
    name?: Maybe<string>;
    phone?: Maybe<string>;
    email?: Maybe<string>;
    id: number;
    manager: Array<{
      __typename?: 't_subject_link';
      id: any;
      subject?: Maybe<{
        __typename?: 't_subject';
        subject_id: number;
        lastname?: Maybe<string>;
        name?: Maybe<string>;
        avatar?: Maybe<string>;
        id: number;
      }>;
    }>;
  }>;
  firm?: Maybe<{
    __typename?: 't_subject';
    subject_id: number;
    lastname?: Maybe<string>;
    name?: Maybe<string>;
    id: number;
  }>;
  orderline: Array<{ __typename?: 't_orderline'; amount?: Maybe<any> }>;
  orderline_aggregate: {
    __typename?: 't_orderline_aggregate';
    aggregate?: Maybe<{
      __typename?: 't_orderline_aggregate_fields';
      sum?: Maybe<{
        __typename?: 't_orderline_sum_fields';
        purchase_price?: Maybe<any>;
        fixed_cost?: Maybe<any>;
      }>;
    }>;
  };
  contact_list: Array<{
    __typename?: 't_subject_link';
    subject_link_id: any;
    subject_id?: Maybe<number>;
    id: any;
    subject?: Maybe<{
      __typename?: 't_subject';
      name?: Maybe<string>;
      lastname?: Maybe<string>;
      phone?: Maybe<string>;
      email?: Maybe<string>;
      id: number;
      post?: Maybe<any>;
    }>;
  }>;
}
export interface TDocProps {
  __typename?: 't_doc',
  amount?: Maybe<any>,
  description?: Maybe<string>,
  doc_id: any,
  ins_date?: Maybe<any>,
  ins_user?: Maybe<number>,
  ddmmyyyy?: Maybe<string>,
  can_edit_doc1? : Maybe<boolean>,
  oper_date?: Maybe<any>,
  number?: Maybe<string>,
  expenses?: Maybe<any>,
  id: any,
  date?: Maybe<any>,
  ftrans: Array<{
    __typename?: 't_ftran',
    amount?: Maybe<any>,
    description?: Maybe<string>,
    order?: Maybe<{
      __typename?: 't_order',
      order_id: number,
      ins_date?: Maybe<string>,
      customer?: Maybe<{
        __typename?: 't_subject',
        name?: Maybe<string>
      }>
    }>
  }>,
  manager?: Maybe<{
    __typename?: 't_subject',
    email?: Maybe<string>,
    name?: Maybe<string>,
    lastname?: Maybe<string>,
    full_name?: Maybe<string>,
    subject_id: number,
    surname?: Maybe<string>,
    id: number
  }>,
  agent?: Maybe<{
    __typename?: 't_subject',
    email?: Maybe<string>,
    full_name?: Maybe<string>,
    lastname?: Maybe<string>,
    name?: Maybe<string>,
    phone?: Maybe<string>,
    subject_id: number,
    surname?: Maybe<string>,
    id: number
  }>,
  partner?: Maybe<{
    __typename?: 't_subject',
    email?: Maybe<string>,
    full_name?: Maybe<string>,
    lastname?: Maybe<string>,
    name?: Maybe<string>,
    phone?: Maybe<string>,
    subject_id: number,
    surname?: Maybe<string>,
    id: number
  }>,
  t_doc_type: {
    __typename?: 't_doc_type',
    brief?: Maybe<string>,
    description?: Maybe<string>,
    doc_type_id: number,
    id: number
  },
  exp?: Maybe<{
    __typename?: 'v_doc_expenses',
    doc_rest_expenses?: Maybe<any>
  }>
}

export interface TDocReturnedProps {
  __typename?: 't_doc';
  amount?: Maybe<any>;
  ins_date?: Maybe<any>;
  manager_id?: Maybe<any>;
  number?: Maybe<string>;
  oper_date?: Maybe<any>;
  partner_id?: Maybe<any>;
  id: any;
}

export interface TDocDrawerTableProps {
  doc_id: any;
  amount?: Maybe<any>;
  number?: Maybe<string>;
  oper_date?: Maybe<any>;
  description?: Maybe<string>;
}
export interface TEmployeeSubject {
  __typename?: 't_subject';
  subject_id: number;
  name?: Maybe<string>;
  lastname?: Maybe<string>;
  phone?: Maybe<string>;
  email?: Maybe<string>;
  is_active?: Maybe<boolean>;
  avatar?: Maybe<string>;
  id: number;
  branch?: Maybe<string>;
  sales_areas: Array<{
    __typename?: 't_sales_area_link';
    object_id?: Maybe<number>;
    sales_area?: Maybe<{
      __typename?: 't_sales_area';
      sales_area_id: number;
    }>;
  }>;
  city: Array<{
    __typename?: 't_subject_prop';
    prop_value_id?: Maybe<number>;
    prop_id?: Maybe<number>;
    subject_prop_id: any;
    id: any;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      brief?: Maybe<string>;
      value?: Maybe<string>;
      id: number;
    }>;
    prop?: Maybe<{
      __typename?: 't_prop';
      brief: string;
      name?: Maybe<string>;
      id: number;
    }>;
  }>;
  dolznost: Array<{
    __typename?: 't_subject_prop';
    prop_value_id?: Maybe<number>;
    prop_id?: Maybe<number>;
    subject_prop_id: any;
    id: any;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      brief?: Maybe<string>;
      value?: Maybe<string>;
      id: number;
    }>;
    prop?: Maybe<{
      __typename?: 't_prop';
      brief: string;
      name?: Maybe<string>;
      id: number;
    }>;
  }>;
}

export interface TEmployeeCurrentSubject {
  __typename?: 't_subject';
  is_active?: Maybe<boolean>;
  subject_id: number;
  name?: Maybe<string>;
  lastname?: Maybe<string>;
  address?: Maybe<string>;
  email?: Maybe<string>;
  phone?: Maybe<string>;
  avatar?: Maybe<string>;
  id: number;
  branch?: Maybe<{
    __typename?: 't_sales_area_link';
    id?: Maybe<number>;
    sales_area?: Maybe<{
      __typename?: 't_sales_area';
      name?: Maybe<string>;
    }>;
  }>;
}

export interface TTaskProps {
  __typename?: 't_task';
  last_comment?: Maybe<any>;
  task_id: any;
  ins_date?: Maybe<any>;
  deleted?: Maybe<boolean>;
  manager_id?: Maybe<number>;
  name?: Maybe<string>;
  description?: Maybe<string>;
  status_id?: Maybe<number>;
  type_id?: Maybe<number>;
  date_start_string?: Maybe<string>;
  ins_date_string?: Maybe<string>;
  ins_time_string?: Maybe<string>;
  percent_completed_check_list?: Maybe<any>;
  subject_id?: Maybe<number>;
  object_id?: Maybe<any>;
  id: any;
  deadline?: Maybe<string>;
  task_type?: Maybe<{
    __typename?: 't_task_type';
    task_type_id: number;
    name?: Maybe<string>;
    brief: string;
    id: number;
  }>;
  task_priority?: Maybe<{
    __typename?: 't_task_priority';
    name: string;
  }>;
  task_props: Array<{
    __typename?: 't_task_prop';
    task_prop_id: any;
    prop_id?: Maybe<number>;
    prop_value_id?: Maybe<number>;
    text_val?: Maybe<string>;
    bigint_val?: Maybe<any>;
    datetime_val?: Maybe<any>;
    int_val?: Maybe<number>;
    id: any;
    prop?: Maybe<{
      __typename?: 't_prop';
      brief: string;
      name?: Maybe<string>;
    }>;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      value?: Maybe<string>;
      brief?: Maybe<string>;
    }>;
  }>;
  tip_zadachi: Array<{
    __typename?: 't_task_prop';
    prop_value_id?: Maybe<number>;
    id: any;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      value?: Maybe<string>;
      brief?: Maybe<string>;
      id: number;
    }>;
  }>;
  status?: Maybe<{
    __typename?: 't_task_status';
    name: string;
    task_status_id: number;
    id: number;
  }>;
  subject?: Maybe<{
    __typename?: 't_subject';
    subject_id: number;
    full_name?: Maybe<string>;
    name?: Maybe<string>;
    surname?: Maybe<string>;
    id: number;
  }>;
  subject_owner?: Maybe<{
    __typename?: 't_subject';
    subject_id: number;
    full_name?: Maybe<string>;
    name?: Maybe<string>;
    surname?: Maybe<string>;
    lastname?: Maybe<string>;
    id: number;
  }>;
  subject_manager?: Maybe<{
    __typename?: 't_subject';
    lastname?: Maybe<string>;
    name?: Maybe<string>;
    subject_id: number;
    id: number;
  }>;
  object_type?: Maybe<{
    __typename?: 't_object_type';
    brief?: Maybe<string>;
    object_type_id: number;
    id: number;
  }>;
  object_order?: Maybe<{
    __typename?: 't_order';
    order_id: number;
    id: number;
  }>;
  object_subject?: Maybe<{
    __typename?: 't_subject';
    name?: Maybe<string>;
    subject_id: number;
    lastname?: Maybe<string>;
    id: number;
    subject_type?: Maybe<{
      __typename?: 't_subject_type';
      brief?: Maybe<string>;
    }>;
  }>;
  object_task?: Maybe<{ __typename?: 't_task'; task_id: any; id: any }>;
}

export interface TTaskCalendarProps {
  __typename?: 't_task';
  task_id: any;
  name?: Maybe<string>;
  deadline?: Maybe<any>;
  description?: Maybe<string>;
  deadline_time?: Maybe<any>;
  ins_date?: Maybe<any>;
  id: any;
  object_subject?: Maybe<{
    __typename?: 't_subject';
    subject_id: number;
    full_name?: Maybe<string>;
    name?: Maybe<string>;
    surname?: Maybe<string>;
    id: number;
  }>;
  status?: Maybe<{
    __typename?: 't_task_status';
    name: string;
    task_status_id: number;
    id: number;
  }>;
}

export interface TTaskByOrderProps {
  __typename?: 't_task';
  task_id: any;
  ins_date?: Maybe<any>;
  deleted?: Maybe<boolean>;
  deadline?: Maybe<any>;
  deadline_string?: Maybe<string>;
  date_start?: Maybe<any>;
  date_start_string?: Maybe<string>;
  manager_id?: Maybe<number>;
  description?: Maybe<string>;
  status_id?: Maybe<number>;
  id: any;
  document?: Maybe<any>;
  subject?: Maybe<{
    __typename?: 't_subject';
    lastname?: Maybe<string>;
    name?: Maybe<string>;
  }>;
  status?: Maybe<{ __typename?: 't_task_status'; name: string }>;
  selected_shipment_task?: Maybe<
    Array<{
      __typename?: 't_task';
      task_id: any;
      status?: Maybe<{ __typename?: 't_task_status'; name: string }>;
    }>
  >;
  task_props: Array<{
    __typename?: 't_task_prop';
    bigint_val?: Maybe<any>;
    datetime_val?: Maybe<any>;
    decimal_val?: Maybe<any>;
    int_val?: Maybe<number>;
    object_id?: Maybe<any>;
    prop_id?: Maybe<number>;
    prop_value_id?: Maybe<number>;
    text_val?: Maybe<string>;
    prop?: Maybe<{
      __typename?: 't_prop';
      brief: string;
      name?: Maybe<string>;
    }>;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      value?: Maybe<string>;
      brief?: Maybe<string>;
    }>;
  }>;
}

export interface TCurrentTaskProps {
  __typename?: 't_task';
  deadline?: Maybe<any>;
  deadline_string?: Maybe<string>;
  deadline_time_string?: Maybe<string>;
  description?: Maybe<string>;
  doc?: Maybe<any>;
  priority_id?: Maybe<number>;
  task_id: any;
  type_id?: Maybe<number>;
  subject_id?: Maybe<number>;
  status_id?: Maybe<number>;
  ins_date_string?: Maybe<string>;
  ins_time_string?: Maybe<string>;
  ins_user?: Maybe<number>;
  owner_id?: Maybe<number>;
  manager_id?: Maybe<number>;
  name?: Maybe<string>;
  object_id?: Maybe<any>;
  gdrive_file_list?: Maybe<any>;
  tags?: Maybe<any>;
  id: any;
  task_priority?: Maybe<{
    __typename?: 't_task_priority';
    name: string;
    task_priority_id: number;
  }>;
  status?: Maybe<{
    __typename?: 't_task_status';
    name: string;
    task_status_id: number;
    id: number;
  }>;
  task_type?: Maybe<{
    __typename?: 't_task_type';
    brief: string;
    name?: Maybe<string>;
    task_type_id: number;
    id: number;
  }>;
  user_created?: Maybe<{
    __typename?: 't_user';
    subject_id?: Maybe<any>;
    id: number;
    subject?: Maybe<{
      __typename?: 't_subject';
      name?: Maybe<string>;
      lastname?: Maybe<string>;
      id: number;
    }>;
  }>;
  subject_owner?: Maybe<{
    __typename?: 't_subject';
    lastname?: Maybe<string>;
    email?: Maybe<string>;
    name?: Maybe<string>;
    phone?: Maybe<string>;
    subject_id: number;
    id: number;
  }>;
  subject_manager?: Maybe<{
    __typename?: 't_subject';
    name?: Maybe<string>;
    lastname?: Maybe<string>;
    subject_id: number;
    id: number;
  }>;
  subject?: Maybe<{
    __typename?: 't_subject';
    lastname?: Maybe<string>;
    name?: Maybe<string>;
    subject_id: number;
    id: number;
  }>;
  object_type?: Maybe<{
    __typename?: 't_object_type';
    brief?: Maybe<string>;
    object_type_id: number;
  }>;
  watchers: Array<{
    __typename?: 't_task_link';
    task_link_id: any;
    link_type_id?: Maybe<number>;
    object_id?: Maybe<number>;
    id: any;
    subject?: Maybe<{
      __typename?: 't_subject';
      subject_id: number;
      name?: Maybe<string>;
      lastname?: Maybe<string>;
      id: number;
      post: Array<{
        __typename?: 't_subject_prop';
        subject_prop_id: any;
        id: any;
        prop_value?: Maybe<{
          __typename?: 't_prop_value';
          value?: Maybe<string>;
          prop_value_id: number;
        }>;
        prop?: Maybe<{
          __typename?: 't_prop';
          name?: Maybe<string>;
          prop_id: number;
        }>;
      }>;
    }>;
  }>;
  soispolnitelList: Array<{
    __typename?: 't_task_link';
    task_link_id: any;
    link_type_id?: Maybe<number>;
    object_id?: Maybe<number>;
    id: any;
    subject?: Maybe<{
      __typename?: 't_subject';
      subject_id: number;
      name?: Maybe<string>;
      lastname?: Maybe<string>;
      id: number;
      post: Array<{
        __typename?: 't_subject_prop';
        subject_prop_id: any;
        id: any;
        prop_value?: Maybe<{
          __typename?: 't_prop_value';
          value?: Maybe<string>;
          prop_value_id: number;
        }>;
        prop?: Maybe<{
          __typename?: 't_prop';
          name?: Maybe<string>;
          prop_id: number;
        }>;
      }>;
    }>;
  }>;
}

export interface TPartnerProps {
  __typename?: 't_subject';
  deleted?: Maybe<boolean>;
  subject_id: number;
  name?: Maybe<string>;
  full_name?: Maybe<string>;
  guid?: Maybe<string>;
  email?: Maybe<string>;
  email2?: Maybe<string>;
  email3?: Maybe<string>;
  phone?: Maybe<string>;
  phone2?: Maybe<string>;
  phone3?: Maybe<string>;
  address?: Maybe<string>;
  kpp?: Maybe<string>;
  type_id?: Maybe<number>;
  status_id?: Maybe<number>;
  ins_date?: Maybe<any>;
  ins_date_format?: Maybe<string>;
  upd_date_format?: Maybe<string>;
  doc?: Maybe<any>;
  info?: Maybe<string>;
  id: number;
  website?: Maybe<any>;
  status?: Maybe<{ __typename?: 't_subject_status'; name: string }>;
  subject_type?: Maybe<{
    __typename?: 't_subject_type';
    name?: Maybe<string>;
    subject_type_id: number;
  }>;
  manager: Array<{
    __typename?: 't_subject_link';
    id: any;
    subject_id?: Maybe<number>;
    subject?: Maybe<{
      __typename?: 't_subject';
      subject_id: number;
      lastname?: Maybe<string>;
      name?: Maybe<string>;
      id: number;
      branch?: Maybe<{
        __typename?: 't_sales_area_link';
        id?: Maybe<number>;
        sales_area?: Maybe<{
          __typename?: 't_sales_area';
          name?: Maybe<string>;
        }>;
      }>;
    }>;
  }>;
  type_organization: Array<{
    __typename?: 't_subject_prop';
    prop_value_id?: Maybe<number>;
    prop_id?: Maybe<number>;
    subject_prop_id: any;
    id: any;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      brief?: Maybe<string>;
      value?: Maybe<string>;
      id: number;
    }>;
    prop?: Maybe<{
      __typename?: 't_prop';
      brief: string;
      name?: Maybe<string>;
      id: number;
    }>;
  }>;
  SFERA_DEYATELNOSTI: Array<{
    __typename?: 't_subject_prop';
    prop_value_id?: Maybe<number>;
    prop_id?: Maybe<number>;
    subject_prop_id: any;
    id: any;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      brief?: Maybe<string>;
      value?: Maybe<string>;
      id: number;
    }>;
    prop?: Maybe<{
      __typename?: 't_prop';
      brief: string;
      name?: Maybe<string>;
      id: number;
    }>;
  }>;
  city: Array<{
    __typename?: 't_subject_prop';
    prop_value_id?: Maybe<number>;
    prop_id?: Maybe<number>;
    subject_prop_id: any;
    id: any;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      brief?: Maybe<string>;
      value?: Maybe<string>;
      id: number;
    }>;
    prop?: Maybe<{
      __typename?: 't_prop';
      brief: string;
      name?: Maybe<string>;
      id: number;
    }>;
  }>;
  etap_raboti: Array<{
    __typename?: 't_subject_prop';
    prop_value_id?: Maybe<number>;
    prop_id?: Maybe<number>;
    subject_prop_id: any;
    id: any;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      brief?: Maybe<string>;
      value?: Maybe<string>;
      id: number;
    }>;
    prop?: Maybe<{
      __typename?: 't_prop';
      brief: string;
      name?: Maybe<string>;
      id: number;
    }>;
  }>;
  partner_docs: Array<{
    __typename?: 't_doc';
    doc_id: any;
    amount?: Maybe<any>;
    number?: Maybe<string>;
    ddmmyyyy?: Maybe<string>;
    expenses?: Maybe<any>;
    ftrans: Array<{
      __typename?: 't_ftran';
      amount?: Maybe<any>;
      description?: Maybe<string>;
      order?: Maybe<{
        __typename?: 't_order';
        order_id: number;
        ins_date?: Maybe<string>;
        customer?: Maybe<{
          __typename?: 't_subject';
          name?: Maybe<string>;
        }>;
      }>;
    }>;
  }>;
  orders: Array<{
    __typename?: 't_order';
    invoice_date?: Maybe<any>;
    invoice_number?: Maybe<string>;
    bill_date?: Maybe<any>;
    bill_number?: Maybe<string>;
    order_id: number;
    order_sum?: Maybe<any>;
    marzh_order_fakt?: Maybe<any>;
    marzh_order_fakt_perc?: Maybe<any>;
    order_expenses_fakt?: Maybe<any>;
    archived?: Maybe<boolean>;
    status_id?: Maybe<number>;
    ins_date?: Maybe<string>;
    deal_owner?: Maybe<{
      __typename?: 't_subject';
      subject_id: number;
      name?: Maybe<string>;
      lastname?: Maybe<string>;
    }>;
    customer?: Maybe<{
      __typename?: 't_subject';
      name?: Maybe<string>;
      lastname?: Maybe<string>;
      subject_id: number;
    }>;
    status?: Maybe<{
      __typename?: 't_order_status';
      name?: Maybe<string>;
    }>;
  }>;
  created_by_subject?: Maybe<{
    __typename?: 't_subject';
    name?: Maybe<string>;
    lastname?: Maybe<string>;
  }>;
  owner: Array<{
    __typename?: 't_subject_link';
    id: any;
    subject_id?: Maybe<number>;
  }>;
  cooper_status: Array<{
    __typename?: 't_subject_prop';
    prop_value_id?: Maybe<number>;
    prop_id?: Maybe<number>;
    subject_prop_id: any;
    id: any;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      brief?: Maybe<string>;
      value?: Maybe<string>;
      id: number;
    }>;
    prop?: Maybe<{
      __typename?: 't_prop';
      brief: string;
      name?: Maybe<string>;
      id: number;
    }>;
  }>;
  tag_list: Array<{
    __typename?: 't_subject_prop';
    prop_value_id?: Maybe<number>;
    prop_id?: Maybe<number>;
    subject_prop_id: any;
    id: any;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      brief?: Maybe<string>;
      value?: Maybe<string>;
      id: number;
    }>;
    prop?: Maybe<{
      __typename?: 't_prop';
      brief: string;
      name?: Maybe<string>;
      id: number;
    }>;
  }>;
}

export interface TPagePartnerProps {
  guid?: Maybe<string>;
  last_doc_date?: Maybe<any>;
  last_doc_num?: Maybe<string>;
  last_contact_date?: Maybe<any>;
  last_contact_text?: Maybe<string>;
  prev_year_amount?: Maybe<number>;
  __typename?: 'v_subject_search';
  deleted?: Maybe<boolean>;
  subject_id?: Maybe<number>;
  name?: Maybe<string>;
  email?: Maybe<string>;
  phone?: Maybe<string>;
  address?: Maybe<string>;
  type_id?: Maybe<number>;
  status_id?: Maybe<number>;
  ins_date?: Maybe<any>;
  partner_manager_name?: Maybe<string>;
  id?: Maybe<number>;
  subject_type?: Maybe<{
    __typename?: 't_subject_type';
    name?: Maybe<string>;
    subject_type_id: number;
  }>;
  SFERA_DEYATELNOSTI: Array<{
    __typename?: 't_subject_prop';
    prop_value_id?: Maybe<number>;
    prop_id?: Maybe<number>;
    subject_prop_id: any;
    id: any;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      brief?: Maybe<string>;
      value?: Maybe<string>;
      id: number;
    }>;
    prop?: Maybe<{
      __typename?: 't_prop';
      brief: string;
      name?: Maybe<string>;
      id: number;
    }>;
  }>;
  status?: Maybe<{ __typename?: 't_subject_status'; name: string }>;
  manager: Array<{
    __typename?: 't_subject_link';
    id: any;
    subject?: Maybe<{
      __typename?: 't_subject';
      subject_id: number;
      lastname?: Maybe<string>;
      name?: Maybe<string>;
      avatar?: Maybe<string>;
      id: number;
    }>;
  }>;
  type_organization: Array<{
    __typename?: 't_subject_prop';
    prop_value_id?: Maybe<number>;
    prop_id?: Maybe<number>;
    subject_prop_id: any;
    id: any;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      brief?: Maybe<string>;
      value?: Maybe<string>;
      id: number;
    }>;
    prop?: Maybe<{
      __typename?: 't_prop';
      brief: string;
      name?: Maybe<string>;
      id: number;
    }>;
  }>;
  city: Array<{
    __typename?: 't_subject_prop';
    prop_value_id?: Maybe<number>;
    prop_id?: Maybe<number>;
    subject_prop_id: any;
    id: any;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      brief?: Maybe<string>;
      value?: Maybe<string>;
      id: number;
    }>;
    prop?: Maybe<{
      __typename?: 't_prop';
      brief: string;
      name?: Maybe<string>;
      id: number;
    }>;
  }>;
  etap_raboti: Array<{
    __typename?: 't_subject_prop';
    prop_value_id?: Maybe<number>;
    prop_id?: Maybe<number>;
    subject_prop_id: any;
    id: any;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      brief?: Maybe<string>;
      value?: Maybe<string>;
      id: number;
    }>;
    prop?: Maybe<{
      __typename?: 't_prop';
      brief: string;
      name?: Maybe<string>;
      id: number;
    }>;
  }>;
  cooper_status: Array<{
    __typename?: 't_subject_prop';
    prop_value_id?: Maybe<number>;
    prop_id?: Maybe<number>;
    subject_prop_id: any;
    id: any;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      brief?: Maybe<string>;
      value?: Maybe<string>;
      id: number;
    }>;
    prop?: Maybe<{
      __typename?: 't_prop';
      brief: string;
      name?: Maybe<string>;
      id: number;
    }>;
  }>;
  tag_list: Array<{
    __typename?: 't_subject_prop';
    prop_value_id?: Maybe<number>;
    prop_id?: Maybe<number>;
    subject_prop_id: any;
    id: any;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      brief?: Maybe<string>;
      value?: Maybe<string>;
      id: number;
    }>;
    prop?: Maybe<{
      __typename?: 't_prop';
      brief: string;
      name?: Maybe<string>;
      id: number;
    }>;
  }>;
}
export interface TOrderlineProps {
  __typename?: 't_orderline';
  comment?: Maybe<string>;
  caption?: Maybe<string>;
  quantity?: Maybe<any>;
  orderline_id: number;
  status?: Maybe<string>;
  purchase_price?: Maybe<any>;
  purchase_amount?: Maybe<any>;
  fixed_cost?: Maybe<any>;
  price?: Maybe<any>;
  amount?: Maybe<any>;
  provider?: Maybe<string>;
  marzh?: Maybe<any>;
  marzh_percent?: Maybe<any>;
  jdoc?: Maybe<any>;
  expenses?: Maybe<any>;
  id: number;
}

export interface TOrderlineExpenseProps {
  docnum?: Maybe<any>;
  docsum?: Maybe<any>;
  key?: Maybe<any>;
  name?: Maybe<any>;
  price?: Maybe<any>;
  qty?: Maybe<any>;
  summ?: Maybe<any>;
}

export interface TOrderlineDocProps {
  amount: Maybe<number>;
  description: Maybe<string>;
  doc_id: Maybe<number>;
  ftran_id: Maybe<number>;
  id: Maybe<number>;
  number: Maybe<string>;
  oper_date: Maybe<string>;
  orderline_id: Maybe<number>;
  partner_name: Maybe<string>;
  summ: Maybe<number>;
}

export interface TFTranProps {
  __typename?: 't_ftran';
  doc_id?: Maybe<any>;
  subject_id?: Maybe<number>;
  amount?: Maybe<any>;
  description?: Maybe<string>;
  id: any;
  t_doc?: Maybe<{
    __typename?: 't_doc';
    number?: Maybe<string>;
    oper_date?: Maybe<any>;
    doc_rest_expenses?: Maybe<any>;
    amount?: Maybe<any>;
    partner?: Maybe<{
      __typename?: 't_subject';
      lastname?: Maybe<string>;
      surname?: Maybe<string>;
      name?: Maybe<string>;
      full_name?: Maybe<string>;
      subject_id: number;
      id: number;
    }>;
  }>;
  manager?: Maybe<{
    __typename?: 't_subject';
    lastname?: Maybe<string>;
    name?: Maybe<string>;
    full_name?: Maybe<string>;
    surname?: Maybe<string>;
    subject_id: number;
    id: number;
  }>;
  doc_import?: Maybe<{
    __typename?: 't_doc_imports';
    id: number;
    query_guid?: Maybe<any>;
    partner_id?: Maybe<any>;
    partner?: Maybe<{
      __typename?: 't_subject';
      name?: Maybe<string>;
    }>;
  }>;
}

export interface VMessageProps {
  __typename?: 'v_message';
  body?: Maybe<string>;
  doc?: Maybe<any>;
  hold?: Maybe<boolean>;
  ins_date_string?: Maybe<string>;
  ins_time_string?: Maybe<string>;
  message_id?: Maybe<any>;
  msg_type?: Maybe<string>;
  see?: Maybe<boolean>;
  recipient_id?: Maybe<number>;
  sent?: Maybe<boolean>;
  title?: Maybe<string>;
  sender?: Maybe<string>;
  id?: Maybe<any>;
  task?: Maybe<any>;
  link?: Maybe<any>;
  url?: Maybe<any>;
  external_url?: Maybe<any>;
  comment?: Maybe<any>;
  task_update?: Maybe<any>;
  subject_link?: Maybe<any>;
  recipient?: Maybe<{
    __typename?: 't_subject';
    lastname?: Maybe<string>;
    name?: Maybe<string>;
    subject_id: number;
    id: number;
  }>;
}

export type TMessageProps = VMessageProps & {
  __typename?: 't_message'
};

export interface CheckListItemTaskProps {
  __typename?: 't_task';
  task_id: any;
  name?: Maybe<string>;
  owner_id?: Maybe<number>;
  parent_id?: Maybe<any>;
  priority_id?: Maybe<number>;
  status_id?: Maybe<number>;
  id: any;
  deadline?: Maybe<string>;
  deadline_time?: Maybe<string>;
  subject_owner?: Maybe<{
    __typename?: 't_subject';
    subject_id: number;
    name?: Maybe<string>;
    lastname?: Maybe<string>;
    id: number;
  }>;
  task_priority?: Maybe<{
    __typename?: 't_task_priority';
    name: string;
    task_priority_id: number;
    id: number;
  }>;
  task_type?: Maybe<{
    __typename?: 't_task_type';
    brief: string;
    name?: Maybe<string>;
    task_type_id: number;
    id: number;
  }>;
  status?: Maybe<{
    __typename?: 't_task_status';
    name: string;
    task_status_id: number;
    id: number;
  }>;
}

export interface TCommentProps {
  __typename?: 't_comment';
  comment_id: any;
  ins_date_string?: Maybe<string>;
  ins_time_string?: Maybe<string>;
  upd_date_string?: Maybe<string>;
  upd_time_string?: Maybe<string>;
  upd_user?: Maybe<number>;
  object_id?: Maybe<number>;
  object_type_id?: Maybe<any>;
  text?: Maybe<string>;
  id: any;
  object_subject?: Maybe<{
    __typename?: 't_subject';
    name?: Maybe<string>;
    lastname?: Maybe<string>;
    avatar?: Maybe<string>;
    id: number;
  }>;
  subject?: Maybe<{
    __typename?: 't_subject';
    name?: Maybe<string>;
    lastname?: Maybe<string>;
    avatar?: Maybe<string>;
    id: number;
  }>;
  object_task?: Maybe<{
    __typename?: 't_task';
    name?: Maybe<string>;
    description?: Maybe<string>;
    deleted?: Maybe<boolean>;
    object_id?: Maybe<any>;
    id: any;
    object_subject?: Maybe<{
      __typename?: 't_subject';
      name?: Maybe<string>;
      lastname?: Maybe<string>;
      id: number;
    }>;
  }>;
  files: Array<{
    __typename?: 't_file_link';
    file_id?: Maybe<any>;
    id: any;
    file?: Maybe<{
      __typename?: 't_file';
      file_id: number;
      id: number;
      name?: Maybe<string>;
      path?: Maybe<string>;
      type?: Maybe<string>;
    }>;
  }>;
}

export interface TCommentActivityProps {
  __typename?: 'v_comment_activity';
  text?: Maybe<string>;
  partner_name?: Maybe<string>;
  ins_date?: Maybe<any>;
  char_date?: Maybe<string>;
  comment_id?: Maybe<any>;
  subject_id?: Maybe<number>;
  task_id?: Maybe<any>;
  key?: Maybe<any>;
  manager?: Maybe<{
    __typename?: 't_subject';
    lastname?: Maybe<string>;
    name?: Maybe<string>;
    avatar?: Maybe<string>;
  }>;
}

export interface TPropValueProps {
  __typename?: 't_prop_value';
  brief?: Maybe<string>;
  prop_id: number;
  value?: Maybe<string>;
  prop_value_id: number;
  id: number;
  prop?: Maybe<{
    __typename?: 't_prop';
    brief: string;
    name?: Maybe<string>;
  }>;
}

export interface TTaskParterProps {
  __typename?: 't_task';
  task_id: any;
  ins_date?: Maybe<any>;
  deleted?: Maybe<boolean>;
  manager_id?: Maybe<number>;
  name?: Maybe<string>;
  description?: Maybe<string>;
  status_id?: Maybe<number>;
  type_id?: Maybe<number>;
  date_start_string?: Maybe<string>;
  ins_date_string?: Maybe<string>;
  ins_time_string?: Maybe<string>;
  subject_id?: Maybe<number>;
  id: any;
  deadline?: Maybe<string>;
  task_type?: Maybe<{
    __typename?: 't_task_type';
    task_type_id: number;
    name?: Maybe<string>;
    brief: string;
    id: number;
  }>;
  task_priority?: Maybe<{
    __typename?: 't_task_priority';
    name: string;
  }>;
  task_props: Array<{
    __typename?: 't_task_prop';
    task_prop_id: any;
    prop_id?: Maybe<number>;
    prop_value_id?: Maybe<number>;
    text_val?: Maybe<string>;
    bigint_val?: Maybe<any>;
    datetime_val?: Maybe<any>;
    int_val?: Maybe<number>;
    id: any;
    prop?: Maybe<{
      __typename?: 't_prop';
      brief: string;
      name?: Maybe<string>;
    }>;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      value?: Maybe<string>;
      brief?: Maybe<string>;
    }>;
  }>;
  tip_zadachi: Array<{
    __typename?: 't_task_prop';
    prop_value_id?: Maybe<number>;
    id: any;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      value?: Maybe<string>;
      brief?: Maybe<string>;
      id: number;
    }>;
  }>;
  status?: Maybe<{
    __typename?: 't_task_status';
    name: string;
    task_status_id: number;
    id: number;
  }>;
  subject?: Maybe<{
    __typename?: 't_subject';
    subject_id: number;
    full_name?: Maybe<string>;
    name?: Maybe<string>;
    surname?: Maybe<string>;
    id: number;
  }>;
  subject_owner?: Maybe<{
    __typename?: 't_subject';
    subject_id: number;
    full_name?: Maybe<string>;
    name?: Maybe<string>;
    surname?: Maybe<string>;
    lastname?: Maybe<string>;
    id: number;
  }>;
  subject_manager?: Maybe<{
    __typename?: 't_subject';
    lastname?: Maybe<string>;
    name?: Maybe<string>;
    subject_id: number;
    id: number;
  }>;
  contact_person: Array<{
    __typename?: 't_task_link';
    task_link_id: any;
    object_id?: Maybe<number>;
    id: any;
    subject?: Maybe<{
      __typename?: 't_subject';
      subject_id: number;
      name?: Maybe<string>;
      lastname?: Maybe<string>;
      id: number;
    }>;
  }>;
}

export interface TOrganizationProps {
  __typename?: 't_subject';
  address?: Maybe<string>;
  birthdate?: Maybe<any>;
  contacts?: Maybe<string>;
  deleted?: Maybe<boolean>;
  email?: Maybe<string>;
  surname?: Maybe<string>;
  subject_id: number;
  manager?: Maybe<any>;
  name?: Maybe<string>;
  lastname?: Maybe<string>;
  phone?: Maybe<string>;
  inn?: Maybe<string>;
  kpp?: Maybe<string>;
  info?: Maybe<string>;
  type_id?: Maybe<number>;
  ins_date?: Maybe<any>;
  ins_date_format?: Maybe<string>;
  upd_date_format?: Maybe<string>;
  id: number;
  subject_type?: Maybe<{
    __typename?: 't_subject_type';
    name?: Maybe<string>;
    subject_type_id: number;
  }>;
  props: Array<{
    __typename?: 't_subject_prop';
    prop_id?: Maybe<number>;
    prop_value_id?: Maybe<number>;
    subject_prop_id: any;
    prop?: Maybe<{
      __typename?: 't_prop';
      brief: string;
      name?: Maybe<string>;
    }>;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      value?: Maybe<string>;
      brief?: Maybe<string>;
    }>;
  }>;
  type_organization: Array<{
    __typename?: 't_subject_prop';
    prop_value_id?: Maybe<number>;
    prop_id?: Maybe<number>;
    subject_prop_id: any;
    id: any;
    prop_value?: Maybe<{
      __typename?: 't_prop_value';
      brief?: Maybe<string>;
      value?: Maybe<string>;
      id: number;
    }>;
    prop?: Maybe<{
      __typename?: 't_prop';
      brief: string;
      name?: Maybe<string>;
      id: number;
    }>;
  }>;
  partner: Array<{
    __typename?: 't_subject_link';
    id: any;
    subject?: Maybe<{
      __typename?: 't_subject';
      subject_id: number;
      lastname?: Maybe<string>;
      name?: Maybe<string>;
      id: number;
    }>;
  }>;
}

export interface TSubjectByIdProps {
  __typename?: 't_subject';
  subject_id: number;
  name?: Maybe<string>;
  lastname?: Maybe<string>;
  phone?: Maybe<string>;
  address?: Maybe<string>;
  email?: Maybe<string>;
  info?: Maybe<string>;
  type_id?: Maybe<number>;
  is_active?: Maybe<boolean>;
  id: number;
  post?: Maybe<any>;
  organization_link: Array<{
    __typename?: 't_subject_link';
    link_type_id?: Maybe<number>;
    subject_link_id: any;
    subject_id?: Maybe<number>;
    id: any;
    subject_object?: Maybe<{
      __typename?: 't_subject';
      name?: Maybe<string>;
    }>;
  }>;
  subject_type?: Maybe<{
    __typename?: 't_subject_type';
    brief?: Maybe<string>;
  }>;
}

export interface TContactProps {
  __typename?: 't_subject';
  address?: Maybe<string>;
  birthdate?: Maybe<any>;
  contacts?: Maybe<string>;
  deleted?: Maybe<boolean>;
  email?: Maybe<string>;
  surname?: Maybe<string>;
  subject_id: number;
  name?: Maybe<string>;
  lastname?: Maybe<string>;
  phone?: Maybe<string>;
  inn?: Maybe<string>;
  kpp?: Maybe<string>;
  info?: Maybe<string>;
  full_name?: Maybe<string>;
  ins_date?: Maybe<any>;
  ins_date_format?: Maybe<string>;
  upd_date_format?: Maybe<string>;
  is_active?: Maybe<boolean>;
  id: number;
  post?: Maybe<any>;
  organization: Array<{
    __typename?: 't_subject_link';
    subject?: Maybe<{
      __typename?: 't_subject';
      name?: Maybe<string>;
      id: number;
    }>;
  }>;
  partner: Array<{
    __typename?: 't_subject_link';
    subject?: Maybe<{
      __typename?: 't_subject';
      name?: Maybe<string>;
      id: number;
    }>;
  }>;
}
